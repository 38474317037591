const paymentTypes = {
    ATM: "ATM櫃員機",
    BARCODE: "超商條碼",
    Credit: "信用卡",
    WebATM: "WebATM",
    CVS: "超商代碼",
};

export const isBarcodeType = (paymentType) => {
    return splitPaymentTypeString(paymentType) === "BARCODE";
};

export const splitPaymentTypeString = (paymentType) => {
    if (paymentType && paymentType.length) {
        return paymentType.toString()?.split("_")[0] ?? undefined;
    }

    return undefined;
};

export const getPaymentTypeLabel = (paymentType) => {
    const type = splitPaymentTypeString(paymentType);
    console.log(`PaymentType: ${type}`);
    if (!type) {
        return "-";
    }

    return paymentTypes[type];
};
