import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import RfaApi from "../RfaApi";
import { FormikErrors } from "formik";
import Meta from "../components/Meta";
import LoginForm, { FormData } from "../components/LoginForm";
import FormContainer from "../components/FormContainer";
import { Col, Row } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ErrorModal from "../components/form/ErrorModal";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

const STR_LOGIN_ERROR = "帳號錯誤";
const STR_PASSWORD_ERROR = "密碼錯誤";
const GRC_ACTION = "login";

type FormDataWithToken = FormData & {
    token: string;
};

export interface AdvertsProps {
    hasAdverts: boolean;
}

const LoginInner = () => {
    const pageTitle = "登入";
    const history = useHistory();
    const rfaApi = useMemo(() => new RfaApi(), []);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formErrors, setFormErrors] = useState<FormikErrors<FormData>>({});
    const [formDataWithToken, setFormDataWithToken] = useState<FormDataWithToken | null>(null);
    const [isGRCError, setIsGRCError] = useState(false);
    const userInfo = useSelector((state: UserState) => state.user);
    // const today = dayjs().format("YYYY/MM/DD");
    const [show, setShow] = useState(true);
    const [noAdvertsTwo, setNoAdvertsTwo] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const handleClose = () => setShow(false);
    const handleLogin = useCallback(
        (formData: FormData) => {
            if (!executeRecaptcha) {
                return;
            }
            executeRecaptcha(GRC_ACTION)
                .then((token) => {
                    setFormDataWithToken({
                        ...formData,
                        token,
                    });
                })
                .catch((error) => {
                    setIsGRCError(true);
                });
        },
        [executeRecaptcha]
    );

    const doLogin = useCallback(
        ({ login, password, token }: FormDataWithToken) => {
            rfaApi.UserLogin(login, password, token).then((result) => {
                if (result.status === "ok") {
                    rfaApi.checkUserLogin().then(() => {
                        if (window.location.pathname === "/login") {
                            history.push("/");
                        }
                    });
                } else {
                    setFormErrors({ login: STR_LOGIN_ERROR, password: STR_PASSWORD_ERROR });
                }
            });
        },
        [formDataWithToken]
    );

    // 記住打勾不再顯示廣告 API

    useEffect(() => {
        if (formDataWithToken) {
            doLogin(formDataWithToken);
        }
    }, [formDataWithToken]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsTwo");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsTwo(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsTwo", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    // useEffect(() => {
    //     console.log(isChecked);
    // }, [isChecked]);

    return (
        <>
            <Row className="justify-content-center">
                <Col sm={12} md={8} xl={4}>
                    <Meta title={pageTitle} />
                    <FormContainer title={pageTitle} className="form-narrow">
                        {/* 傳遞api 的參數給 不再顯示廣告用 */}
                        <LoginForm formData={{ login: "", password: "" }} onSubmit={handleLogin} errors={formErrors} />
                        {/* <GoogleReCaptcha onVerify={handleVerify} /> */}
                    </FormContainer>
                    <ErrorModal
                        content="reCAPTCHA載入失敗"
                        show={isGRCError}
                        handleClose={() => setIsGRCError(false)}
                    />
                </Col>
            </Row>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 1 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </>
    );
};

export default LoginInner;
