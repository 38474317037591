import React from "react";
import SearchTable, { RowValues } from "./SearchTable";

export interface SearchGridProps {
    items: Array<RowValues>;
}

const SearchGrid = ({ items }: SearchGridProps) => {
    return (
        <div className="search-grid">
            <SearchTable
                header={[
                    "姓名",
                    "性別",
                    "任職縣市",
                    "任職區域",
                    "單位類別",
                    "現職服務單位",
                    "公司電話",
                    "行動電話",
                    "電子信箱",
                    "證照號碼",
                ]}
                content={items}
            />
        </div>
    );
};

export default SearchGrid;
