import React from "react";
import MaterialIcon from "../material/MaterialIcon";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

export interface SuccessModalProps {
    show: boolean;
    handleClose: () => void;
}

const SuccessModal = ({ show, handleClose }: SuccessModalProps) => {
    return (
        <Modal className="form-alert confirm-payment-modal" show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="modal-inner-content">
                    <Stack gap={3}>
                        <div className="p-3">
                            <MaterialIcon icon="check_circle" size={100} color="#60C213" />
                        </div>
                        <div>
                            <h4>付款成功，測驗報名完成，請依測驗日期前往考試。</h4>
                        </div>
                        <Button className="btn btn-primary mt-4" onClick={handleClose}>
                            OK
                        </Button>
                    </Stack>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SuccessModal;
