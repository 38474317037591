import React from "react";
import MaterialIcon from "../material/MaterialIcon";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

export interface ErrorModalProps {
    show: boolean;
    handleClose: () => void;
    title: string;
    children?: React.ReactNode;
}

const ErrorModal = ({ show, handleClose, title, children }: ErrorModalProps) => {
    return (
        <Modal className="form-alert alert-success confirm-payment-modal" show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="modal-inner-content">
                    <Stack gap={3}>
                        <div className="p-3">
                            <MaterialIcon icon="cancel" size={100} color="#f33223" />
                        </div>
                        <div>
                            <h4>{title}</h4>
                        </div>
                        <div className="d-grid">
                            <Button className="mt-4" onClick={handleClose}>
                                OK
                            </Button>
                        </div>
                        {children}
                    </Stack>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ErrorModal;
