import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import Nav from "react-bootstrap/Nav";
import { Card, Col, Row } from "react-bootstrap";
import RfaCard, { RfaCardProps } from "../components/rfa/RfaCard";

const renderRfaCard = (props: RfaCardProps) => {
    const { title } = props;
    return (
        <Col key={title} xs={6} lg={3}>
            <RfaCard {...props} />
        </Col>
    );
};

const Rfa = () => {
    const user = useSelector((state: UserState) => state.user);
    useEffect(() => {
        if (user && user.pages) {
            console.log("pages", user.pages);
        }
    }, [user]);
    return (
        <>
            <Row className="pb-5">
                <Col>
                    <Card className="no-padding rfa-hero-card shadow-sm">
                        <Row className="flex-row align-items-center">
                            <Col lg={8} className="col-lg">
                                <img className="card-img-top" src={user?.pages.rfa.banner} alt={"RFA Hero"} />
                            </Col>
                            <Col lg={4} className="rfa-hero-card-info">
                                <h3 className="py-3">{user?.pages.rfa.title}</h3>
                                <p></p>
                                <div className="d-grid gap-2 d-md-flex py-3 justify-content-md-start">
                                    <Nav.Link
                                        className="btn btn-primary px-4 me-md-2"
                                        role="button"
                                        href={user?.pages.rfa.link}
                                    >
                                        了解更多
                                    </Nav.Link>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className="pb-3">
                <Col>
                    <hr className="hr-text" data-content="測驗與查詢" />
                </Col>
            </Row>
            <Row className="gx-4 gy-3">{user?.pages.rfa.cards.map(renderRfaCard)}</Row>
        </>
    );
};

export default Rfa;
