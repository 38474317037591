import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import RfaApi from "../RfaApi";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import SuccessModal from "../components/form/SuccessModal";
import ErrorModal from "../components/form/ErrorModal";
import PersonalDataAuthorizationForm, { FormData } from "../components/PersonalDataAuthorizationForm";

const PersonalDataAuthorization = () => {
    const pageTitle = "RFA 個人資料授權對外公佈書";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const user = useSelector((state: UserState) => state.user);
    const [formData, setFormData] = useState<FormData>();
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();

    const responseToForm = (serverForm: FormData) => {
        setFormData({ ...serverForm });
    };

    const onError = (error: any) => {
        // TODO: error message
        setErrorContent(error?.response?.data ?? "");
        setErrorVisible(true);
    };

    const postPersonalDataAuthorization = (formData: FormData) => {
        rfaApi
            .UpdateAgreement(formData)
            .then((result) => setSuccessVisible(true))
            .catch(onError);
    };

    useEffect(() => {
        if (user && user.rfa) {
            rfaApi.GetAgreement().then(responseToForm).catch(onError);
        }
    }, [user, rfaApi]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <PersonalDataAuthorizationForm formData={formData} onSubmit={postPersonalDataAuthorization} />
                </FormContainer>
                <SuccessModal show={isSuccessVisible} handleClose={() => setSuccessVisible(false)} />
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
        </Row>
    );
};

export default PersonalDataAuthorization;
