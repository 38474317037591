import React, { createRef, MouseEventHandler } from "react";
import { getRocDate } from "../date/RocDate";
import { Col } from "react-bootstrap";
import Pdf from "react-to-pdf";

export interface StatusDesc {
    name: string;
    admissionTicketNumber: string;
    status: string;
    replyDate: string;
    replyUnit: string;
}

export interface StatusProps {
    scoreStatus: StatusDesc;
    doPrevious: () => void;
}

const Status = ({
    scoreStatus: { name, admissionTicketNumber, status, replyDate, replyUnit },
    doPrevious,
}: StatusProps) => {
    const ref = createRef<HTMLDivElement>();
    const pdfOptions = {
        orientation: "landscape",
        unit: "mm",
        format: [297, 210],
    };
    const getPdfName = () => {
        return `admission-ticket-${admissionTicketNumber}.pdf`;
    };

    return (
        <>
            <div className="row score-status">
                <div className="col score-status-wrapper" ref={ref}>
                    <div className="container score-status-content">
                        <div className="row">
                            <div className="title col-2">姓名</div>
                            <div className="col-auto">{name}</div>
                        </div>
                        <div className="row">
                            <div className="title col-2">准考證號碼</div>
                            <div className="col-auto">{admissionTicketNumber}</div>
                        </div>
                        <div className="row">
                            <div className="title col-2">複查結果</div>
                            <div className="col-auto">{status}</div>
                        </div>
                        <div className="row">
                            <div className="title col-2">回覆日期</div>
                            <div className="col-auto">{getRocDate(replyDate)}</div>
                        </div>
                        <div className="row">
                            <div className="title col-2">回覆單位</div>
                            <div className="col-auto">{replyUnit}</div>
                        </div>
                        <div className="row stamp-field">
                            <div className="stamp" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <Col>
                    <button type="button" className="btn btn-outline-primary w-100" onClick={doPrevious}>
                        返回
                    </button>
                </Col>
                <Col>
                    <Pdf targetRef={ref} filename={getPdfName()} options={pdfOptions}>
                        {({ toPdf }: { toPdf: MouseEventHandler<HTMLButtonElement> }) => (
                            <button className="btn btn-primary w-100" onClick={toPdf}>
                                下載
                            </button>
                        )}
                    </Pdf>
                </Col>
            </div>
        </>
    );
};

export default Status;
