import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CertificateLogo from "../components/certificate/CertificateLogo";
import RfaApi from "../RfaApi";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

const LogoDownload = () => {
    const pageTitle = "認證商標下載";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const userInfo = useSelector((state: UserState) => state.user);
    const [logoUrl, setLogoUrl] = useState("");
    const [isLogoVisible, setLogoVisible] = useState(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setLogoUrl(`/rfa-logo.png`);
    }, []);

    useEffect(() => {
        if (userInfo && userInfo.username) {
            rfaApi
                .GetCertificateFile()
                .then((rdata) => {
                    console.log("GetCertificateFile", rdata);
                    if (!rdata) {
                        throw new Error("Invalid payment response");
                    } else {
                        setLogoVisible(true);
                        // const advert = localStorage.getItem("noAdverts") as string;
                        // const advertObj = JSON.parse(advert);
                        // if (advertObj && advertObj.date === dayjs().format("YYYY/MM/DD") && advertObj.noAdverts) {
                        //     setShow(false);
                        // } else {
                        //     setShow(true);
                        // }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setErrorContent("無資料");
                    setErrorVisible(true);
                });
        }
    }, [userInfo]);

    const renderLayout = () => {
        if (isLogoVisible) {
            return <CertificateLogo logoUrl={logoUrl} />;
        }

        return <div>無法使用</div>;
    };

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <>
            <Row className="justify-content-center">
                <Col>
                    <Meta title={pageTitle} />
                    <FormContainer title={pageTitle} className="dashboard-card">
                        {renderLayout()}
                    </FormContainer>
                    <ErrorModal
                        content={errorContent}
                        show={isErrorVisible}
                        handleClose={() => setErrorVisible(false)}
                    />
                </Col>
            </Row>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </>
    );
};

export default LogoDownload;
