import React from "react";
import { Col, Row } from "react-bootstrap";

const renderEntry = ({ label, value }: FormDataEntry) => {
    return (
        <Row key={label}>
            <Col className="form-confirm-label text-end">{label}</Col>
            <Col className="mark-secondary">{value}</Col>
        </Row>
    );
};

export interface FormDataEntry {
    label: string;
    value: string;
}

export interface FormDataConfirmProps {
    entries: Array<FormDataEntry>;
}

const FormDataConfirm = ({ entries }: FormDataConfirmProps) => {
    return <div className="form-data-confirm align-items-start">{entries.map(renderEntry)}</div>;
};

export default FormDataConfirm;
