import React from "react";
import { Stack } from "react-bootstrap";

export interface PendingInfoProps {
    doPrevious: () => void;
}

const PendingInfo = ({ doPrevious }: PendingInfoProps) => {
    return (
        <Stack gap={4}>
            <div className="row">
                <div className="col-auto">
                    <div className="pending-info">待定申請</div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <button type="button" className="btn btn-outline-primary w-100" onClick={doPrevious}>
                        返回
                    </button>
                </div>
            </div>
        </Stack>
    );
};

export default PendingInfo;
