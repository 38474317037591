import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RfaApi from "../RfaApi";
import { UserState } from "../components/types";
import { Col, Row, Stack } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";
import EventCard from "../components/events/EventCard";
import { ActivityData, DataList } from "../type";

const pageTitle = "活動列表";

const Events = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [events, setEvents] = useState<ActivityData[]>([]);

    useEffect(() => {
        rfaApi
            .GetActivities()
            .then((respData: any) => {
                if (respData) {
                    const mData = respData as DataList<ActivityData>;
                    setIsLoading(false);
                    setEvents(mData.rows);
                }
            })
            .catch((err: any) => {
                console.error(err);
                setErrorVisible(true);
                setErrorContent("載入資料失敗");
            });
    }, []);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card events">
                    <Stack gap={5}>
                        {events.map((eventDetails, index) => (
                            <EventCard key={index} eventDetails={eventDetails} />
                        ))}
                        {isLoading && <div className="text-center">載入中...</div>}
                    </Stack>
                </FormContainer>
                <ErrorModal
                    content={errorContent}
                    show={isErrorVisible}
                    handleClose={() => {
                        setErrorVisible(false);
                        window.location.href = "/profile/dashboard";
                    }}
                />
            </Col>
        </Row>
    );
};

export default Events;
