import React from "react";
import AgreementYesNo from "./AgreementYesNo";
import AgreementScroll from "./AgreementScroll";

export interface UserProfile {
    chName: string;
    idNum: string;
}

export interface AgreementCorrectInformationProps {
    // TODO: update types
    formik: any;
    onAgreementChange: (value: string, name: string) => void;
    userProfile: UserProfile;
}

const AgreementCorrectInformation = ({ formik, onAgreementChange, userProfile }: AgreementCorrectInformationProps) => {
    const { chName, idNum } = userProfile;

    return (
        <>
            <AgreementScroll formik={formik} name="agreementCorrectInformation">
                <p>一、提供正確真實資訊之聲明書</p>
                <p>
                    立聲明書人 {chName} {idNum}{" "}
                    特此聲明以下資訊均為正確真實；其中若有任何虛偽不實或隱匿情事，立聲明書人願意接受中華民國退休基金協會相關規定之處分及處置，絕無異議。
                </p>
                <ol>
                    <li>
                        立聲明書人從未因涉及任何故意犯罪被列為被告而經科刑判決確定、或在法院審理中或檢察官偵察中之公訴性刑事訴訟案件。
                        <AgreementYesNo formik={formik} name={"noCrime"} onChange={onAgreementChange} />
                    </li>
                    <li>
                        立聲明書人從未因執行業務或營業行為，被列為民事訴訟案件之被告(所稱「民事訴訟案件」，包括但不限於：訴訟、仲裁、和解、調解、調處、或聲請強制執行或宣告破產等事件。)
                        <AgreementYesNo formik={formik} name={"noLitigation"} onChange={onAgreementChange} />
                    </li>
                    <li>
                        立聲明書人從未因逃漏稅捐、簽證(認)不實、疏忽職務、或因違反金融、經濟秩序之行為，經政府機關(構)或依法享有行政處分(置)權之自律性組織為懲戒、行政處罰或自律處置，或為行使此等處分、處罰或處置權所為之調查。
                        <AgreementYesNo formik={formik} name={"noTaxEvasion"} onChange={onAgreementChange} />
                    </li>
                </ol>
                <p>
                    ※若以上任何一項勾選為「否」，立聲明書人必須於本聲明書外，另附書面資料，詳細說明事件發生緣由及處理情形。書面資料包括但不限於：起訴或聲請文件、起訴書、判決書、判斷書、裁決書、處分書、調解書、調處書、和解書、繳納罰款證明或其他案件終結文件等。
                </p>
                <p>
                    ※立聲明書人所提供書面資料，除依法律規定或主管機關要求外，中華民國退休基金協會不得將該書面資料提供與第三人。
                </p>
            </AgreementScroll>
        </>
    );
};

export default AgreementCorrectInformation;
