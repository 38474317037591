import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import RfaApi from "../../RfaApi";
import { ActivityData } from "../../type";
import { RegisterErrorStatus } from "./useEventRegister";

export interface EventDataHookConfig {
    id: string | undefined;
    onError: (message: string) => void;
}

function useEventData({ id, onError }: EventDataHookConfig) {
    const history = useHistory();
    const rfaApi = useMemo(() => new RfaApi(), []);
    const [event, setEvent] = useState<ActivityData>();
    const [isLoading, setIsLoading] = useState(false);
    const backToList = () => {
        history.replace("/profile/events");
    };

    useEffect(() => {
        const numberId = Number(id);
        if (!isNaN(numberId)) {
            setIsLoading(true);
            rfaApi
                .GetActivity(numberId)
                .then((respData: any) => {
                    if (respData) {
                        const mData = respData as ActivityData;
                        setEvent(mData);
                    } else {
                        backToList();
                    }
                })
                .catch((err: any) => {
                    console.error(err);
                    onError(RegisterErrorStatus.NO_DATA);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            backToList();
        }
    }, [id]);

    return { event, isLoading, backToList };
}

export default useEventData;
