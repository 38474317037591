import React from "react";
import { useHistory } from "react-router-dom";
import Button from "react-bootstrap/Button";
const TermsOfService = () => {
    const history = useHistory();
    const goback = () => {
        history.goBack();
    };

    return (
        <>
            <p style={{ textAlign: "center" }}>
                <span style={{ color: "#ff0000" }}>
                    <strong>好命退休計算機RFA版訂閱</strong>
                </span>
                <strong>同意條款</strong>
            </p>
            <p style={{ textAlign: "justify" }}>
                <strong>好命退休計算機RFA版</strong>係由中華民國退休基金協會（以下簡稱 退協）提供之訂閱服務，我們透過《
                <strong>好命退休計算機RFA版</strong>同意條款》提供
                <strong>好命退休計算機RFA版</strong>完整的服務。為保障您的權益，請詳讀下列條款。
            </p>
            <ul>
                <li>
                    <strong>試用與付款</strong>
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                若您訂閱本服務，您享有免費使用<strong>好命退休計算機RFA版</strong>服務內容30天試用期。
            </p>
            <p style={{ textAlign: "justify" }}>
                好命退休計算機RFA版為自動週期性續訂之服務，如果您向退協訂購本服務，您必須同意以信用卡扣款作為購買好命退休計算機RFA版服務的付款方式，系統會要求您提供您的信用卡付款資訊，以啟動您的訂閱資格。
            </p>
            <ul>
                <li>
                    <strong>付款人義務</strong>
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                您向退協直接購買本訂閱，應使用本人之信用卡，並同意授權退協依據本訂閱內容及扣款方式，自您指定之信用卡帳戶扣款。若您使用非本人（以下稱「實際付款人」）之信用卡進行付款，我們將視為您已獲得合法授權使用，實際付款人亦完全知悉此付款行為。您或實際付款人將來得查詢與付款相關之會員帳戶資訊、終止付費或僅屬於實際付款人個人之資料異動。
            </p>
            <p style={{ textAlign: "justify" }}>
                您或實際付款人使用信用卡付款時，其指定之信用卡過期、掛失、被往來銀行拒絕或因其他原因致本公司請款失敗時，我們有權終止或解除雙方交易。
            </p>
            <p style={{ textAlign: "justify" }}>
                您或實際付款人皆應盡善良管理人之注意，妥善保管個人資料與所提供信用卡或第三方付費服務之重要資料，不得洩漏或交付與第三人，如因您或實際付款人之故意或過失使他人知悉、或遭第三人冒用該重要資料，而造成損失時，應自行負責。對當期應繳之費用有疑慮時，請優先洽詢刷卡行再與退協做洽詢。
            </p>
            <ul>
                <li>
                    <strong>定價與變更</strong>
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                退協保留調整訂閱定價之權利，訂閱定價與優惠在您完成購買手續前可能隨時變更。
            </p>
            <p style={{ textAlign: "justify" }}>
                我們會先依您同意成為好命退休計算機RFA版的適用訂閱年限向您收費，直到您取完成取消訂閱。如果本訂閱有調高服務價格之情事，退協10天前對您發出通知，調高的價格將會套用至您應繳的下次訂閱之款項中。
            </p>
            <p style={{ textAlign: "justify" }}>
                如果您的訂閱完成取消，則之後當您決定要重新購買時，將依當時現行之費率向您收取首期費用。
            </p>
            <ul>
                <li>
                    <strong>取消訂閱</strong>
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                好命退休計算機RFA版屬非以有形媒介提供之數位內容，且一經提供完成線上服務，依據行政院消費者保護處公告實施之「通訊交易解除權合理例外情事」，已排除消保法七日猶豫期無條件解除之適用，您一旦同意訂閱退協之好命退休計算機RFA版，退協將不受理退換。
            </p>
            <p>您可以在訂閱期間隨時取消本服務，當期計費週期結束時即可完成取消訂閱。</p>
            <p>
                取消訂閱後，您不會獲得當期計費週期的退款，但在當期計費週期的剩餘期間內，您對本服務之使用權不會受取消作業影響，仍得繼續使用本服務，直到該計費週期到期，您將喪失好命退休計算機RFA版服務。
            </p>
            <ul>
                <li>
                    <strong>智慧財產權</strong>
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                本訂閱所提供的任何服務、軟體、內容、文字及編碼的智慧財產權皆屬於退協所有。退協僅授權您使用的權利；為免爭議，我們未於本條款中明確授予您的權利，除按照本使用條款的規定外，未得退協事前書面同意，您不可複製、傳送、抄襲、散布、修改、鏈接、展示、使用、轉讓或出售本訂閱任何内容（包括但不限於任何影音、文字、圖像、應用程式、名稱、商標及標記）或使用任何該等内容作商業用途。
            </p>
            <ul>
                <li>
                    <strong>條款變更、修改與終止</strong>
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                退協保留隨時修改本條款的權利，本條款修改後於本訂閱網站公布後即生效。本條款如有變更，您在下次使用本服務前，系統會請您接受新條款。您接受新條款後，您對所有服務之使用以及後續所購買之所有項目，即適用新條款，直到我們通知您進一步變更為止。
            </p>
            <p style={{ textAlign: "justify" }}>
                如果您拒絕接受更新版本條款，您將無法繼續使用本訂閱，如您繼續使用本訂閱，表示您接受本「條款」之最新版本規範。
            </p>
            <p style={{ textAlign: "justify" }}>
                退協可隨時修改、暫停或終止依據本條款提供之特定服務及應用程式的使用權限。
            </p>
            <ul>
                <li>
                    <strong>一般條款</strong>
                </li>
            </ul>
            <p style={{ textAlign: "justify" }}>
                此同意條款構成您與退協就您使用本服務之完整合意，取代您先前您與退協間有關本訂閱所為之任何約定。若任何本訂閱條款規定，經有管轄權之法院認定無效，當事人仍同意法院應努力使當事人於前開規定所表達之真意生效，且本服務條款之其他規定仍應完全有效。
            </p>
            <p style={{ textAlign: "justify" }}>
                退協如對您違反本條款之情事未行使或主張任何權利或法律行動，不構成前開權利或規定之棄權，亦不免除我們未來就相同或類似之情事採取處置或法律行動的權利。
            </p>
            <p style={{ textAlign: "justify" }}>
                本訂閱條款之解釋與適用，以及與本訂閱條款有關的爭議，除法律另有規定者外，均應依照中華民國法律予以處理，並以台灣台北地方法院為第一審管轄法院。
            </p>
            <p style={{ textAlign: "center" }}>
                <Button type="button" variant="outline-primary" onClick={goback}>
                    返回
                </Button>
            </p>
        </>
    );
};

export default TermsOfService;
