import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ProfileForm, { FormData } from "../components/profile/ProfileForm";
import RfaApi from "../RfaApi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SuccessModal from "../components/form/SuccessModal";
import ErrorModal from "../components/form/ErrorModal";
import { UserState } from "../components/types";
import { formatDateInput } from "../components/date/GregorianDate";

const Profile = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);
    const pageTitle = "個人資料修改";

    const [formData, setFormData] = useState<FormData>();
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();

    useEffect(() => {
        if (user && user.username) {
            rfaApi
                .GetProfile()
                .then((data) => {
                    setFormData((prevState) => {
                        return {
                            ...prevState,
                            ...data,
                            ...{ birthday: formatDateInput(data?.birthday) },
                        };
                    });
                })
                .catch((err) => {
                    setErrorContent(err.response.data);
                    setErrorVisible(err);
                    history.push("/");
                });
        }
        window.scrollTo(0, 0);
    }, [user, history, rfaApi]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <ProfileForm
                        formData={formData}
                        onSubmit={(formData) => {
                            rfaApi
                                .UpdateProfile({ ...formData })
                                .then(() => {
                                    setSuccessVisible(true);
                                })
                                .catch((err) => {
                                    setErrorContent(err.response.data);
                                    setErrorVisible(err);
                                });
                        }}
                    />
                </FormContainer>
                <SuccessModal show={isSuccessVisible} handleClose={() => setSuccessVisible(false)} />
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
        </Row>
    );
};

export default Profile;
