import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import DashboardGrid from "../components/DashboardGrid";
import { Link } from "react-router-dom";
import RfaApi from "../RfaApi";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

interface GridRow {
    id: number;
    title: string;
    date: string;
}

const TicketAttendances = () => {
    const pageTitle = "應考證明";
    const userInfo = useSelector((state: UserState) => state.user);
    const [mockData, setMockData] = useState<Array<GridRow>>([]);
    const rfaApi = useMemo(() => new RfaApi(), [mockData]);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const parseDataRow = (gridRow: GridRow, index: number) => {
        const { id, title, date } = gridRow;
        console.log("gridRow", gridRow);
        return [
            <Link key={id} to={`/profile/ticket-attendance/${id}`}>
                {title}
            </Link>,
            date,
        ];
    };
    useEffect(() => {
        //get admission ticket by examid
        if (userInfo && userInfo.username) {
            rfaApi
                .GetExamAttendances()
                .then((result) => {
                    console.log("result", result);
                    setMockData(result);
                })
                .catch((err) => {
                    console.log(err);
                    try {
                        // setErrorContent(err.response.data);
                        // setErrorVisible(true);
                    } catch {
                        // console.error("Error response");
                        // setErrorVisible(true);
                    }
                });
            window.scrollTo(0, 0);
        }
    }, [userInfo]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    {mockData.length > 0 ? (
                        <DashboardGrid header={["項目", "考試日期"]} content={mockData.map(parseDataRow)} />
                    ) : (
                        <h3 className="no-ticket">無應考證明資料</h3>
                    )}
                </FormContainer>
            </Col>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
};

export default TicketAttendances;
