import DEF from "../def";

const initialState = {};

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case DEF.PAYMENT_UPDATE:
            console.log("reducer,action.type:PAYMENT_UPDATE, action.value:", action.value);
            return action.value;
        default:
            return state;
    }
}
