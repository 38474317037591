import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import RfaApi from "../RfaApi";
import PendingInfo from "../components/PendingInfo";
import Status, { StatusDesc } from "../components/score/Status";
import "../css/score-status.sass";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";

const ScoreStatus = () => {
    const pageTitle = "成績複查結果";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();

    const [scoreStatus, setScoreStatus] = useState<StatusDesc>();
    const { id } = useParams<{ id?: string }>();

    const parseResponse = (data: any) => {
        console.log("GetScoreCheckResult", data);
        if (data && data.replyDate) {
            setScoreStatus({ ...data });
        } else {
            setErrorContent("無複查結果");
            setErrorVisible(true);
        }
    };

    const handleError = () => {
        setErrorContent("服務器錯誤");
        setErrorVisible(true);
    };

    useEffect(() => {
        rfaApi
            .GetScoreCheckResult(id ? id : "")
            .then(parseResponse)
            .catch(handleError);
    }, [user, rfaApi, history]);

    const goBack = () => {
        history.goBack();
    };

    const renderLayout = () => {
        if (scoreStatus && scoreStatus.admissionTicketNumber) {
            return <Status scoreStatus={scoreStatus} doPrevious={goBack} />;
        }

        return <PendingInfo doPrevious={goBack} />;
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    {renderLayout()}
                </FormContainer>
                <ErrorModal
                    content={errorContent}
                    show={isErrorVisible}
                    handleClose={() => {
                        setErrorVisible(false);
                        history.push("/profile/score-test-status");
                    }}
                />
            </Col>
        </Row>
    );
};

export default ScoreStatus;
