import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import WorkExperienceForm, { FormData } from "../components/WorkExperienceForm";
import RfaApi from "../RfaApi";
import { useSelector } from "react-redux";
import SuccessModal from "../components/form/SuccessModal";
import ErrorModal from "../components/form/ErrorModal";
import { UserState } from "../components/types";
import { formatDateInput } from "../components/date/GregorianDate";

interface ApiFormData {
    company: string;
    jobTitle: string;
    companyType: string;
    workDate?: string;
    workHoursPerWeek: number;
    totalYears: number;
    totalMonths: number;
    description: string;
}

const WorkExperience = () => {
    const pageTitle = "工作經歷";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const user = useSelector((state: UserState) => state.user);
    const [formData, setFormData] = useState<FormData>();
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();

    const responseToForm = (serverForm: ApiFormData) => {
        setFormData({
            ...serverForm,
            ...{ workDate: formatDateInput(serverForm?.workDate) },
        });
    };

    const onError = (error: any) => {
        // TODO: error message
        setErrorContent(error?.response?.data ?? "");
        setErrorVisible(true);
    };
    const postWorkExperience = (formData: FormData) => {
        rfaApi
            .UpdateWorkExperience(formData)
            .then((result) => setSuccessVisible(true))
            .catch(onError);
    };

    useEffect(() => {
        if (user && user.rfa) {
            rfaApi.GetWorkExperience().then(responseToForm).catch(onError);
        }
    }, [user, rfaApi]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <div className="form-subtitle">目前(最近)任職狀況</div>
                    <WorkExperienceForm
                        formData={formData}
                        onSubmit={postWorkExperience}
                        submitButtonTitle="保存變更"
                    />
                </FormContainer>
                <SuccessModal show={isSuccessVisible} handleClose={() => setSuccessVisible(false)} />
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
        </Row>
    );
};

export default WorkExperience;
