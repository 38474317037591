import React, { MouseEventHandler, useEffect, useState } from "react";
import { ButtonGroup, Col, Row } from "react-bootstrap";
// @ts-ignore
import Barcode from "react-barcode";
import Pdf from "react-to-pdf";
import BarcodeInfo from "./payment/BarcodeInfo";
import { getPaymentTypeLabel, isBarcodeType } from "./payment/PaymentType";
import FormDataView from "./form/FormDataView";
import { FormDataEntry } from "./form/FormDataConfirm";

const getPdfName = (form: PaymentForm) => `bill${form.PaymentNo}.pdf`;
const isSet = (value: any) => value !== undefined && value !== null && value !== "";

const pdfOptions = {
    orientation: "portrait",
    unit: "mm",
    format: [297, 210],
};

const getBarcodes = (form: PaymentForm) => {
    return [form.Barcode1, form.Barcode2, form.Barcode3].filter(isSet);
};

const PaymentResult: { [status: string]: string } = {
    "": "交易發生異常",
    "0": "-",
    "1": "已付款",
    "2": "待付款",
    "10100073": "待繳費",
    "10100058": "付款失敗",
    "10100248": "拒絕交易",
    "10100249": "交易失敗",
    "10100251": "卡片過期",
    "10100282": "3D授權未完成",
};
export interface PaymentForm {
    transactionId?: string;
    transactionAt?: string;
    status?: string;
    transactionStatus?: string;
    PaymentType?: string;
    PaymentNo?: string;
    BankCode?: string;
    vAccount?: string;
    TradeAmt?: string;
    Barcode1?: string;
    Barcode2?: string;
    Barcode3?: string;
    paymentId?: number;
    payFor?: string;
}

export interface PaymentInfoProps {
    form: PaymentForm;
    doPrevious: () => void;
}

const PaymentInfo = ({ form, doPrevious }: PaymentInfoProps) => {
    const ref = React.createRef<HTMLDivElement>();

    const [confirmEntries, setConfirmEntries] = useState<Array<FormDataEntry>>([]);
    useEffect(() => {
        const entries: Array<FormDataEntry> = [
            { label: "交易狀態", value: PaymentResult[form.transactionStatus ? form.transactionStatus : ""] },
            { label: "交易序號", value: form.transactionId },
            { label: "交易時間", value: form.transactionAt },
            { label: "繳費項目", value: form.payFor },
            { label: "繳費方式", value: getPaymentTypeLabel(form.PaymentType) },
            { label: "繳費代碼", value: form.PaymentNo },
            { label: "銀行代碼", value: form.BankCode },
            { label: "匯款帳號", value: form.vAccount },
            { label: "金額", value: form.TradeAmt ? `NT$${form.TradeAmt}` : null },
        ].filter(({ value }) => isSet(value));

        setConfirmEntries(entries);
    }, [form]);

    return (
        <div className="form-confirm">
            <Row ref={ref}>
                <Col>
                    <FormDataView entries={confirmEntries} className="gray-labels" />
                    {isBarcodeType(form) ? (
                        <>
                            <Row className="signup-result__payment-barcodes">
                                <Col>
                                    {getBarcodes(form).map((barcode, key) => {
                                        return (
                                            <Row key={key} className="barcode">
                                                <Barcode value={barcode} options={{ format: "CODE39" }} />
                                            </Row>
                                        );
                                    })}
                                </Col>
                            </Row>
                            <Row className="payment-info__barcode-info">
                                <BarcodeInfo />
                            </Row>
                        </>
                    ) : (
                        <></>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup className="d-flex justify-content-between btn-group-spacing">
                        <button type="button" className="btn btn-outline-primary w-100" onClick={doPrevious}>
                            返回
                        </button>
                        <Pdf targetRef={ref} filename={getPdfName(form)} options={pdfOptions} scale="0.8">
                            {({ toPdf }: { toPdf: MouseEventHandler<HTMLButtonElement> }) => (
                                <button className="btn btn-primary w-100" onClick={toPdf}>
                                    下載
                                </button>
                            )}
                        </Pdf>
                    </ButtonGroup>
                </Col>
            </Row>
        </div>
    );
};

export default PaymentInfo;
