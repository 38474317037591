import React, { useEffect, useMemo, useState } from "react";
import ApplicationChoice from "../components/certificate/ApplicationChoice";
import { APPLICATION_RENEWAL, NEW_APPLICATION, UNKNOWN } from "../components/certificate/Application";
import { useSelector } from "react-redux";
import RfaApi from "../RfaApi";
import { useHistory } from "react-router-dom";
import Meta from "../components/Meta";
import { Col, Row } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import { UserState } from "../components/types";
import ErrorModal from "../components/form/ErrorModal";
import CertificateApplicationWarningModal from "../components/certificate/CertificateApplicationWarningModal";
import WorkExperienceForm, { FormData } from "../components/WorkExperienceForm";
import { formatDateInput } from "../components/date/GregorianDate";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

interface ErrorResponse {
    response: {
        data: string;
    };
}

const CertificateApplication = () => {
    const pageTitle = "認證申請/換證";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const userInfo = useSelector((state: UserState) => state.user);
    const [formData, setFormData] = useState<FormData>();
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [errorReturnPath, setErrorReturnPath] = useState<string | undefined>();
    const [isWarningVisible, setWarningVisible] = useState<boolean>(false);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const onErrorClose = () => {
        setErrorVisible(false);
        if (errorReturnPath) {
            history.push(errorReturnPath);
        }
    };

    const responseToForm = (serverForm: FormData) => {
        setFormData({
            ...serverForm,
            ...{ workDate: formatDateInput(serverForm?.workDate) },
        });
    };

    const apiError = (err: ErrorResponse) => {
        setErrorContent(err.response.data);
        setErrorVisible(true);
    };

    useEffect(() => {
        if (!(userInfo && userInfo.rfa)) {
            return;
        }
        const currentExam = userInfo?.exam ?? null;
        rfaApi.InNewcertPeriod(currentExam).then((allowed) => {
            if (allowed) {
                setWarningVisible(true);
            }
        });

        rfaApi
            .GetScore()
            .then((data) => {
                console.log("GetScore", data);
                if (!data || data.length < 1 || (data[0].result !== 1 && data[0].checkResult !== 1)) {
                    setErrorContent("無認證申請資格");
                    setErrorVisible(true);
                    setErrorReturnPath("/profile/dashboard");
                } else {
                    rfaApi
                        .GetProfile()
                        .then((profile) => {
                            console.log("GetProfile", profile);
                            if (IsValidProfile(profile)) {
                                rfaApi.GetWorkExperience().then(responseToForm).catch(apiError);
                            } else {
                                setErrorContent("請完成個人資料填寫");
                                setErrorVisible(true);
                                setErrorReturnPath("/profile");
                            }
                        })
                        .catch(apiError);
                }
            })
            .catch(apiError);
    }, [userInfo, rfaApi]);

    const postWorkExperience = (formData: FormData) => {
        rfaApi
            .UpdateWorkExperience(formData)
            .then(() => {
                return history.push(`/profile/certificate-agreements/${applicationType}`);
            })
            .catch(apiError);
    };

    // TODO: shouldn't this be in formik?
    const IsValidProfile = (profile: any) => {
        return !!(
            profile.SurName &&
            profile.GivenName &&
            profile.mobile &&
            profile.idNum &&
            profile.email &&
            profile.birthday &&
            profile.companyType &&
            profile.company &&
            profile.eduClass &&
            profile.school &&
            profile.eduLevel &&
            profile.graduateYear &&
            profile.workPhone &&
            profile.homePhone &&
            profile.companyArea &&
            profile.companyAddress &&
            profile.homeArea &&
            profile.homeAddress &&
            profile.mainAddress
        );
    };

    const [applicationType, setApplicationType] = useState(UNKNOWN);

    const onChoice = (type: number) => {
        console.log(type);
        const currentExam = userInfo?.exam ?? null;
        if (type === NEW_APPLICATION) {
            rfaApi.InNewcertPeriod(currentExam).then((allowed) => {
                if (allowed) {
                    setApplicationType(type);
                } else {
                    setErrorContent(`非認證申請期間`);
                    setErrorVisible(true);
                }
            });
        }
        if (type === APPLICATION_RENEWAL) {
            setApplicationType(type);
        }
    };
    const getLayout = () => {
        switch (applicationType) {
            case NEW_APPLICATION:
            case APPLICATION_RENEWAL:
                return (
                    <WorkExperienceForm formData={formData} onSubmit={postWorkExperience} submitButtonTitle="下一步" />
                );
            default:
                break;
        }
        return <ApplicationChoice onChoice={onChoice} />;
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [applicationType]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    {getLayout()}
                </FormContainer>
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={onErrorClose} />
                <CertificateApplicationWarningModal
                    exam={userInfo?.exam ?? null}
                    show={isWarningVisible}
                    handleClose={() => setWarningVisible(false)}
                />
            </Col>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
};

export default CertificateApplication;
