import React from "react";
import { Table } from "react-bootstrap";

export type CellValue = React.ReactNode | string;
export type RowValues = Array<CellValue>;

export interface SearchTableProps {
    header: Array<string>;
    content: Array<RowValues>;
}

const renderContent = (values: Array<CellValue>, key: number) => {
    return (
        <tr key={key}>
            {values.map((value, key) => {
                return <td key={key}>{value}</td>;
            })}
        </tr>
    );
};
const SearchTable = ({ header, content }: SearchTableProps) => {
    return (
        <Table striped hover responsive>
            <thead>
                <tr>
                    {header.map((value) => (
                        <th key={value} scope="col">
                            {value}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>{content.map(renderContent)}</tbody>
        </Table>
    );
};

export default SearchTable;
