import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import MaterialIcon from "../material/MaterialIcon";

export interface ExamProps {
    name: string;
    status: string;
}

export interface TestResultProps {
    exam: ExamProps;
}

interface TestIconProps {
    status: string;
}

const TestIcon = ({ status }: TestIconProps) => {
    if (status === "通過") {
        return <MaterialIcon icon="check_circle" size={20} color="#60C213" />;
    }
    return <MaterialIcon icon="cancel" size={20} color="#f33223" />;
};

const TestResult = ({ exam: { name, status } }: TestResultProps) => {
    return (
        <Card className="dashboard-card">
            <Card.Header>
                <h4>測驗結果</h4>
            </Card.Header>
            <Card.Body>
                <Row className="dashboard-test-results">
                    <Col lg={10} xs={8}>
                        <div className="mark-secondary">{name}</div>
                    </Col>
                    <Col>
                        <TestIcon status={status} />
                        {status}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default TestResult;
