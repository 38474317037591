import React, { useEffect, useMemo, useState } from "react";
import PersonalDataAuthorizationForm, { FormData } from "../components/PersonalDataAuthorizationForm";
import { useSelector } from "react-redux";
import RfaApi from "../RfaApi";
import "../css/personal-data-authorization.sass";
import { UserState } from "../components/types";
import Meta from "../components/Meta";
import { Col, Row } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";

interface ErrorResponse {
    response: {
        data: string;
    };
}

export interface ApplicationChoiceProps {
    match: any;
}

const CertificatePersonalDataAuthorization = ({ match }: ApplicationChoiceProps) => {
    const pageTitle = "RFA個人資料授權對外公佈書";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const user = useSelector((state: UserState) => state.user);
    const [formData, setFormData] = useState<FormData>();
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();

    const apiError = (err: ErrorResponse) => {
        setErrorContent(err.response.data);
        setErrorVisible(true);
    };

    const responseToForm = (serverForm: FormData) => {
        setFormData({ ...serverForm });
    };

    const postPersonalDataAuthorization = (formData: FormData) => {
        rfaApi
            .UpdateAgreement(formData)
            .then(() => {
                const applicationType = match.params.type;
                //alert(JSON.stringify(formData));
                const options = {
                    ...formData,
                    ...{ applicationType: applicationType },
                };
                console.log(options);
                rfaApi
                    .RegisterCertificate(options)
                    .then((data) => {
                        //3.進入綠界交易畫面
                        window.location.href = `/api/payment/${data}`;
                    })
                    .catch((err) => {
                        console.log("RegisterCertificate,err:", err);
                        setErrorContent(err.response.data);
                        setErrorVisible(true);
                    });
            })
            .catch(apiError);
    };

    useEffect(() => {
        if (user && user.rfa) {
            rfaApi.GetAgreement().then(responseToForm).catch(apiError);
        }
    }, [user, rfaApi]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <PersonalDataAuthorizationForm
                        formData={formData}
                        onSubmit={postPersonalDataAuthorization}
                        submitButtonLabel="我同意，進入付款流程"
                    />
                    <div id="rfa_payment" />
                    {/*Payment container*/}
                </FormContainer>
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
        </Row>
    );
};

export default CertificatePersonalDataAuthorization;
