/**
 * Counts number of decimal digits
 * @param value
 * @returns {number}
 */
export const countDecimals = (value) => {
    if (!value) {
        return 0;
    }

    const totalLength = value.toString().length;
    const integerLength = value.toFixed().length;
    if (integerLength === totalLength) {
        return 0;
    }

    return totalLength - integerLength - 1;
};
