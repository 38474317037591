import React from "react";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import MaterialIcon from "../components/material/MaterialIcon";

export interface FormSuccessProps {
    className?: string;
    children: React.ReactNode;
}

const FormSuccess = ({ className, children }: FormSuccessProps) => {
    return (
        <Card className={`form-narrow ${className}`}>
            <Stack gap={5} className="d-flex justify-content-between align-items-center">
                <MaterialIcon icon="check_circle" size={100} color="#60C213" />
                {children}
            </Stack>
        </Card>
    );
};

export default FormSuccess;
