import React, { useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { formikErrorFeedback } from "../formik/Form";
import GenericSelect, { Option } from "../formik/GenericSelect";
import useCompanies from "../company/useCompanies";
import { companyTypes, TYPE_OTHER_COMPANY } from "../company/CompanyType";
import { countyCodes } from "../address/CountyCode";
import useRegions from "../address/useRegions";
import { ButtonGroup } from "react-bootstrap";

type SelectOption = Option<number, string>;
const genders: Array<SelectOption> = [
    {
        id: 0,
        label: "請選擇性別",
    },
    {
        id: 1,
        label: "男",
    },
    {
        id: 2,
        label: "女",
    },
];

const validationSchema = Yup.object({});

export interface FormData {
    name: string;
    county: string;
    workingArea: string;
    companyType: string;
    company: string;
    gender: number;
}

type FormKeys = keyof FormData;

export interface SearchFormProps {
    formData?: FormData;
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
    onReset: () => void;
}

const SearchForm = ({ formData, onSubmit, onReset }: SearchFormProps) => {
    const formik = useFormik({
        initialValues: {
            name: "",
            county: "",
            workingArea: "",
            companyType: "",
            company: "",
            gender: 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            onSubmit(values, formikHelpers);
        },
    });

    useEffect(() => {
        formik.setValues({ ...formik.values, ...formData });
    }, [formData]);

    const companyRegions = useRegions(formik.values.county);
    const companies = useCompanies(formik.values.companyType);

    const errorFeedback = (field: string) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field: FormKeys) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");
    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack className="form-content">
                <fieldset>
                    <Form.Group controlId="searchForm.name">
                        <Form.Label>姓名</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="請輸入姓名"
                            className={`form-control ${errorClass("name")}`}
                            {...formik.getFieldProps("name")}
                        />
                        {errorFeedback("name")}
                    </Form.Group>
                    <Form.Group controlId="searchForm.county">
                        <GenericSelect
                            label="任職縣市"
                            options={countyCodes}
                            errorClass={errorClass("county")}
                            {...formik.getFieldProps("county")}
                            onChange={(value) => {
                                formik.setFieldValue("county", value);
                                formik.setFieldValue("workingArea", "");
                            }}
                        />
                        {errorFeedback("county")}
                    </Form.Group>
                    <Form.Group controlId="searchForm.workingArea">
                        <GenericSelect
                            label="任職區域(請先選擇任職縣市)"
                            options={companyRegions}
                            errorClass={errorClass("workingArea")}
                            {...formik.getFieldProps("workingArea")}
                            onChange={(value) => formik.setFieldValue("workingArea", value)}
                        />
                        {errorFeedback("workingArea")}
                    </Form.Group>
                    <Form.Group controlId="searchForm.companyType">
                        <GenericSelect
                            label="單位類別"
                            options={companyTypes}
                            errorClass={errorClass("companyType")}
                            {...formik.getFieldProps("companyType")}
                            onChange={(value) => formik.setFieldValue("companyType", value)}
                        />
                        {errorFeedback("companyType")}
                    </Form.Group>

                    {formik.values.companyType !== TYPE_OTHER_COMPANY && (
                        <Form.Group controlId="searchForm.company">
                            <GenericSelect
                                label="現職服務單位(請先選擇單位類別)"
                                options={companies}
                                errorClass={errorClass("company")}
                                {...formik.getFieldProps("company")}
                                onChange={(value) => formik.setFieldValue("company", value)}
                            />
                            {errorFeedback("company")}
                        </Form.Group>
                    )}
                    {formik.values.companyType === TYPE_OTHER_COMPANY && (
                        <Form.Group controlId="searchForm.company">
                            <Form.Label>現職服務單位</Form.Label>
                            <Form.Control {...formik.getFieldProps("company")} placeholder="請輸入現職服務單位" />
                            {errorFeedback("company")}
                        </Form.Group>
                    )}
                    <Form.Group controlId="searchForm.gender">
                        <GenericSelect
                            label="性別"
                            options={genders}
                            errorClass={errorClass("gender")}
                            {...formik.getFieldProps("gender")}
                            onChange={(value) => formik.setFieldValue("gender", value)}
                        />
                        {errorFeedback("gender")}
                    </Form.Group>
                </fieldset>
                <ButtonGroup className="d-flex justify-content-between btn-group-spacing">
                    <Button type="button" variant="outline-primary" className="mt-3 mb-2" onClick={onReset}>
                        重置篩選
                    </Button>
                    <Button type="submit" variant="primary" className="mt-3 mb-2">
                        查詢結果
                    </Button>
                </ButtonGroup>
            </Stack>
        </Form>
    );
};

export default SearchForm;
