import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Receipt from "../components/Receipt";
import { ButtonGroup } from "react-bootstrap";
import Pdf from "react-to-pdf";
import RfaApi from "../RfaApi";
import Swal from "sweetalert2";
import moment from "moment";
import { toChineseWords } from "../components/payment/Numbers";
import { parseDate } from "../components/date/GregorianDate";

function getPdfName(paymentLog) {
    return `rfa-certificate-receipt-${paymentLog.Year}.${paymentLog.logDate}-${paymentLog.serialnumber}.pdf`;
}

// TODO: common logic for downloading count
const toDownload = () => {
    //     console.log('toDownload');
    //     setLog({ ...log, ...{ printCount: log.printCount + 1 } });
    //     console.log(log);
    //     rfaApi.PrintReceipt(log.serialnumber)
    //         .then((printresult) => {
    //             setLog({ ...log, printCount: printresult.printCount });
    //         });
};

const ScoreReceipt = () => {
    const history = useHistory();
    const rfaApi = new RfaApi();
    const user = useSelector((state) => state.user);
    const [log, setLog] = React.useState({});

    const doPrevious = () => {
        history.push("/profile/score-check");
    };

    const ref = React.createRef();
    const options = {
        orientation: "landscape",
        unit: "mm",
        format: [297, 210],
    };

    useEffect(() => {
        if (user && user.username) {
            rfaApi
                .GetScoreCheckReceipt()
                .then((rdata) => {
                    console.log("GetScoreCheckReceipt", rdata);
                    if (rdata && rdata.content) {
                        var pdate = parseDate(rdata.content.payAt);
                        rdata.content.Year = pdate.getFullYear() - 1911; //ROC year
                        rdata.content.logDate = moment(pdate).format("MM.DD");
                        rdata.content.Amount = toChineseWords(rdata.content.amount);
                        rdata.content.serialnumber = rdata.serialnumber;
                        setLog({
                            chName: rdata.content.chName,
                            vatTitle: rdata.content.vatTitle,
                            vatNumber: rdata.content.vatNumber,
                            printCount: 0,
                            serialnumber: rdata.content.serialnumber,
                            description: rdata.content.description,
                            Amount: rdata.content.Amount,
                            Year: rdata.content.Year,
                            logDate: rdata.content.logDate,
                        });
                    } else {
                        throw new Error("Invalid payment response");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    Swal.fire({ icon: "error", title: "尚未完成繳費" });
                    history.push("/profile/score-check");
                });
        }
    }, [user]);

    return (
        <div className="canvas score-receipt">
            <h2 className="canvas-title">成績複查收據</h2>
            <div className="container">
                <div className="row receipt">
                    <div className="col">
                        <Receipt log={log} ref={ref} />
                    </div>
                </div>
                <div className="row">
                    <ButtonGroup size="lg" className="d-flex justify-content-around">
                        <button type="button" className="btn btn-secondary" onClick={doPrevious}>
                            返回
                        </button>
                        <Pdf targetRef={ref} filename={getPdfName(log)} options={options}>
                            {({ toPdf }) => (
                                <button className="btn btn-primary" onClick={toPdf} onClickCapture={toDownload}>
                                    下載
                                </button>
                            )}
                        </Pdf>
                    </ButtonGroup>
                </div>
            </div>
        </div>
    );
};

export default ScoreReceipt;
