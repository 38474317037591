import React, { useEffect, useState } from "react";
import { FormDataEntry } from "../form/FormDataConfirm";
import { FormData } from "./SignupForm";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { companyTypesMap } from "../company/CompanyType";
import FormDataView from "../form/FormDataView";
import { formatDate } from "../date/GregorianDate";
import { findCityByArea } from "../address/City";

export interface SignupFormConfirmProps {
    formData: FormData;
    onPrevious: () => void;
    onNext: () => void;
}

const renderCfpAfp = ({ cfpAfp, certNum }: FormData) => {
    if (cfpAfp === "1") {
        return `是 ${certNum}`;
    }
    return "否";
};

const renderRegion = (regionId: string) => {
    return findCityByArea(regionId);
};

const mergeAddress = (addressParts: Array<String>) => {
    return addressParts.filter((el) => el !== undefined && el !== null && el.length).join(", ");
};

const SignupFormConfirm = ({ formData, onPrevious, onNext }: SignupFormConfirmProps) => {
    const companyTypes = companyTypesMap();
    const [confirmEntries, setConfirmEntries] = useState<Array<FormDataEntry>>([]);
    useEffect(() => {
        const entries: Array<FormDataEntry> = [];
        entries.push({ label: "身份", value: formData.applyType });
        entries.push({ label: "考區", value: formData.applyArea });
        entries.push({ label: "中文姓名", value: formData.chName });
        entries.push({ label: "英文姓名", value: formData.SurName + " " + formData.GivenName });
        entries.push({ label: "是否持有CFP/AFP證照", value: renderCfpAfp(formData) });
        entries.push({ label: "身份證字號", value: formData.idNum });
        entries.push({ label: "出生年月日", value: formatDate(formData.birthday) });
        entries.push({ label: "現職服務單位類別", value: companyTypes[formData.companyType] });
        entries.push({ label: "現職服務單位", value: formData.company });
        entries.push({ label: "收據抬頭", value: formData.vatTitle });
        entries.push({ label: "統一編號", value: formData.vatNumber });
        entries.push({ label: "最高學歷", value: formData.eduLevel });
        entries.push({ label: "畢業學校", value: formData.school });
        entries.push({ label: "科系", value: formData.eduClass });
        entries.push({ label: "學位取得日期", value: formData.graduateYear });
        entries.push({ label: "公司電話", value: formData.workPhone });
        entries.push({ label: "住家電話", value: formData.homePhone });
        entries.push({ label: "行動電話", value: formData.mobile });
        entries.push({ label: "電子郵件信箱", value: formData.email });
        entries.push({
            label: "公司地址",
            value: mergeAddress([formData.companyArea, renderRegion(formData.companyZipcode), formData.companyAddress]),
        });
        entries.push({
            label: "住家地址",
            value: mergeAddress([formData.homeArea, renderRegion(formData.homeZipcode), formData.homeAddress]),
        });
        entries.push({ label: "主要聯絡地址", value: formData.mainAddress });

        setConfirmEntries(entries);
    }, [formData]);

    return (
        <div className="form-confirm">
            <Row>
                <Col>
                    <FormDataView entries={confirmEntries} className="gray-labels" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup className="d-flex justify-content-between btn-group-spacing">
                        <Button variant="outline-primary" role="button" onClick={onPrevious}>
                            上一步
                        </Button>
                        <Button type="submit" variant="primary" onClick={onNext}>
                            下一步
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </div>
    );
};

export default SignupFormConfirm;
