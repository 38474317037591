import React from "react";
import FormSuccess from "../FormSuccess";
import Nav from "react-bootstrap/Nav";

const SubscriptionSuccess = () => {
    return (
        <FormSuccess>
            <p>付款成功，感謝您訂閱計算機！</p>
            <Nav.Link className="btn btn-primary w-100" role="button" href="/RetireComputer-Pro/Master.php">
                前往使用
            </Nav.Link>
        </FormSuccess>
    );
};

export default SubscriptionSuccess;
