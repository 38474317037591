import React, { useState } from "react";
import RfaApi from "../RfaApi";
import { FormikHelpers } from "formik";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ResetPasswordForm, { FormData } from "../components/ResetPasswordForm";
import ResetPasswordRequestSuccess from "../components/ResetPasswordRequestSuccess";

//const STR_SOMETHING_WENT_WRONG = "出問題了";

const isResultError = (result: { confirm?: boolean }) => !result || !result.confirm;

const LoginReset = () => {
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const pageTitle = "重設密碼";
    const rfaApi = new RfaApi();

    const resetPassword = async (params: FormData, { setErrors }: FormikHelpers<FormData>) => {
        const { email } = params;
        const result = await rfaApi.resetPassword(email);
        if (isResultError(result)) {
            setErrors({ email: result.message });
        } else {
            setIsSuccess(true);
        }
    };

    const renderLayout = () => {
        if (isSuccess) {
            return <ResetPasswordRequestSuccess />;
        }

        return (
            <FormContainer title={pageTitle} className="form-narrow">
                <ResetPasswordForm onSubmit={resetPassword} />
            </FormContainer>
        );
    };

    return (
        <>
            <Meta title={pageTitle} />
            {renderLayout()}
        </>
    );
};

export default LoginReset;
