import React from "react";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";

export interface FormNoIconProps {
    className?: string;
    children: React.ReactNode;
}

const FormNoIcon = ({ className, children }: FormNoIconProps) => {
    return (
        <Card className={`form-narrow ${className}`}>
            <Stack gap={5} className="d-flex justify-content-between align-items-center">
                {children}
            </Stack>
        </Card>
    );
};

export default FormNoIcon;
