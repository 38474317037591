import React from "react";
import MaterialIcon from "../material/MaterialIcon";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export interface SuccessModalProps {
    show: boolean;
    content?: string;
    handleClose: () => void;
}

const SuccessModal = ({ show, content, handleClose }: SuccessModalProps) => {
    return (
        <Modal className="form-alert alert-success" show={show} onHide={handleClose}>
            <Modal.Body>
                <MaterialIcon icon="check_circle" size={100} color="#60C213" />
                <p>{content ?? "保存變更成功"}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-primary" onClick={handleClose} type="button">
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default SuccessModal;
