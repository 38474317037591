import React from "react";
import { Col, Form, FormLabel } from "react-bootstrap";

type OptionKey = string | number;
type OptionLabel = string;

export interface Option<K extends OptionKey, L extends OptionLabel> {
    id: K;
    label: L;
}

export interface GenericSelectProps<K extends OptionKey, L extends OptionLabel> {
    name: string;
    errorClass: string;
    label?: string;
    value: K;
    options: Array<Option<K, L>>;
    onChange: (value?: string) => void;
}

const GenericSelect = <K extends OptionKey, L extends OptionLabel>({
    name,
    errorClass,
    label,
    value,
    options,
    onChange,
}: GenericSelectProps<K, L>) => {
    return (
        <>
            {label !== undefined && (
                <Col xs="auto">
                    <FormLabel>{label}</FormLabel>
                </Col>
            )}
            <Col xs="auto">
                <Form.Select
                    title="選擇單位類別"
                    className={`form-control ${errorClass}`}
                    value={value}
                    name={name}
                    onChange={(e) => onChange(e.target.value)}
                >
                    {options.map(({ id, label }) => (
                        <option key={id} value={id}>
                            {label}
                        </option>
                    ))}
                </Form.Select>
            </Col>
        </>
    );
};

export default GenericSelect;
