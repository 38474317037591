import React, { useEffect, useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { STR_REQUIRED_FIELD } from "../formik/Strings";
import { ButtonGroup } from "react-bootstrap";
import { formikErrorFeedback } from "../formik/Form";
import FormDataConfirm, { FormDataEntry } from "../form/FormDataConfirm";

const validationSchema = Yup.object({
    terms: Yup.boolean().oneOf([true], STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
});

export interface FormData {
    subscriberName: string;
    idNumber: string;
    certificateNumber: string;
    terms: boolean;
}

type FormKeys = keyof FormData;

export interface TryoutFormProps {
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
    formData: FormData;
}

const TryOutRequired = ({ onSubmit, formData }: TryoutFormProps) => {
    const formik = useFormik({
        initialValues: formData,
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            onSubmit(values, formikHelpers);
        },
    });

    const [confirmEntries, setConfirmEntries] = useState<Array<FormDataEntry>>([]);
    useEffect(() => {
        const { subscriberName, idNumber, certificateNumber } = formData;
        const entries: Array<FormDataEntry> = [];
        entries.push({ label: "訂閱人姓名", value: subscriberName });
        entries.push({ label: "身分證字號", value: idNumber });
        entries.push({ label: "結業證號", value: certificateNumber });

        setConfirmEntries(entries);
    }, [formData]);

    const errorFeedback = (field: string) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field: FormKeys) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");
    const go_previous = () => {
        window.location.href = "/";
    };
    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack className="form-content">
                <FormDataConfirm entries={confirmEntries}></FormDataConfirm>
                <fieldset>
                    <Form.Group>
                        <Form.Check type="checkbox" id={`terms`}>
                            <Form.Check.Input
                                type="checkbox"
                                className={errorClass("terms")}
                                {...formik.getFieldProps("terms")}
                            />
                            <Form.Check.Label>
                                <small>
                                    我同意接受
                                    <a href="/terms-of-service">退休基金協會條款</a>
                                </small>
                            </Form.Check.Label>
                            {errorFeedback("terms")}
                        </Form.Check>
                    </Form.Group>
                </fieldset>
                <fieldset>
                    <div className="small">
                        注意事項: 好命退休計算機RFA版（以下簡稱 本訂閱）係由中華民國退休基金協會（以下簡稱
                        退協）提供之訂閱服務，為保障您的權益，敬請詳閱以下注意事項。
                        <ol className="px-4">
                            <li>
                                若您初次訂閱本服務，您將享有免費30天試用期，試用期滿，再選擇訂閱方案，以啟動您的訂閱資格。
                            </li>
                            <li>
                                如果您向退協訂閱本服務，您必須同意以信用卡扣款作為購買本訂閱的付款方式，以啟動您的訂閱資格。
                            </li>
                            <li>
                                您可以在訂閱期間隨時取消本服務，當期計費週期結束前仍得繼續使用本服務，直到該計費週期到期，使喪失本服務。
                            </li>
                        </ol>
                    </div>
                </fieldset>
                <ButtonGroup className="d-flex justify-content-between btn-group-spacing">
                    <Button type="button" variant="outline-primary" onClick={go_previous}>
                        返回
                    </Button>
                    <Button type="submit" variant="primary">
                        前往試用
                    </Button>
                </ButtonGroup>
            </Stack>
        </Form>
    );
};

export default TryOutRequired;
