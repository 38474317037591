import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import PduStatusGrid from "../components/certificate/PduStatusGrid";
import "../css/grid.sass";
import RfaApi from "../RfaApi";

const PduStatus = () => {
    const history = useHistory();
    const rfaApi = new RfaApi();

    const [pduResults, setPduResults] = useState([]);

    const doPrevious = () => {
        history.push("/profile/dashboard");
    };
    useEffect(() => {
        rfaApi.GetPduHistory().then((pdus) => {
            console.log(pdus);
            setPduResults(pdus);
        });

        window.scrollTo(0, 0);
    }, []);

    return (
        <Container className="canvas">
            <h2 className="canvas-title">時數查詢</h2>
            <Row className="grid-content">
                <Col sm={12}>
                    <PduStatusGrid pduResults={pduResults} />
                </Col>
            </Row>
            <Row>
                <ButtonGroup size="lg" className="d-flex justify-content-around">
                    <button type="button" className="btn btn-secondary" onClick={doPrevious}>
                        回首頁
                    </button>
                </ButtonGroup>
            </Row>
        </Container>
    );
};

export default PduStatus;
