import React, { useEffect, useState } from "react";

export const formikErrorFeedback = (formik, field) => {
    return formik.touched[field] && formik.errors[field] ? (
        <div className="invalid-feedback">{formik.errors[field]}</div>
    ) : null;
};

export const errorClass = (formik, field) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

/**
 * Formik hook detecting an error state.
 *
 * @param formik
 * @returns {boolean}
 */
export const useFormError = (formik) => {
    const [isFormInvalid, setIsFormInvalid] = useState(false);

    /**
     * There is one case when condition in else is always false - on empty form submit.
     * Property isValidating is set to true after this effect is executed.
     * Without isValidating and isSubmitting, this effect would execute on user input, e.g. when user clears field.
     * That would lead to infinite loop.
     * This is the reason why submit count is used to determine appropriate condition.
     */
    useEffect(() => {
        if (formik.submitCount === 1) {
            setIsFormInvalid(formik.isSubmitting && !formik.isValid);
        } else {
            setIsFormInvalid(formik.isValidating && formik.isSubmitting && !formik.isValid);
        }
    }, [formik.isValidating, formik.isSubmitting, formik.isValid, formik.submitCount]);

    return isFormInvalid;
};
