import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
//import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import DashboardGrid from "../components/DashboardGrid";
import { Link } from "react-router-dom";
import RfaApi from "../RfaApi";

//title, status, amount, payStep, transactionAt, transactionId
interface GridRow {
    id: number;
    title: string;
    payAt: string;
    amount: string;
}

const ReceiptsEvents = () => {
    const pageTitle = "活動報名收據";
    //const history = useHistory();
    const rfaApi = new RfaApi();
    const user = useSelector((state: UserState) => state.user);
    const [mockData, setMockData] = useState<Array<GridRow>>([]);
    const parseDataRow = ({ id, title, payAt, amount }: GridRow) => {
        return [
            <Link key={id} to={`/profile/receipt/${id}`}>
                {title}
            </Link>,
            payAt,
            amount,
        ];
    };
    useEffect(() => {
        if (user && user.username) {
            //TODO: update endpoint for Event Receipts
            rfaApi
                .GetReceipts(`activity`)
                .then((rdata) => {
                    //console.log("GetCertificateReceipts", rdata);
                    if (rdata) {
                        setMockData(rdata);
                    } else {
                        throw new Error("Invalid payment response");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [user]);
    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <DashboardGrid header={["項目", "申請日期", "費用"]} content={mockData.map(parseDataRow)} />
                </FormContainer>
            </Col>
        </Row>
    );
};

export default ReceiptsEvents;
