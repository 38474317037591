import React from "react";
import { FormLabel, InputGroup } from "react-bootstrap";
import { formikErrorFeedback } from "./Form";

export interface Option {
    value: string | number;
    label: string;
}

export interface AgreementRadioGroupProps {
    formik: any;
    name: string;
    onChange: (value: string, name: string) => void;
    values: Array<Option>;
    label?: string;
}

const AgreementRadioGroup = ({ formik, name, onChange, values, label }: AgreementRadioGroupProps) => {
    const errorFeedback = (field: string) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field: string) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

    return (
        <>
            {label && <FormLabel>{label}</FormLabel>}
            {/*  className="justify-content-center" */}
            <InputGroup>
                {errorFeedback(name)}
                {values.map(({ value, label }) => {
                    const invalidClass = errorClass(name);
                    const id = `${name}-${value}`;

                    const currentFormValue = formik.getFieldProps(name);

                    return (
                        <div key={value} className={`form-check form-check-inline ${invalidClass}`}>
                            <input
                                key={value}
                                name={name}
                                id={id}
                                type="radio"
                                className={`form-check-input ${invalidClass}`}
                                {...formik.getFieldProps(name)}
                                value={value}
                                checked={currentFormValue.value === value}
                                onChange={(e) => onChange(e.target.value, name)}
                            />
                            <label htmlFor={id} className="form-check-label">
                                {label}
                            </label>
                        </div>
                    );
                })}
            </InputGroup>
        </>
    );
};

export default AgreementRadioGroup;
