import { Col, Row } from "react-bootstrap";
import React from "react";

interface DetailsProps {
    label: string;
    value: string;
}

const Details = ({ label, value }: DetailsProps) => {
    return (
        <Row key={label}>
            <Col className="form-confirm-label mark-secondary text-end">{label}</Col>
            <Col className="details-value">{value}</Col>
        </Row>
    );
};

export default Details;
