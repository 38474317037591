import React from "react";
import AgreementFinancialAdvisor from "./AgreementFinancialAdvisor";
import AgreementCorrectInformation, { UserProfile } from "./AgreementCorrectInformation";
import FormContainer from "../FormContainer";
import { Stack } from "react-bootstrap";

export interface AgreementStatementProps {
    // TODO: update types
    formik: any;
    onAgreementChange: (value: string, name: string) => void;
    userProfile: UserProfile;
}

const AgreementStatement = ({ formik, onAgreementChange, userProfile }: AgreementStatementProps) => {
    return (
        <FormContainer className="dashboard-card secondary" title="職業道德及執業準則聲明書">
            <Stack gap={5}>
                <AgreementCorrectInformation
                    formik={formik}
                    onAgreementChange={onAgreementChange}
                    userProfile={userProfile}
                />
                <AgreementFinancialAdvisor formik={formik} userProfile={userProfile} />
            </Stack>
        </FormContainer>
    );
};

export default AgreementStatement;
