import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";
import Event from "../components/events/Event";
import useEventData from "../components/events/useEventData";

const EventView = () => {
    const pageTitle = "活動詳細資訊";
    const user = useSelector((state: UserState) => state.user);
    const { id } = useParams<{ id?: string }>();
    const [errorContent, setErrorContent] = useState<string | null>(null);
    const handleApiError = (message: string) => {
        setErrorContent(message);
    };
    //hook for load data
    const { event, isLoading, backToList } = useEventData({ id, onError: handleApiError });

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card events">
                    {isLoading && "載入中..."}
                    {event && <Event data={event} />}
                </FormContainer>
                <ErrorModal
                    content={errorContent || ""}
                    show={!!errorContent}
                    handleClose={() => {
                        setErrorContent(null);
                        backToList();
                    }}
                />
            </Col>
        </Row>
    );
};

export default EventView;
