import React from "react";
import AgreementScroll from "./AgreementScroll";
import FormContainer from "../FormContainer";

export interface AgreementTrademarkProps {
    formik: any;
}

const AgreementTrademark = ({ formik }: AgreementTrademarkProps) => {
    return (
        <FormContainer className="dashboard-card secondary" title="商標使用授權書">
            <AgreementScroll formik={formik} name="agreementTrademark">
                <p>
                    RFA，退休理財規劃顧問(Retirement Financial Advisor)及商標
                    <span className="rfa-icon" />
                    為中華民國退休基金協會（以下簡稱本會）
                </p>
                <span className="agreement-bold">說明事項：</span>
                <ol className="nested">
                    <li>
                        初次認證申請人在完成本會教育訓練及測驗，並通過紀律道德審查後，將收到本會製發有關如何正確使用RFA，退休理財規劃顧問(Retirement
                        Financial Advisor)及商標
                        <span className="rfa-icon" />
                        之說明。唯有獲得本會正式授權者方能合法使用該商標。
                    </li>
                    <li>
                        初次認證申請人在收到本會發給之退休理財規劃顧問RFA證書之後，在該證書有效期間之內，即獲得本會授權使用上述商標
                        <span className="rfa-icon" />。
                    </li>
                    <li>
                        理財規劃顧問RFA需確實了解並遵守有關RFA，退休理財規劃顧問(Retirement Financial Advisor)及商標
                        <span className="rfa-icon" />
                        使用的各項規定。若有不正確使用或非法複製，經查證屬實且情節嚴重者，本會有權隨時撤銷或停止其商標
                        <span className="rfa-icon" />
                        使用權利。
                    </li>
                    <li>
                        本會官網 RFA專區所記載內容為RFA退休理財規劃顧問商標
                        <span className="rfa-icon" />
                        ，僅供經本會認證程序取得理財規劃顧問正式資格者使用，請勿擅自更改商標
                        <span className="rfa-icon" />
                        的文字、圖形、記號、顏色。
                    </li>
                    <li>
                        依據商標法第六十二條未得商標權人同意，有下列情形之一者，視為侵害商標權：
                        <ol className="nested">
                            <li>
                                明知為他人著名之註冊商標而使用相同或近似之商標或以該著名商標中之文字作為自己公司名稱、商號名稱、網域名稱或其他表彰營業主體或來源之標識，致減損著名商標之識別性或信譽者。
                            </li>
                            <li>
                                明知為他人之註冊商標，而以該商標中之文字作為自己公司名稱、商號名稱、網域名稱或其他表彰營業主體或來源之標識，致商品或服務相關消費者混淆誤認者。
                            </li>
                        </ol>
                    </li>
                </ol>
            </AgreementScroll>
        </FormContainer>
    );
};

export default AgreementTrademark;
