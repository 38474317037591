import React from "react";
import FormContainer from "../FormContainer";
import { PlanDescriptionProps } from "./PlanDescriptionProps";
import PlanDescription from "./PlanDescription";
import Plan, { PlanProps } from "./Plan";

export interface SubscriptionTableProps {
    title: string;
    visibleBreakpoint: "sm" | "md" | "lg";
    plans: Array<PlanProps>;
    planDescriptions: Array<PlanDescriptionProps>;
}

const renderPlan = (props: PlanProps) => {
    const { title } = props;
    return <Plan {...props} key={title} />;
};

const renderPlanDescription = (props: PlanDescriptionProps) => {
    const { title } = props;
    return <PlanDescription key={title} {...props} />;
};

const SubscriptionTable = ({ title, visibleBreakpoint, plans, planDescriptions }: SubscriptionTableProps) => {
    return (
        <FormContainer title={title} className={`d-none d-${visibleBreakpoint}-block shadow-sm`}>
            <table className="table table-bordered table-fixed subscription-table">
                <thead>
                    <tr>
                        <th className="description px-5 py-4" rowSpan={2} colSpan={3}>
                            <p className="mark-info">請選擇適合您的方案</p>
                        </th>
                        <th className="mark-secondary">民眾版</th>
                        <th colSpan={4} className="mark-secondary">
                            RFA版
                        </th>
                    </tr>
                    <tr>
                        <th className="bg-secondary mark-secondary">免費</th>
                        <th colSpan={4}>
                            <div className="subscription-plans-grid">{plans.map(renderPlan)}</div>
                        </th>
                    </tr>
                    <tr className="mark-secondary">
                        <th>項目</th>
                        <th colSpan={2}>說明</th>
                        <th colSpan={5}>民眾版 / RFA版，計算機功能差異</th>
                    </tr>
                </thead>
                <tbody>{planDescriptions.map(renderPlanDescription)}</tbody>
            </table>
        </FormContainer>
    );
};

export default SubscriptionTable;
