import React, { useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ProfileForm, { FormData } from "../components/profile/ProfileForm";
import RfaApi from "../RfaApi";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SuccessModal from "../components/form/SuccessModal";
import ErrorModal from "../components/form/ErrorModal";
import { UserState } from "../components/types";
import { formatDateInput } from "../components/date/GregorianDate";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

const Profile = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const userInfo = useSelector((state: UserState) => state.user);
    const pageTitle = "個人資料修改";

    const [formData, setFormData] = useState<FormData>();
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        if (userInfo && userInfo.username) {
            rfaApi
                .GetProfile()
                .then((data) => {
                    setFormData((prevState) => {
                        return {
                            ...prevState,
                            ...data,
                            ...{ birthday: formatDateInput(data?.birthday) },
                        };
                    });
                })
                .catch((err) => {
                    setErrorContent(err.response.data);
                    setErrorVisible(err);
                    history.push("/");
                });
        }
        window.scrollTo(0, 0);
    }, [userInfo, history, rfaApi]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <ProfileForm
                        formData={formData}
                        onSubmit={(formData) => {
                            rfaApi
                                .UpdateProfile({ ...formData })
                                .then(() => {
                                    setSuccessVisible(true);
                                })
                                .catch((err) => {
                                    setErrorContent(err.response.data);
                                    setErrorVisible(err);
                                });
                        }}
                    />
                </FormContainer>
                <SuccessModal show={isSuccessVisible} handleClose={() => setSuccessVisible(false)} />
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
};

export default Profile;
