import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
//import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import DashboardGrid from "../components/DashboardGrid";
import { Link } from "react-router-dom";
import RfaApi from "../RfaApi";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

//title, status, amount, payStep, transactionAt, transactionId
interface GridRow {
    id: number;
    title: string;
    payAt: string;
    amount: string;
}

const ReceiptsScorecheck = () => {
    const pageTitle = "成績複查收據";
    //const history = useHistory();
    const rfaApi = new RfaApi();
    const userInfo = useSelector((state: UserState) => state.user);
    const [mockData, setMockData] = useState<Array<GridRow>>([]);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const parseDataRow = ({ id, title, payAt, amount }: GridRow) => {
        return [
            <Link key={id} to={`/profile/receipt/${id}`}>
                {title}
            </Link>,
            payAt,
            amount,
        ];
    };
    useEffect(() => {
        if (userInfo && userInfo.username) {
            rfaApi
                .GetReceipts(`scorecheck`)
                .then((rdata) => {
                    //console.log("GetScorecheckReceipts", rdata);
                    if (rdata) {
                        //mockData = rdata;
                        setMockData(rdata);
                    } else {
                        throw new Error("Invalid payment response");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [userInfo]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <DashboardGrid header={["項目", "申請日期", "費用"]} content={mockData.map(parseDataRow)} />
                </FormContainer>
            </Col>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
};

export default ReceiptsScorecheck;
