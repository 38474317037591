import { DEF, store } from "../";

export const updateUser = (value: object) => {
    const dispatch = store.dispatch;
    console.log("updateUser", value);
    dispatch({ type: DEF.USER_UPDATE, value });
};

export const updateForm = (value: object) => {
    const dispatch = store.dispatch;
    console.log("updateForm", value);
    dispatch({ type: DEF.FORM_UPDATE, value });
};

export const updatePayment = (value: object) => {
    const dispatch = store.dispatch;
    console.log("updatePayment", value);
    dispatch({ type: DEF.PAYMET_UPDATE, value });
};
// eslint-disable-next-line
export default { updateUser, updateForm, updatePayment };
