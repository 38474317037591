import React from "react";
import ErrorModal from "../modals/ErrorModal";

export interface PaymentErrorModalProps {
    show: boolean;
    errorText: string;
    handleClose: () => void;
}

const PaymentErrorModal = ({ show, errorText, handleClose }: PaymentErrorModalProps) => {
    return <ErrorModal show={show} handleClose={handleClose} title={errorText} />;
};

export default PaymentErrorModal;
