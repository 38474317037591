import React, { useEffect, useState } from "react";
import PduForm from "../components/PduForm";
import RfaApi from "../RfaApi";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import Actions from "../actions/actions";

/**
 * Page for PDU Application edit
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PduRevise = ({ match }) => {
    const history = useHistory();
    const rfaApi = new RfaApi();

    const [pduData, setPduData] = useState({});
    const postPduApplication = (form) => {
        Actions.updateForm({ ...form });
        history.push("/profile/pdu-confirm");
    };
    const doPrevious = () => {
        history.push("/profile/pdu-status");
    };

    useEffect(() => {
        const { id } = match.params;
        rfaApi.GetPdu(id).then((pduData) => {
            //console.log('pduData', pduData);
            if (!pduData) {
                Swal.fire({ icon: "error", title: "無效請求" });
                doPrevious();
            } else {
                setPduData(pduData);
            }
        });
    }, []);

    return (
        <div className="canvas pdu-revise">
            <h2 className="canvas-title">時數申請</h2>
            <PduForm formData={pduData} onSubmit={postPduApplication} />
        </div>
    );
};

export default PduRevise;
