import React from "react";
import FormFailure from "../FormFailure";
import Nav from "react-bootstrap/Nav";

const SubscriptionSuccess = () => {
    return (
        <FormFailure>
            <p>付款失敗，請重新操作！</p>
            <Nav.Link className="btn btn-primary w-100" role="button" href="/">
                重新訂閱
            </Nav.Link>
            <p className="mark-info small">
                通常付款失敗，最常出現的幾個原因如下：
                <br />
                <br />
                1. 逾期失敗，操作時間超過4分半 信用卡繳費之後會有4分半的期限，逾時就會失敗需要再重新操作一次。
                <br />
                <br />
                2. 3D交易認證錯誤（消費者端自行操作錯誤）
                信用卡識別碼或是交易認證碼錯誤導致，請稍待5-10分鐘後再重新操作一次
            </p>
        </FormFailure>
    );
};

export default SubscriptionSuccess;
