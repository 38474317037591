import React from "react";
import AgreementRadioGroup from "../formik/AgreementRadioGroup";

const values = [
    {
        value: 0,
        label: "否",
    },
    {
        value: 1,
        label: "是",
    },
];

export interface AgreementYesNoProps {
    // TODO: update type
    formik: any;
    name: string;
    onChange: (value: string, name: string) => void;
}

const AgreementYesNo = ({ formik, name, onChange }: AgreementYesNoProps) => {
    return (
        <div className="row agreement-yes-no">
            <div className="col-auto">
                <AgreementRadioGroup values={values} name={name} onChange={onChange} formik={formik} />
            </div>
            <div className="col-auto">(請勾選)</div>
        </div>
    );
};

export default AgreementYesNo;
