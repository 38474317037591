import React, { useMemo, useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import RfaApi from "../RfaApi";
import { useHistory } from "react-router-dom";
import LoginChangeForm, { FormData } from "../components/LoginChangeForm";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import SuccessModal from "../components/form/SuccessModal";
import ErrorModal from "../components/form/ErrorModal";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

const LoginChange = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const userInfo = useSelector((state: UserState) => state.user);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const pageTitle = "密碼修改";
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);

    const submitChangePassword = ({ pass, newpass }: FormData) => {
        rfaApi.changePassword(pass, newpass).then((result) => {
            if (result.confirm === true) {
                setSuccessVisible(true);
            } else {
                setErrorVisible(true);
            }
        });
    };

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <div className="form-subtitle">
                        目前登入&nbsp;{userInfo.username}&nbsp;{userInfo.name}
                    </div>
                    <LoginChangeForm onSubmit={submitChangePassword} />
                </FormContainer>
                <SuccessModal
                    content="密碼已更新"
                    show={isSuccessVisible}
                    handleClose={() => {
                        setSuccessVisible(false);
                        history.push("/profile/dashboard");
                    }}
                />
                <ErrorModal content={"密碼更新失敗"} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
                {userInfo &&
                    userInfo?.pages?.advertisement?.map(
                        (item, index) =>
                            index === 2 && (
                                <AdvModal
                                    show={show}
                                    handleClose={handleClose}
                                    item={item}
                                    key={item.id}
                                    isChecked={isChecked}
                                    setIsChecked={setIsChecked}
                                    saveAdvertisent={saveAdvertisent}
                                />
                            )
                    )}
            </Col>
        </Row>
    );
};

export default LoginChange;
