import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "../css/certificate-download.sass";
import { Col, Row, Stack } from "react-bootstrap";
import { UserState } from "../components/types";
import Swal from "sweetalert2";

function guid() {
    function _p8(s: boolean) {
        const p = (Math.random().toString(16) + "000000000").substr(2, 8);
        return s ? "-" + p.substr(0, 4) + "-" + p.substr(4, 4) : p;
    }
    return _p8(false) + _p8(true) + _p8(true) + _p8(false);
}

const CertificateDownload = () => {
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);

    useEffect(() => {
        if (user && user.username) {
            if (user.certificate) {
                //持證人才能前往did平台
                const url = "/auth/did?" + guid(); //產生did平台jwt的api
                window.location.href = url;
            } else {
                Swal.fire({ icon: "warning", title: "僅限持有RFA有效證照之持證人" }).then(() => {
                    window.location.href = "/";
                    return;
                });
            }
        }
    }, []);

    return (
        <Row className="justify-content-center certificate-download">
            <Col></Col>
        </Row>
    );
};

export default CertificateDownload;
