import React, { MouseEventHandler } from "react";
import { getRocDate } from "./date/RocDate";
import { Button, Stack } from "react-bootstrap";
import Pdf from "react-to-pdf";
import ExamTime, { ExamDetails } from "./ExamTime";
import "../css/exam-ticket.sass";
import FormDataView from "../components/form/FormDataView";

export interface Admission {
    ticketNumber: string;
    title: string;
    chName: string;
    test_date: Date;
    stage1?: ExamDetails;
    stage2: ExamDetails;
    area: string;
    address: string;
}

export interface ExamTicketProps {
    admission: Admission;
    isPassed: boolean;
}

/**
 * Pass admission details into admission object. Flag isPassed determine whether to show stamp.
 *
 * @constructor
 */
const ExamTicket = ({ admission, isPassed }: ExamTicketProps) => {
    const ref = React.createRef<HTMLDivElement>();
    const pdfOptions = {
        orientation: "landscape",
        unit: "mm",
        format: [297, 210],
    };
    const getPdfName = () => {
        if (isPassed) {
            return `ticket-confirmed-${admission.ticketNumber}.pdf`;
        }

        return `ticket-${admission.ticketNumber}.pdf`;
    };

    const examTicketEntries = [
        { label: "測驗名稱", value: admission.title },
        { label: "考生姓名", value: admission.chName },
        { label: "准考證號碼", value: admission.ticketNumber },
        { label: "測驗日期", value: getRocDate(admission.test_date) },
        {
            label: "測驗時間",
            value: (
                <>
                    {admission.stage1 ? <ExamTime label={admission.stage1.title} details={admission.stage1} /> : <></>}
                    <ExamTime label={admission.stage2.title} details={admission.stage2} />
                </>
            ),
        },
        { label: "測驗地點", value: admission.area },
        { label: "地址", value: admission.address },
    ];

    return (
        <Stack gap={5}>
            <div className="ticket-pdf-wrapper">
                <div className="form-data-bordered exam-ticket" ref={ref}>
                    <FormDataView entries={examTicketEntries} className="gray-labels" />
                    {isPassed && (
                        <div className="exam-ticket-stamp-wrapper">
                            <div className="row exam-ticket-stamp-field">
                                <div className="stamp" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="d-grid">
                    <Pdf targetRef={ref} filename={getPdfName()} x={5} y={10} options={pdfOptions}>
                        {({ toPdf }: { toPdf: MouseEventHandler<HTMLButtonElement> }) => (
                            <Button variant="primary" onClick={toPdf}>
                                下載
                            </Button>
                        )}
                    </Pdf>
                </div>
            </div>
        </Stack>
    );
};

export default ExamTicket;
