import React, { useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { useFormError } from "./formik/Form";
import AgreementControl from "./formik/AgreementControl";
import { STR_REQUIRED_FIELD } from "./formik/Strings";
import { getRocDate } from "./date/RocDate";
import { showErrorAlert } from "./form/Errors";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import FormDataView from "../components/form/FormDataView";

const validationSchema = Yup.object({
    name: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    documentId: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    currentDate: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    consentNewspaperName: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    consentNewspaperCompany: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    consentOfficialLetterName: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    consentRfaName: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    consentRfaCompany: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    consentRfaEmail: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    consentRfaCompanyPhone: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    consentRfaMobilePhone: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
});

export interface FormData {
    name: string;
    documentId: string;
    currentDate: string;
    consentNewspaperName: string;
    consentNewspaperCompany: string;
    consentOfficialLetterName: string;
    consentRfaName: string;
    consentRfaCompany: string;
    consentRfaEmail: string;
    consentRfaCompanyPhone: string;
    consentRfaMobilePhone: string;
}

export interface PersonalDataAuthorizationFormProps {
    formData?: FormData;
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
    submitButtonLabel?: string;
}

const PersonalDataAuthorizationForm = ({
    formData,
    onSubmit,
    submitButtonLabel = "更新",
}: PersonalDataAuthorizationFormProps) => {
    const formik = useFormik<FormData>({
        initialValues: {
            name: formData?.name || "",
            documentId: formData?.documentId || "",
            currentDate: formData?.currentDate || new Date().toISOString(),
            consentNewspaperName: "",
            consentNewspaperCompany: "",
            consentOfficialLetterName: "",
            consentRfaName: "",
            consentRfaCompany: "",
            consentRfaEmail: "",
            consentRfaCompanyPhone: "",
            consentRfaMobilePhone: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            onSubmit(values, formikHelpers);
        },
    });

    useEffect(() => {
        formik.setValues({ ...formik.values, ...formData });
    }, [formData]);

    const isFormError = useFormError(formik);
    useEffect(() => {
        if (isFormError) {
            showErrorAlert();
        }
    }, [isFormError]);

    const onAgreementChange = (value: string, name: string) => {
        formik.setFieldValue(name, +value);
    };

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack className="form-content">
                <fieldset>
                    <div className="row">
                        <div className="col">
                            <FormDataView
                                entries={[
                                    { label: "本人", value: formik?.values?.name },
                                    { label: "身分證字號", value: formik?.values?.documentId },
                                ]}
                                className="blue-values"
                            />
                        </div>
                    </div>
                    <p className="mb-5">
                        本人茲提供以下個人資料予 中華民國退休基金協會 統一辦理媒體/網站刊登事宜。
                        本人充分了解中華民國退休基金協會對外公佈本人之相關資料，均係經本人提供辦理，中華民國退休基金協會對外不負責本人所提供個人相關資料之正確性，更不表示對本人執行業務時之保證。
                    </p>
                    <div className="rounded-table-wrapper table-responsive table-light-grid">
                        <table className="table dashboard-table table-large">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">項目</th>
                                    <th scope="col">說明</th>
                                    <th scope="col" colSpan={2}>
                                        公開欄位
                                    </th>
                                    <th scope="col">同意授權與否</th>
                                </tr>
                            </thead>
                            <tbody className="table-light-grid">
                                <tr>
                                    <td rowSpan={2}>持證人榜單</td>
                                    <td rowSpan={2}>刊登在報紙版面上</td>
                                    <td colSpan={2}>姓名</td>
                                    <td>
                                        <AgreementControl
                                            formik={formik}
                                            name="consentNewspaperName"
                                            onChange={onAgreementChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>現職任職公司</td>
                                    <td>
                                        <AgreementControl
                                            formik={formik}
                                            name="consentNewspaperCompany"
                                            onChange={onAgreementChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>通知任職公司</td>
                                    <td>
                                        協會採發函公文形 <br />
                                        式致持證人所屬公司，
                                        <br />
                                        希望給予表揚與鼓勵
                                    </td>
                                    <td colSpan={2}>姓名</td>
                                    <td>
                                        <AgreementControl
                                            formik={formik}
                                            name="consentOfficialLetterName"
                                            onChange={onAgreementChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={5}>協會官網</td>
                                    <td rowSpan={5}>供一般民眾官網查詢 RFA</td>
                                    <td colSpan={2}>姓名</td>
                                    <td>
                                        <AgreementControl
                                            formik={formik}
                                            name="consentRfaName"
                                            onChange={onAgreementChange}
                                        />
                                    </td>
                                </tr>

                                <tr>
                                    <td colSpan={2}>現職任職公司</td>
                                    <td>
                                        <AgreementControl
                                            formik={formik}
                                            name="consentRfaCompany"
                                            onChange={onAgreementChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>電子信箱</td>
                                    <td>
                                        <AgreementControl
                                            formik={formik}
                                            name="consentRfaEmail"
                                            onChange={onAgreementChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td rowSpan={2}>連絡電話</td>
                                    <td>公司電話</td>
                                    <td>
                                        <AgreementControl
                                            formik={formik}
                                            name="consentRfaCompanyPhone"
                                            onChange={onAgreementChange}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>行動電話</td>
                                    <td>
                                        <AgreementControl
                                            formik={formik}
                                            name="consentRfaMobilePhone"
                                            onChange={onAgreementChange}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="row mt-5">
                        <p>
                            註:如對上述項目有任何疑問，敬請不吝於與中華民國退休基金協會聯繫(02-2506-8185)。
                            <br />
                            以上本人所提供之個人相關資料日後如有變更時，本人同意即時通知中華民國退休基金協會變更。{" "}
                            <br />
                            中華民國退休基金協會同意，本人享有隨時以書面方式要求中華民國退休基金協會取消對外公佈本人個人相關資料之權利。
                            謹此聲明
                        </p>
                    </div>
                    <div className="row">
                        <div className="col mx-4">
                            <FormDataView
                                entries={[{ label: "中華民國", value: getRocDate(formik?.values?.currentDate) }]}
                                className="blue-values"
                            />
                        </div>
                    </div>
                </fieldset>
                <Button type="submit" variant="primary">
                    {submitButtonLabel}
                </Button>
            </Stack>
        </Form>
    );
};

export default PersonalDataAuthorizationForm;
