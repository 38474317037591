import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.sass";

import ProviderLevel from "./ProviderLevel";

ReactDOM.render(
    <React.StrictMode>
        <ProviderLevel>
            <App />
        </ProviderLevel>
    </React.StrictMode>,
    document.getElementById("root")
);
