import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RfaApi from "../RfaApi";
import PaymentInfo, { PaymentForm } from "../components/PaymentInfo";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";

/**
 * Page for certificate payment result
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CertificateResult = () => {
    const pageTitle = "支付结果";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);
    const [form, setForm] = useState<PaymentForm>();
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();

    const doPrevious = () => {
        history.push("/profile/certificate-application");
    };

    useEffect(() => {
        rfaApi
            .GetCertificatePayment()
            .then((result) => {
                setForm((prevState) => {
                    return { ...prevState, ...result };
                });
            })
            .catch((err) => {
                setErrorContent(err.response.data);
                setErrorVisible(true);
            });

        window.scrollTo(0, 0);
    }, [user, rfaApi]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    {form && <PaymentInfo form={form} doPrevious={doPrevious} />}
                </FormContainer>
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
        </Row>
    );
};

export default CertificateResult;
