import React from "react";
import { getRocDateDots } from "../date/RocDate";

interface EventDateTimeProps {
    date: Date;
    startTime: string;
    endTime: string;
}

const EventDateTime = ({ date, startTime, endTime }: EventDateTimeProps) => {
    const dateFormat = getRocDateDots(date);
    return (
        <>
            <span className="text-nowrap">{dateFormat}</span>{" "}
            <span className="text-nowrap">
                {startTime}-{endTime}
            </span>
        </>
    );
};

export default EventDateTime;
