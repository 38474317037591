import React from "react";

interface SeatLimitProps {
    seatLimit: number;
}

const SeatLimit = ({ seatLimit }: SeatLimitProps) => {
    if (seatLimit === 0) {
        return <span>無限制</span>;
    }

    return <span>{seatLimit}</span>;
};

export default SeatLimit;
