import React from "react";
import MaterialIcon from "../material/MaterialIcon";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import dayjs from "dayjs";
export interface CertificateApplicationWarningModalProps {
    show: boolean;
    exam: any;
    handleClose: () => void;
}

const CertificateApplicationWarningModal = ({ show, exam, handleClose }: CertificateApplicationWarningModalProps) => {
    const starAt = dayjs(exam.newcert.startAt);
    const endAt = dayjs(exam.newcert.endAt);
    const ROC_YAER = Number.parseInt(starAt.format("YYYY")) - 1911;
    return (
        <Modal className="form-alert confirm-payment-modal" show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="modal-inner-content">
                    <div className="p-3">
                        <MaterialIcon icon="error" size={110} color="#ff9d00" />
                    </div>
                    <Stack gap={5}>
                        <div>
                            認證申請期間：
                            <br />
                            {ROC_YAER}年{starAt.format("MM")}月{starAt.format("DD")}日 {starAt.format("HH:mm")}
                            <br />至 {endAt.format("MM")}月{endAt.format("DD")}日 {endAt.format("HH:mm")}
                            <br />
                        </div>
                        <div>
                            通過測驗者，請於 <br />
                            {endAt.format("MM")}月{endAt.format("DD")}日 {endAt.format("HH:mm")}前<br />
                            完成認證申請，
                            <br />
                            逾期不受理。
                        </div>
                        <div className="d-grid">
                            <Button type="button" variant="primary" onClick={handleClose}>
                                OK
                            </Button>
                        </div>
                    </Stack>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CertificateApplicationWarningModal;
