import React, { ReactNode, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Col, Nav, Row } from "react-bootstrap";
import DashboardGrid from "../components/DashboardGrid";
import FormContainer from "../components/FormContainer";
import Meta from "../components/Meta";
import RfaApi from "../RfaApi";
import { ActivityData, DataList, UserCertificate } from "../type";
import { minsToHours } from "../components/events/helper";
import ErrorModal from "../components/form/ErrorModal";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

export interface OnlineRow {
    name: string;
    orgName: string;
    completedDate: string;
    mins: number;
    updatedAt: string;
    userCertificate: UserCertificate;
}

export interface OfflineRow {
    mins: number;
    updatedAt: string;
    activity: ActivityData;
    userCertificate: UserCertificate;
}

export interface EventHistoryRow {
    name: string;
    orgName: string;
    date: string;
    mins: number;
    serialNumber: string;
}

const processOnlineRow = (row: OnlineRow): EventHistoryRow => {
    const pickFormOrgRow = _.pick(row, ["name", "orgName", "mins"]);
    return { ...pickFormOrgRow, date: row.completedDate, serialNumber: row.userCertificate.serialNumber };
};

const processOfflineRow = (row: OfflineRow): EventHistoryRow => {
    const { mins, activity, userCertificate } = row;
    const pickFormActivity = _.pick(activity, ["name", "orgName"]);
    return { ...pickFormActivity, mins, date: activity.activityDate, serialNumber: userCertificate.serialNumber };
};

const pageTitle = "【查詢】持續教育活動紀錄";

const defaultActiveKey = "online";

function EventHistory() {
    const [currentEventKey, setCurrentEventKey] = useState<string | null>(defaultActiveKey);
    const [data, setData] = useState<EventHistoryRow[]>([]);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [isLoading, setIsLoading] = useState(false);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const userInfo = useSelector((state: UserState) => state.user);
    const rfaApi = new RfaApi();
    const handleNavSelect = (eventKey: string | null) => {
        setCurrentEventKey(eventKey);
    };
    useEffect(() => {
        setIsLoading(true);
        if (currentEventKey === "online") {
            rfaApi
                .GetOnlineEventHistory()
                .then((respData: any) => {
                    if (respData) {
                        const mData = respData as DataList<OnlineRow>;
                        setIsLoading(false);
                        setData(mData.rows.map(processOnlineRow));
                    }
                })
                .catch((err: any) => {
                    console.error(err);
                    setErrorVisible(true);
                    setErrorContent("載入資料失敗");
                    window.location.href = "/profile/dashboard";
                });
        }
        if (currentEventKey === "offline") {
            rfaApi
                .GetOfflineEventHistory()
                .then((respData: any) => {
                    const mData = respData as DataList<OfflineRow>;
                    setIsLoading(false);
                    setData(mData.rows.map(processOfflineRow));
                })
                .catch((err: any) => {
                    console.error(err);
                    setErrorVisible(true);
                    setErrorContent("載入資料失敗");
                });
        }
        //
    }, [currentEventKey]);
    const dataRows = useMemo<ReactNode[][]>(
        () =>
            data.map((row) => {
                const { name, orgName, date, mins, serialNumber } = row;
                const hours = minsToHours(mins);
                return [name, orgName, date.replaceAll("-", "/"), hours, serialNumber];
            }),

        [data]
    );

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <Nav
                        className="mb-4"
                        variant="pills"
                        fill
                        onSelect={handleNavSelect}
                        defaultActiveKey={defaultActiveKey}
                    >
                        <Nav.Item>
                            <Nav.Link eventKey="online">數位課程</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="offline">實體課程</Nav.Link>
                        </Nav.Item>
                    </Nav>
                    <DashboardGrid
                        header={["活動名稱", "開課單位", "上課日期", "認證時數", "匯入證書號碼"]}
                        content={dataRows}
                        isLoading={isLoading}
                    />
                    <ErrorModal
                        content={errorContent}
                        show={isErrorVisible}
                        handleClose={() => {
                            setErrorVisible(false);
                            window.location.href = "/profile/dashboard";
                        }}
                    />
                </FormContainer>
            </Col>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
}

export default EventHistory;
