import moment from "moment";

const getDateFormat = (date) => date.toLocaleString("zh-TW", { dateStyle: "short", hour12: false });

export const formatDateInput = (date) => {
    if (!date) {
        return "";
    }

    return moment(date).format("yyyy-MM-DD");
};

export const formatDate = (date) => {
    if (date instanceof Date) {
        return getDateFormat(date);
    } else if (typeof date === "string") {
        const dateTime = newDate(date);
        return getDateFormat(dateTime);
    }

    return "";
};

const getDateShortDots = (date) => {
    const formattedDate = date.toLocaleString("zh-TW", { dateStyle: "short" });

    return formattedDate.replaceAll("/", ".");
};

export const formatDateShortDots = (date) => {
    if (date instanceof Date) {
        return getDateShortDots(date);
    } else if (typeof date === "string") {
        const dateTime = newDate(date);
        return getDateShortDots(dateTime);
    }

    return "";
};

const FORMAT_ISO8601_LENGTH = "YYYY-MM-DD".length;

export const parseDateStr = (dateString) => {
    if (typeof dateString === "string") {
        return dateString.replace(/-/g, "/");
    }

    return dateString;
};

/**
 * Handles date parsing in official supported formats which are recognized by most browsers, including Safari
 */
export const parseDate = (dateString) => {
    if (dateString.length !== FORMAT_ISO8601_LENGTH) {
        return new Date(dateString);
    }
    const parsedDate = parseDateStr(dateString);

    return new Date(parsedDate);
};

/**
 * Date initializer for all browsers
 * @param dateString
 * @returns {Date}
 */
export const newDate = (dateString) => {
    try {
        return parseDate(dateString);
    } catch (error) {
        console.error(`Cannot parse date ${dateString}: ${error}`);
        return new Date();
    }
};

const resetDateTime = (date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
};

/**
 * Is inputDate later or equal to inputComparedWith
 * @param inputDate
 * @param inputComparedWith
 * @returns {boolean}
 */
export const isDateLaterOrEqual = (inputDate, inputComparedWith) => {
    const date = newDate(inputDate);
    const comparedWith = newDate(inputComparedWith);

    resetDateTime(date);
    resetDateTime(comparedWith);

    return date.valueOf() >= comparedWith.valueOf();
};
