import React from "react";

interface Entry {
    label: string;
    value: string | React.ReactNode;
}

export interface FormDataProps {
    entries: Array<Entry>;
    className?: string;
}

const FormDataView = ({ entries, className }: FormDataProps) => {
    return (
        <div className={`form-data-align-middle ${className}`}>
            {entries.map(({ label, value }) => (
                <React.Fragment key={label}>
                    <span className="data-label">{label}</span>
                    <span className="data-value">{value}</span>
                </React.Fragment>
            ))}
        </div>
    );
};

export default FormDataView;
