import twzipcode from "twzipcode-data";
import _ from "lodash";

const zipcodes = twzipcode().zipcodes;
export const findZipcodeByArea = (areaId?: string) => {
    if (!areaId) {
        return "";
    }
    const result = _.find(zipcodes, { id: areaId });
    //console.log("findZipcodeByArea", areaId, result);
    if (!result) {
        return "";
    }
    return result.zipcode; // !== undefined ? result.zipcode : "";
};
