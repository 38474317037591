export interface CompanyType {
    id: string;
    label: string;
}

export const TYPE_OTHER_COMPANY = "99";
export const companyTypes: Array<CompanyType> = [
    { id: "0", label: "選擇單位類別" },
    { id: "1", label: "01-銀行" },
    { id: "2", label: "02-保險公司" },
    { id: "3", label: "03-保險經紀人" },
    { id: "4", label: "04-保險代理人" },
    { id: "5", label: "05-證券" },
    { id: "6", label: "06-投信" },
    { id: "7", label: "07-投顧" },
    { id: "8", label: "08-大專校院" },
    { id: TYPE_OTHER_COMPANY, label: "99-其他" },
];

export type CompanyTypesMap = Record<string, string>;
export const companyTypesMap = () => {
    return companyTypes.reduce((result, { id, label }) => {
        result[id] = label;

        return result;
    }, {} as CompanyTypesMap);
};
