import React, { useEffect, useState } from "react";
import { formatDateShortDots } from "../date/GregorianDate";
import Details from "./Details";
import { Col, Row } from "react-bootstrap";

export interface CertificateProps {
    idNum: string;
    effectiveDate: string; //原 Date
    expireDate: string; // 原 Date
    educationHours: number;
    educationHoursMax: number;
    rfaHours: number;
    rfaHoursMax: number;
    extendExpireDate: string;
}

export interface CertificateDetailsProps {
    certificate: CertificateProps;
    isProgressVisible: boolean;
}

interface ProgressBarProps {
    title: string;
    percentComplete: number;
    Hours: number;
    HoursMax: number;
}

const ProgressBar = ({ title, percentComplete, Hours, HoursMax }: ProgressBarProps) => {
    return (
        <div className="progress-wrapper">
            <p className="mark-secondary">{title}</p>
            <div className="progress">
                <div
                    className="progress-bar"
                    style={{ width: `${percentComplete}%` }}
                    role="progressbar"
                    aria-valuenow={Hours}
                    aria-valuemin={0}
                    aria-valuemax={HoursMax}
                ></div>
            </div>
            <Row className="dashboard-progress-legend">
                <Col className="done-hours-label">
                    <small>已完成 {Hours} 小時</small>
                </Col>
                <Col className="required-hours-label">
                    <small>尚需要 {HoursMax - Hours} 小時</small>
                </Col>
            </Row>
        </div>
    );
};

const CertificateDetails = ({
    certificate: { idNum, effectiveDate, expireDate, educationHours, educationHoursMax, rfaHours, rfaHoursMax },
    isProgressVisible,
}: CertificateDetailsProps) => {
    const [percentComplete, setPercentComplete] = useState(0);
    const [rfaComplete, setRfaComplete] = useState(0);
    const getPercentComplete = () => Math.ceil(100 * (educationHours / educationHoursMax));
    const getRfaComplete = () => Math.ceil(100 * (rfaHours / rfaHoursMax));

    useEffect(() => {
        const percent = getPercentComplete();
        setPercentComplete(percent);
        const rfa_percent = getRfaComplete();
        setRfaComplete(rfa_percent);
    }, []);

    return (
        <div className="dashboard-certificate-details">
            <div className="dashboard-user-data align-items-start">
                <Details label="證書證號" value={idNum} />
                <Details label="生效日" value={formatDateShortDots(effectiveDate)} />
                <Details label="到期日" value={formatDateShortDots(expireDate)} />
            </div>
            {isProgressVisible && (
                <ProgressBar
                    title="協會課程時數 (必修)"
                    percentComplete={rfaComplete}
                    Hours={rfaHours}
                    HoursMax={rfaHoursMax}
                />
            )}
            {isProgressVisible && (
                <ProgressBar
                    title="活動課程時數 (選修)"
                    percentComplete={percentComplete}
                    Hours={educationHours}
                    HoursMax={educationHoursMax}
                />
            )}
            {/* <div className="dashboard-user-data align-items-start">
                <Details label="過往證書證號" value="RFAC1101013" />
            </div> */}
        </div>
    );
};

export default CertificateDetails;
