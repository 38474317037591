import React from "react";
import FormSuccess from "./FormSuccess";
import Nav from "react-bootstrap/Nav";

const ResetPasswordRequestSuccess = () => {
    return (
        <FormSuccess>
            <p>重設密碼信件已送出，請至您的信箱查看。</p>
            <Nav.Link className="btn btn-primary w-100" role="button" href="/">
                回到首頁
            </Nav.Link>
        </FormSuccess>
    );
};

export default ResetPasswordRequestSuccess;
