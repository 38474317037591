import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import RfaApi from "../RfaApi";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import SuccessModal from "../components/form/SuccessModal";
import ErrorModal from "../components/form/ErrorModal";
import PersonalDataAuthorizationForm, { FormData } from "../components/PersonalDataAuthorizationForm";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

const PersonalDataAuthorization = () => {
    const pageTitle = "RFA 個人資料授權對外公佈書";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const userInfo = useSelector((state: UserState) => state.user);
    const [formData, setFormData] = useState<FormData>();
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const responseToForm = (serverForm: FormData) => {
        setFormData({ ...serverForm });
    };

    const onError = (error: any) => {
        // TODO: error message
        setErrorContent(error?.response?.data ?? "");
        setErrorVisible(true);
    };

    const postPersonalDataAuthorization = (formData: FormData) => {
        rfaApi
            .UpdateAgreement(formData)
            .then((result) => setSuccessVisible(true))
            .catch(onError);
    };

    useEffect(() => {
        if (userInfo && userInfo.rfa) {
            rfaApi.GetAgreement().then(responseToForm).catch(onError);
        }
    }, [userInfo, rfaApi]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <PersonalDataAuthorizationForm formData={formData} onSubmit={postPersonalDataAuthorization} />
                </FormContainer>
                <SuccessModal show={isSuccessVisible} handleClose={() => setSuccessVisible(false)} />
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
};

export default PersonalDataAuthorization;
