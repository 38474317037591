import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import { UserState } from "../components/types";
import { useHistory, useParams } from "react-router-dom";
import SuccessModal from "../components/events/SuccessModal";
import ConfirmPaymentModal from "../components/events/ConfirmPaymentModal";
import PaymentErrorModal from "../components/events/PaymentErrorModal";
import PaymentFailedErrorModal from "../components/events/PaymentFailedErrorModal";
import EventRegisterFormConfirm from "../components/events/EventRegisterFormConfirm";
import useEventData from "../components/events/useEventData";
import ErrorModal from "../components/form/ErrorModal";
import useUserProfile from "../components/events/useUserProfile";
import useEventRegister, { RegisterErrorStatus } from "../components/events/useEventRegister";

const pageTitle = "活動報名資料";

const EventRegistrationConfirm = () => {
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);
    const [errorContent, setErrorContent] = useState<string | null>(null);
    const [isConfirmPaymentVisible, setConfirmPaymentVisible] = useState<boolean>(false);
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isPaymentErrorVisible, setPaymentErrorVisible] = useState<boolean>(false);
    const [isPaymentFailedErrorVisible, setPaymentFailedErrorVisible] = useState<boolean>(false);
    const [paymentForm, setPaymentForm] = useState<string | null>(null);
    const { id } = useParams<{ id?: string }>();

    const handleApiError = (message: string) => {
        setErrorContent(message);
    };
    //hook for load data
    const eventLoader = useEventData({ id, onError: handleApiError });
    const userProfileLoader = useUserProfile({ onError: handleApiError });

    const isLoading = eventLoader.isLoading || userProfileLoader.isLoading;
    const profile = userProfileLoader.profile;
    const event = eventLoader.event;

    const isFreeEvent = event?.amount === 0;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [user, eventLoader.isLoading]);

    const handlePaymentForm = (formContent: string) => {
        //付費表單處理
        setPaymentForm(formContent);
        //當有表單資料之後，觸發下面的useEffect
    };

    const handleRegisterSuccess = () => {
        //報名成功 (免費活動)
        setConfirmPaymentVisible(false);
        setSuccessVisible(true);
    };

    //付款處理hook
    const { isProcessing, doRegister } = useEventRegister({
        onError: handleApiError,
        onPayment: handlePaymentForm,
        onSuccess: handleRegisterSuccess,
    });

    const doPrevious = () => {
        history.push(`/profile`);
    };

    const doNext = () => {
        console.log("doPayment");
        setConfirmPaymentVisible(true);
    };

    const handleRegistrationConfirm = () => {
        if (event) {
            doRegister(Number(event.id), isFreeEvent);
        }
    };

    useEffect(() => {
        if (paymentForm) {
            try {
                const formAiochkElement = document.querySelector("#_form_aiochk");
                if (!formAiochkElement) {
                    throw new Error("Missing Aiochk in the DOM");
                }
                (formAiochkElement as HTMLFormElement).submit();
            } catch (err: any) {
                console.log("GetPaymentForm", err);
                if ("message" in err) {
                    setErrorContent(err.message);
                }
            }
        }
    }, [paymentForm]);
    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                {!isLoading && profile && event ? (
                    <>
                        <FormContainer title={pageTitle} className="dashboard-card">
                            <EventRegisterFormConfirm
                                event={event}
                                formData={{
                                    chName: profile.chName,
                                    mobile: profile.mobile,
                                    email: profile.email,
                                }}
                                onPrevious={doPrevious}
                                onNext={doNext}
                            />
                        </FormContainer>
                        <ConfirmPaymentModal
                            show={isConfirmPaymentVisible}
                            handleConfirm={handleRegistrationConfirm}
                            handleClose={() => setConfirmPaymentVisible(false)}
                            chName={profile.chName}
                            mobile={profile.mobile}
                            isProcessing={isProcessing}
                        ></ConfirmPaymentModal>
                        <SuccessModal
                            show={isSuccessVisible}
                            handleClose={() => {
                                setSuccessVisible(false);
                                history.push("/profile/event-registration-status");
                            }}
                        />
                        <PaymentErrorModal
                            show={isPaymentErrorVisible}
                            handleClose={() => setPaymentErrorVisible(false)}
                        />
                        <PaymentFailedErrorModal
                            show={isPaymentFailedErrorVisible}
                            handleClose={() => setPaymentFailedErrorVisible(false)}
                        />
                    </>
                ) : (
                    <>載入中...</>
                )}
            </Col>
            {/*Payment container*/}
            {paymentForm && <div id="rfa_payment" dangerouslySetInnerHTML={{ __html: paymentForm }} />}
            <ErrorModal
                content={errorContent || ""}
                show={!!errorContent}
                handleClose={() => {
                    if (errorContent === RegisterErrorStatus.NO_DATA) {
                        eventLoader.backToList();
                    } else if (errorContent === RegisterErrorStatus.INVALID_CERT) {
                        history.replace("/");
                    }
                    setErrorContent(null);
                }}
            />
        </Row>
    );
};

export default EventRegistrationConfirm;
