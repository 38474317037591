import React from "react";
import PduForm from "../components/PduForm";
import Actions from "../actions/actions";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

/**
 * New PDU application
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PduApplication = () => {
    const history = useHistory();
    const form = useSelector((state) => state.form);

    const postPduApplication = (form) => {
        Actions.updateForm({ ...form });
        history.push("/profile/pdu-confirm");
    };

    return (
        <div className="canvas pdu-application">
            <h2 className="canvas-title">時數申請</h2>
            <PduForm formData={form} onSubmit={postPduApplication} />
        </div>
    );
};

export default PduApplication;
