import React from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { formikErrorFeedback } from "../components/formik/Form";
import { STR_IS_EMAIL, STR_REQUIRED_FIELD } from "../components/formik/Strings";

const validationSchema = Yup.object({
    email: Yup.string().required(STR_REQUIRED_FIELD).email(STR_IS_EMAIL).typeError(STR_REQUIRED_FIELD),
});

export interface FormData {
    email: string;
}

type FormKeys = keyof FormData;

export interface ResetPasswordFormProps {
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
}

const ResetPasswordForm = ({ onSubmit }: ResetPasswordFormProps) => {
    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            onSubmit(values, formikHelpers);
        },
    });

    const errorFeedback = (field: string) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field: FormKeys) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack className="form-content">
                <fieldset>
                    <Form.Group controlId="resetPasswordForm.email">
                        <Form.Label>請輸入您的電子郵件信箱</Form.Label>
                        <Form.Control
                            type="email"
                            placeholder="請輸入email"
                            className={`form-control ${errorClass("email")}`}
                            {...formik.getFieldProps("email")}
                        />
                        {errorFeedback("email")}
                    </Form.Group>
                    <Form.Text id="emailHelp" className="text-muted">
                        <small>系統將寄出重設密碼的信件給您。</small>
                    </Form.Text>
                </fieldset>
                <Button type="submit" variant="primary">
                    送出
                </Button>
            </Stack>
        </Form>
    );
};

export default ResetPasswordForm;
