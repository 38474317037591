import React from "react";
import { PlanDescriptionProps } from "./PlanDescriptionProps";
import PlanIcon from "./PlanIcon";

const PlanDescription = ({ title, description, isFree }: PlanDescriptionProps) => {
    return (
        <tr>
            <td className="description">{title}</td>
            <td colSpan={2} className="description">
                <small>{description}</small>
            </td>
            <td>
                <PlanIcon isAvailable={isFree} />
            </td>
            <td colSpan={4}>
                <PlanIcon isAvailable={true} />
            </td>
        </tr>
    );
};

export default PlanDescription;
