import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CertificateLogo from "../components/certificate/CertificateLogo";
import RfaApi from "../RfaApi";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";

const LogoDownload = () => {
    const pageTitle = "認證商標下載";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const user = useSelector((state: UserState) => state.user);
    const [logoUrl, setLogoUrl] = useState("");
    const [isLogoVisible, setLogoVisible] = useState(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();

    useEffect(() => {
        setLogoUrl(`/rfa-logo.png`);
    }, []);

    useEffect(() => {
        if (user && user.username) {
            rfaApi
                .GetCertificateFile()
                .then((rdata) => {
                    console.log("GetCertificateFile", rdata);
                    if (!rdata) {
                        throw new Error("Invalid payment response");
                    } else {
                        setLogoVisible(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setErrorContent("無資料");
                    setErrorVisible(true);
                });
        }
    }, [user]);

    const renderLayout = () => {
        if (isLogoVisible) {
            return <CertificateLogo logoUrl={logoUrl} />;
        }

        return <div>無法使用</div>;
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    {renderLayout()}
                </FormContainer>
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
        </Row>
    );
};

export default LogoDownload;
