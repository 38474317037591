import React from "react";
import { errorClass } from "../formik/Form";

export interface CheckboxAgreementProps {
    // TODO: update type
    formik: any;
    name: string;
    disabled: boolean;
}

const CheckboxAgreement = ({ formik, name, disabled = false }: CheckboxAgreementProps) => {
    return (
        <div className="form-check form-check-inline">
            <input
                id={name}
                className={`form-check-input ${errorClass(formik, name)}`}
                type="checkbox"
                {...formik.getFieldProps(name)}
                checked={formik.getFieldProps(name).value}
                disabled={disabled}
            />
            <label htmlFor={name} className="form-check-label">
                我已經充份了解並同意遵守以上說明事項
            </label>
        </div>
    );
};

export default CheckboxAgreement;
