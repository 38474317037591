import React, { useEffect, useState } from "react";
import UserDetails, { ProfileProps, UserProps } from "../components/dashboard/UserDetails";
import Certificates from "../components/dashboard/Certificates";
import TestResult, { ExamProps } from "../components/dashboard/TestResult";
// import { useSelector } from "react-redux";
//import logoUrlPath from "../static/rfa-logo.png";
import RfaApi from "../RfaApi";
import { CertificateProps } from "../components/dashboard/CertificateDetails";
import Meta from "../components/Meta";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import AdvModal from "../components/modals/AdvertisementModal";

export interface AdvertsProps {
    hasAdverts: boolean;
}

dayjs.extend(utc);
dayjs.extend(timezone);

const Dashboard = () => {
    const userInfo = useSelector((state: UserState) => state.user);
    const [user, setUser] = useState<UserProps>();
    const [profile, setProfile] = useState<ProfileProps>();
    const [certificate, setCertificate] = useState<CertificateProps>();
    const [previousCertificatesNumbers, setPreviousCertificatesNumbers] = useState<Array<string>>([]);
    const [lastExam, setLastExam] = useState<ExamProps>();
    const rfaApi = new RfaApi();
    const today = dayjs().tz("Asia/Taipei");
    const formattedDate = today.format("YYYY-MM-DD");
    const [disabled, setDisabled] = useState(false);

    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    // useEffect(() => {
    //     setUploadedImage(modalImage);
    //     setShow(true);
    // }, []);

    useEffect(() => {
        rfaApi.GetDashboard().then((db) => {
            console.log(db);

            // // 不再顯示廣告用
            // const [adverts, setAdverts] = useState<AdvertsProps>({ hasAdverts: false });
            // // 彈窗
            // const [show, setShow] = useState(false);
            // const [uploadedImage, setUploadedImage] = useState<string>(modalImage);

            // const handleClose = () => setShow(false);
            // const handleShow = () => setShow(true)

            setUser({
                chineseName: db.profile.chName,
                englishName:
                    (db.profile.SurName ? db.profile.SurName : "") +
                    " " +
                    (db.profile.GivenName ? db.profile.GivenName : ""),
            });
            setProfile({
                idNum: db.profile.idNum,
                email: db.profile.email,
                phone: db.profile.mobile,
                company: db.profile.company,
                applicationStatus: db.applicationStatus,
            });
            if (db.certificate) {
                setCertificate({
                    idNum: db.certificate.serialNumber,
                    effectiveDate: db.certificate.issueDate,
                    expireDate: db.certificate.expireDate,
                    educationHours: db.certificate.hours,
                    educationHoursMax: 21,
                    rfaHours: db.certificate.rfaHours,
                    rfaHoursMax: 9,
                    extendExpireDate: db.certificate.extendExpireDate,
                });
                setPreviousCertificatesNumbers([db.certificate.serialNumber]);
            }

            if (db.score) {
                setLastExam({
                    name: db.score.name,
                    status: db.score.status,
                });
            }
            if (db.certificate?.expireDate > formattedDate) {
                setDisabled(true);
            }
        });
    }, []);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    // console.log(certificate?.expireDate);

    // console.log(formattedDate);

    // user.certificate.extendExpireDate && user.certificate.extendExpireDate >= formattedDate

    return (
        <div className="dashboard">
            <Meta title={"RFA專區"} />
            {user && profile && <UserDetails user={user} profile={profile} />}
            {/* disabled 判斷證照加三年內可顯示 但認證圖案的按鈕隱藏(按鈕元件子層內做隱藏) */}
            {certificate && (
                <Certificates
                    logoUrl={"/rfa-logo.png"}
                    certificate={certificate}
                    disabled={disabled}
                    previousCertificatesNumbers={previousCertificatesNumbers}
                />
            )}
            {lastExam && <TestResult exam={lastExam} />}
            {/* <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered={true}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body className="pa-4" id="contained-modal-title-vcenter">
                    <a href="https://www.google.com.tw/?hl=zh_TW" target="_blank" rel="noreferrer">
                        <Image src={uploadedImage} width={`100%`} height={420}></Image>
                    </a>
                    <Stack gap={3} className="d-flex justify-content-center align-items-center">
                        <Button variant="primary" onClick={handleClose}>
                            關閉廣告
                        </Button>
                    </Stack>
                </Modal.Body>
            </Modal> */}
            {/* {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item: any, index: number) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )} */}
        </div>
    );
};

export default Dashboard;
