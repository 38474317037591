import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import RfaApi from "../RfaApi";
import { UserState } from "../components/types";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import DashboardGrid from "../components/DashboardGrid";
import ErrorModal from "../components/modals/ErrorModal";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

enum StatusEnum {
    FAILED = 0,
    PASSED = 1,
}

const StatusCodes = {
    [StatusEnum.FAILED]: "未通過",
    [StatusEnum.PASSED]: "通過",
};
type StatusCodesKey = keyof typeof StatusCodes;

export interface ScoreTestStatusData {
    id: number;
    name: string;
    result: StatusCodesKey;
    checkResult: number;
    allowCheck: boolean;
}

interface GridRow {
    id: number;
    examName: string;
    statusId: StatusCodesKey;
    checkResult: number;
    allowCheck: boolean;
}

const parseScoreTestStatusData = (data: ScoreTestStatusData): GridRow => {
    return {
        id: data.id,
        examName: data.name,
        statusId: data.result,
        checkResult: data.checkResult,
        allowCheck: data.allowCheck,
    };
};

const ScoreTestStatus = () => {
    const pageTitle = "測驗成績/複查查詢";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const userInfo = useSelector((state: UserState) => state.user);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>("");
    const [testResults, setTestResults] = useState<Array<GridRow>>([]);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const parseDataRow = ({ id, examName, statusId, checkResult, allowCheck }: GridRow) => {
        const result = checkResult === 1 ? StatusCodes[1] : StatusCodes[statusId];

        return [
            examName,
            result,
            checkResult !== null ? (
                <Link key={id} to={`/profile/score-status/${id}`} className="btn btn-primary">
                    成績複查結果
                </Link>
            ) : allowCheck === true ? (
                <Link key={id} to={`/profile/score-check-request`} className="btn btn-primary">
                    成績複查申請
                </Link>
            ) : (
                "-"
            ),
        ];
    };
    useEffect(() => {
        rfaApi.GetScore().then((data) => {
            console.log("GetScore", data);
            if (data && data.length) {
                setTestResults(data.map(parseScoreTestStatusData));
            } else {
                setErrorContent("無測驗結果");
                setErrorVisible(true);
            }
        });
        window.scrollTo(0, 0);
    }, [userInfo, rfaApi]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <DashboardGrid header={["項目", "測驗結果", "成績複查"]} content={testResults.map(parseDataRow)} />
                </FormContainer>
                <ErrorModal
                    title={errorContent}
                    show={isErrorVisible}
                    handleClose={() => {
                        setErrorVisible(false);
                    }}
                />
            </Col>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
};

export default ScoreTestStatus;
