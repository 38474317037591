import { useEffect, useState } from "react";
import RfaApi from "../../RfaApi";
import { UserProfile } from "../../type";

export interface UserProfileHookConfig {
    onError: (message: string) => void;
}

function useUserProfile({ onError }: UserProfileHookConfig) {
    const rfaApi = new RfaApi();
    const [isLoading, setIsLoading] = useState(false);
    const [profile, setProfile] = useState<UserProfile>();
    useEffect(() => {
        setIsLoading(true);
        rfaApi
            .GetDashboard()
            .then((db) => {
                setProfile(db.profile);
            })
            .catch((err: any) => {
                console.error(err);
                onError("載入個人資料失敗");
            })
            .finally(() => {
                setIsLoading(false);
            });
    }, []);
    return { profile, isLoading };
}

export default useUserProfile;
