import React, { useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { STR_REQUIRED_FIELD } from "../formik/Strings";
import { Stack } from "react-bootstrap";
import AgreementStatements from "./AgreementStatements";
import AgreementTrademark from "./AgreementTrademark";
import { useFormError } from "../formik/Form";
import { showErrorAlert } from "../form/Errors";
import { UserProfile } from "./AgreementCorrectInformation";

export interface FormData {
    noCrime: number;
    noLitigation: number;
    noTaxEvasion: number;
    agreementTrademark: boolean;
    agreementFinancialAdvisor: boolean;
    agreementCorrectInformation: boolean;
}

export type FormKeys = keyof FormData;

export interface AgreementFormProps {
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
    userProfile: UserProfile;
}

const AgreementForm = ({ onSubmit, userProfile }: AgreementFormProps) => {
    const formik = useFormik({
        initialValues: {
            noCrime: 1,
            noLitigation: 1,
            noTaxEvasion: 1,
            agreementTrademark: true,
            agreementFinancialAdvisor: true,
            agreementCorrectInformation: true,
        },
        validationSchema: Yup.object({
            noCrime: Yup.boolean().oneOf([true, false], STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            noLitigation: Yup.boolean().oneOf([true, false], STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            noTaxEvasion: Yup.boolean().oneOf([true, false], STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            agreementTrademark: Yup.boolean().oneOf([true], STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            agreementFinancialAdvisor: Yup.boolean().oneOf([true], STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            agreementCorrectInformation: Yup.boolean().oneOf([true], STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
        }),
        onSubmit: (values, formikHelpers) => {
            console.log("Moral AgreementForm.onSubmit", values);
            onSubmit(values, formikHelpers);
        },
    });

    const isFormError = useFormError(formik);
    useEffect(() => {
        if (isFormError) {
            showErrorAlert();
        }
    }, [isFormError]);

    const onAgreementChange = (value: string, name: string) => {
        formik.setFieldValue(name, +value);
    };

    return (
        <form className="form-certificate-agreement" onSubmit={formik.handleSubmit}>
            <Stack gap={5}>
                <p className="mark-important">請再次確認以下聲明書內容</p>
                <AgreementStatements formik={formik} onAgreementChange={onAgreementChange} userProfile={userProfile} />
                <AgreementTrademark formik={formik} />
                <div className="d-grid">
                    <button type="submit" name="save" className="btn btn-primary">
                        下一步
                    </button>
                </div>
            </Stack>
        </form>
    );
};

export default AgreementForm;
