import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import LoginInner from "./LoginInner";

function LoginMain() {
    return (
        <GoogleReCaptchaProvider
            useRecaptchaNet
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}
            scriptProps={{ async: true, defer: true, appendTo: "body" }}
        >
            <LoginInner />
        </GoogleReCaptchaProvider>
    );
}

export default LoginMain;
