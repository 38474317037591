import * as Yup from "yup";
import { STR_INVALID_PATTERN, STR_REQUIRED_FIELD } from "../formik/Strings";
import { TYPE_OTHER_COMPANY } from "../company/CompanyType";
import twzipcode from "twzipcode-data";
import _ from "lodash";
import { findZipcodeByArea } from "../address/Zipcode";

const data = twzipcode();
const zipcodes = data.zipcodes;

const getCountyZipCodes = (countyName: string) => {
    return _.map(zipcodes, ({ county, zipcode }) => {
        if (countyName === county) {
            return zipcode;
        }
    });
};

const isZipCodeValidForCounty = (zipcode: number, county: string) => {
    const countyZipcodes = getCountyZipCodes(county);

    return _.includes(countyZipcodes, zipcode);
};

const STR_INVALID_CFP_AFP = "證號格式錯誤";
const isCfpAfp = (text?: string) => {
    if (!text) {
        return false;
    }

    return text.startsWith("CFP") || text.startsWith("AFP");
};

export const schemaDesc = {
    chName: Yup.string()
        .matches(/^[\u4e00-\u9fa5]{2,7}$/, STR_INVALID_PATTERN)
        .required(STR_REQUIRED_FIELD)
        .typeError(STR_REQUIRED_FIELD),
    SurName: Yup.string()
        .matches(/^[a-zA-Z]{2,8}$/, STR_INVALID_PATTERN)
        .required(STR_REQUIRED_FIELD)
        .typeError(STR_REQUIRED_FIELD),
    GivenName: Yup.string()
        .matches(/^[a-zA-Z-]{2,16}$/, STR_INVALID_PATTERN)
        .required(STR_REQUIRED_FIELD)
        .typeError(STR_REQUIRED_FIELD),
    cfpAfp: Yup.number().oneOf([0, 1]).typeError(STR_REQUIRED_FIELD),
    certNum: Yup.string()
        .when("cfpAfp", {
            is: 1,
            then: (schema) =>
                schema
                    .required(STR_INVALID_CFP_AFP)
                    .min(11, STR_INVALID_CFP_AFP)
                    .test("Check prefix", STR_INVALID_CFP_AFP, isCfpAfp),
        })
        .nullable()
        .typeError(STR_INVALID_CFP_AFP),
    idNum: Yup.string()
        .matches(/^[a-zA-Z]\d{9}$/, STR_INVALID_PATTERN)
        .required(STR_REQUIRED_FIELD)
        .typeError(STR_REQUIRED_FIELD),
    birthday: Yup.date().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    companyType: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    company: Yup.string()
        .when("companyType", {
            is: TYPE_OTHER_COMPANY,
            then: (schema) => schema,
            otherwise: (schema) => schema.required("請輸入現職服務單位"),
        })
        .typeError(STR_REQUIRED_FIELD),
    mainAddress: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    vatTitle: Yup.string().nullable().typeError(STR_REQUIRED_FIELD),
    vatNumber: Yup.string().nullable().typeError(STR_REQUIRED_FIELD),
    eduLevel: Yup.string().required("請輸入最高學歷").typeError(STR_REQUIRED_FIELD),
    graduateYear: Yup.string().required("請輸入學位取得年度").typeError(STR_REQUIRED_FIELD),
    school: Yup.string().required("請輸入畢業學校").typeError(STR_REQUIRED_FIELD),
    eduClass: Yup.string().required("請輸入畢業科系").typeError(STR_REQUIRED_FIELD),
    workPhone: Yup.string().required("請輸入公司電話").typeError(STR_REQUIRED_FIELD),
    homePhone: Yup.string().required("請輸入住家電話").typeError(STR_REQUIRED_FIELD),
    mobile: Yup.string()
        .matches(/[0-9]{10}/, STR_INVALID_PATTERN)
        .required("請輸入行動電話")
        .typeError(STR_REQUIRED_FIELD),
    email: Yup.string().required("請輸入電子郵件信箱").typeError(STR_REQUIRED_FIELD),
    companyZipcode: Yup.string()
        .matches(/[0-9]{3,3}|[0-9]{5,5}|[0-9]{6,6}/, STR_INVALID_PATTERN)
        .required("請選擇區域")
        .typeError(STR_REQUIRED_FIELD),
    companyCounty: Yup.string().required(STR_REQUIRED_FIELD),
    companyArea: Yup.string()
        .required(STR_REQUIRED_FIELD)
        .test("allowed-postcode", function (areaValue?: string) {
            const county = this.parent.companyCounty;
            if (!areaValue) {
                return false;
            }
            const areaZipcode = findZipcodeByArea(areaValue);

            return isZipCodeValidForCounty(+areaZipcode, county);
        })
        .typeError(STR_REQUIRED_FIELD),
    companyAddress: Yup.string().required("請輸入公司地址").typeError(STR_REQUIRED_FIELD),
    homeZipcode: Yup.string()
        .matches(/[0-9]{3,3}|[0-9]{5,5}|[0-9]{6,6}/, STR_INVALID_PATTERN)
        .required("請選擇區域")
        .typeError(STR_REQUIRED_FIELD),
    homeCounty: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    homeArea: Yup.string()
        .required(STR_REQUIRED_FIELD)
        .test("allowed-postcode", function (areaValue?: string) {
            const county = this.parent.homeCounty;
            if (!areaValue) {
                return false;
            }
            const areaZipcode = findZipcodeByArea(areaValue);

            return isZipCodeValidForCounty(+areaZipcode, county);
        })
        .typeError(STR_REQUIRED_FIELD),
    homeAddress: Yup.string().required("請輸入住家地址").typeError(STR_REQUIRED_FIELD),
};
