import React from "react";
import ErrorModal from "../modals/ErrorModal";
import Stack from "react-bootstrap/Stack";

export interface PaymentFailedErrorModalProps {
    show: boolean;
    handleClose: () => void;
}

const PaymentFailedErrorModal = ({ show, handleClose }: PaymentFailedErrorModalProps) => {
    return (
        <ErrorModal show={show} handleClose={handleClose} title="付款失敗，請至【活動報名狀態】重新繳費">
            <Stack gap={4} className="payment-failed-notice mt-4">
                <p>通常付款失敗，最常出現的幾個原因如下：</p>
                <p>1. 逾期失敗，操作時間超過4分半 信用卡繳費之後會有4分半的期限，逾時就會失敗需要再重新操作一次。</p>
                <p>
                    2. 3D交易認證錯誤（消費者端自行操作錯誤）
                    信用卡識別碼或是交易認證碼錯誤導致，請稍待5-10分鐘後再重新操作一次
                </p>
            </Stack>
        </ErrorModal>
    );
};

export default PaymentFailedErrorModal;
