import React, { useEffect, useState } from "react";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import { Col, Row } from "react-bootstrap";
import SearchForm, { FormData } from "../components/search/SearchForm";
import SearchGrid from "../components/search/SearchGrid";
import { RowValues } from "../components/search/SearchTable";
import { SearchResultItem } from "../components/search/searchResultsMock";
import SearchPagination from "../components/search/SearchPagination";
import rfaLogoImg from "../static/search/rfa-logo.png";
import rfaLogoImgMobile from "../static/search/rfa-logo-mobile.png";
import RfaApi from "../RfaApi";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";

const searchResultItemToRowValue = ({
    chName,
    gender,
    county,
    area,
    companyType,
    company,
    workPhone,
    mobile,
    email,
    licenseNumber,
}: SearchResultItem) => {
    const cellData = [chName, gender, county, area, companyType, company, workPhone, mobile, email, licenseNumber];
    return cellData.map((value) => value ?? "-");
};

export interface SearchProps {
    match: any;
}

const Search = ({ match }: SearchProps) => {
    const pageTitle = "查詢RFA理財規劃顧問";
    const searchPageSize = 10;
    const rfaApi = new RfaApi();
    const history = useHistory();
    const location = useLocation();
    const [formData, setFormData] = useState<FormData>();
    const [gridItems, setGridItems] = useState<Array<RowValues>>([]);
    const [isGridVisible, setGridVisible] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        const visible = match.params !== undefined && totalCount > 0;
        setGridVisible(visible);
        const { p } = match.params;
        setCurrentPage(+(p ?? 1));
    }, [match.params, totalCount]);

    useEffect(() => {
        if (!location.search) {
            return;
        }
        const queryParams = new URLSearchParams(location.search);

        setFormData({
            name: queryParams.get("name") ?? "",
            county: queryParams.get("county") ?? "",
            workingArea: queryParams.get("workingArea") ?? "",
            companyType: queryParams.get("companyType") ?? "",
            company: queryParams.get("company") ?? "",
            gender: parseInt(queryParams.get("gender") ?? "-1"),
        });
    }, [location]);

    const renderTitle = () => {
        return (
            <div className="d-flex justify-content-start align-items-center gap-3 gap-lg-4">
                <picture>
                    <source media="(max-width: 991px)" srcSet={rfaLogoImgMobile} />
                    <source media="(min-width: 992px)" srcSet={rfaLogoImg} />
                    <img src={rfaLogoImg} className="search-logo" alt="RFA Logo" />
                </picture>
                <h3 className="search-title">{pageTitle}</h3>
            </div>
        );
    };

    const renderGrid = () => {
        if (!isGridVisible) {
            return <></>;
        }

        return (
            <Row>
                <Col className="d-flex flex-column gap-5">
                    <Row>
                        <SearchGrid items={gridItems} />
                    </Row>
                    <Row>
                        <SearchPagination pageSize={searchPageSize} total={totalCount} active={currentPage} />
                    </Row>
                </Col>
            </Row>
        );
    };
    useEffect(() => {
        if (!formData) {
            return;
        }

        const query = { pageIndex: currentPage - 1, ...formData };
        rfaApi
            .Search(query)
            .then((rdata) => {
                if (rdata) {
                    setTotalCount(+rdata.count);
                    //console.log("rfaApi.Search", rdata);
                    const items = rdata.rows.map(searchResultItemToRowValue);
                    //console.log(items);
                    setGridItems(items);
                } else {
                    throw new Error("Invalid response");
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }, [currentPage, formData]);

    const onSearch = (formData: FormData) => {
        const queryParams = new URLSearchParams();
        _.each(formData, (value, key) => {
            const serializedValue = value.toString();
            queryParams.set(key, serializedValue);
        });
        history.replace({ pathname: location.pathname, search: queryParams.toString() });
    };

    const onReset = () => {
        setGridItems([]);
        setTotalCount(0);
        history.replace({ pathname: location.pathname });
    };

    return (
        <div className="search-page">
            <Row className="justify-content-center">
                <Col sm={12} md={6}>
                    <Meta title={pageTitle} />
                    <FormContainer title={renderTitle()}>
                        <SearchForm formData={formData} onSubmit={onSearch} onReset={onReset} />
                    </FormContainer>
                </Col>
            </Row>
            {renderGrid()}
        </div>
    );
};

export default Search;
