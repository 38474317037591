/* eslint-disable no-unused-vars */
import axios from "axios";
import Actions from "../actions/actions";

const qs = require("qs");
export default class RfaApi {
    public async InSignUpPeriod(examConfig: any): Promise<boolean> {
        if (
            examConfig &&
            examConfig.signup &&
            examConfig.signup.startAt &&
            examConfig.signup.endAt &&
            new Date(`${examConfig.signup.startAt}+08:00`).getTime() - new Date().getTime() < 0 &&
            new Date(`${examConfig.signup.endAt}+08:00`).getTime() - new Date().getTime() > 0
        ) {
            return true;
        }
        return false;
    }
    async InScorecheckPeriod(examConfig: any): Promise<boolean> {
        if (
            examConfig &&
            examConfig.scorecheck &&
            examConfig.scorecheck.startAt &&
            examConfig.scorecheck.endAt &&
            new Date(`${examConfig.scorecheck.startAt}+08:00`).getTime() - new Date().getTime() < 0 &&
            new Date(`${examConfig.scorecheck.endAt}+08:00`).getTime() - new Date().getTime() > 0
        ) {
            return true;
        }
        return false;
    }

    async InNewcertPeriod(examConfig: any): Promise<boolean> {
        if (
            examConfig &&
            examConfig.newcert &&
            examConfig.newcert.startAt &&
            examConfig.newcert.endAt &&
            new Date(`${examConfig.newcert.startAt}+08:00`).getTime() - new Date().getTime() < 0 &&
            new Date(`${examConfig.newcert.endAt}+08:00`).getTime() - new Date().getTime() > 0
        ) {
            return true;
        }
        return false;
    }
    //1 登入
    async UserLogin(login: string, pass: string, token: string): Promise<any> {
        return await axios
            .post(
                `/auth/login`,
                { login, pass, token },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then(async (response) => {
                if (response.data.status === "ok") {
                    this.checkUserLogin();
                }
                return response.data;
            })
            .catch((error) => {
                return { status: error.response.status, text: error.response.text };
            });
    }

    //2 登出
    async UserLogout(): Promise<void> {
        return await axios
            .get(`/auth/logout`, { withCredentials: true })
            .then(async (response) => {
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    //3 更新
    async checkUserLogin(): Promise<void> {
        return await axios
            .get(`/auth/login`, { withCredentials: true })
            .then((response) => {
                Actions.updateUser(response.data);
                return response.data;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    //4 修改密碼
    async changePassword(password: string, newpassword: string): Promise<any> {
        return await axios
            .post(`/auth/password/`, { password, newpassword }, { withCredentials: true })
            .then((response) => {
                console.log(response);
                return response.data;
            })
            .catch(() => {
                return { confirm: false, message: "修改密碼失敗" };
            });
    }

    //5 重設密碼
    async resetPassword(email: string): Promise<any> {
        return await axios
            .post(`/auth/reset/`, { email }, { withCredentials: true })
            .then((response) => {
                //console.log(response.data);
                return response.data;
            })
            .catch((ex) => {
                console.log(ex);
                return { confirm: false, message: "重設密碼發生錯誤" };
            });
    }

    //6 重設url確認
    async resetUrlCheck(code: string): Promise<any> {
        return await axios
            .get(`/auth/reset/${code}`, { withCredentials: true })
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                return { confirm: false, message: "url確認發生錯誤" };
            });
    }

    //7 確認重設密碼
    async resetPasswordConfirm(password: string, code: string): Promise<any> {
        return await axios
            .post(`/auth/reset/${code}`, { password }, { withCredentials: true })
            .then((response) => {
                return response.data;
            })
            .catch(() => {
                return { confirm: false, message: "重設密碼發生錯誤" };
            });
    }

    //8 公司清單
    async GetCompanyList(): Promise<any> {
        return await axios
            .get(`/api/company`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //9 公司清單(分類)
    async GetCompanyByType(company_type: string): Promise<any> {
        return await axios
            .get(`/api/company/${company_type}`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    async GetDashboard(): Promise<any> {
        return await axios
            .get(`/api/dashboard`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //10 個人資料
    async GetProfile(): Promise<any> {
        return await axios
            .get(`/api/profile`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //11 更新個人資料
    async UpdateProfile(profile: any): Promise<any> {
        return await axios
            .post(`/api/profile`, profile, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    async GetPicture(): Promise<any> {
        return await axios
            .get(`/api/picture`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    async SavePicture(file: any): Promise<any> {
        const uploadForm = new FormData();
        if (file) {
            console.log("fileupload:", file);
            uploadForm.append("file", file);
            //delete form.file;
        }

        //uploadForm.append("json", JSON.stringify(form));

        console.log("uploadForm:", uploadForm);
        return await axios
            .post(`/api/picture`, uploadForm, {
                headers: { "Content-Type": "multipart/form-data" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //12 工作經歷
    async GetWorkExperience(): Promise<any> {
        return await axios
            .get(`/api/jobreference`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //13 更新工作經歷
    async UpdateWorkExperience(form: any): Promise<any> {
        return await axios
            .post(`/api/jobreference`, form, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //14 個人資料授權
    async GetAgreement(): Promise<any> {
        return await axios
            .get(`/api/agreement`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //15 更新個人授權
    async UpdateAgreement(form: any): Promise<any> {
        return await axios
            .post(`/api/agreement`, form, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //19 上傳報名表, (取得交易代碼)
    //generate ecpay checkout form (using x-www-form-urlencoded)
    async RegisterSignup(form: any): Promise<any> {
        return await axios
            .post(`/api/signup`, form, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //20 複查, (取得交易代碼)
    async RegisterScorecheck(options: any): Promise<any> {
        //const params = qs.stringify(options);
        return await axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: options,
            withCredentials: true,
            url: `/api/scorecheck`,
        }).then((response) => {
            return response.data;
        });
    }

    //21 認證/換證 , (取得交易代碼)
    async RegisterCertificate(options: any): Promise<any> {
        let applyURL = "";
        if (options.applicationType === "1") {
            applyURL = `/api/certificate`;
        }
        if (options.applicationType === "2") {
            applyURL = `/api/renew`;
        }
        //console.log(`params: ${params}`);
        return await axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            data: options,
            withCredentials: true,
            url: applyURL,
        }).then((response) => {
            return response.data;
        });
    }
    //24 取消付款
    async CancelPayment(form: any): Promise<any> {
        return await axios
            .put(`/api/payment`, form, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                console.log(response.data);
                return response.data;
            });
    }

    //22 報名付費清單
    async GetSignupPayment(): Promise<any> {
        return await axios
            .get(`/api/paymentlist/signup`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                console.log(response.data);
                return response.data;
            });
    }
    //55 M3認列
    async RegisterM3(): Promise<any> {
        return await axios
            .post(
                `/api/m3`,
                {},
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then((response) => {
                return response.data;
            });
    }
    //23 M3狀態 (付費清單)
    async GetM3Payment(): Promise<any> {
        return await axios
            .get(`/api/paymentlist/m3`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                console.log(response.data);
                return response.data;
            });
    }

    //25 複查付費清單
    async GetScorecheckPayment(): Promise<any> {
        return await axios
            .get(`/api/paymentlist/scorecheck`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //26 認證/換證付費清單
    async GetCertificatePayment(): Promise<any> {
        return await axios
            .get(`/api/paymentlist/newcert,renew`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //27 查詢單次交易結果(明細/繳費單)
    async GetPaymentLog(logid: string): Promise<any> {
        return await axios
            .get(`/api/paymentlog/${logid}`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //28 准考證
    async GetExamTickets(): Promise<any> {
        return await axios
            .get(`/api/admission`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }
    //准考證&應考證明
    async GetExamTicket(examId?: string, withShowUp?: string): Promise<any> {
        return await axios
            .get(`/api/admission/${examId}/${withShowUp}`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }
    //應考證明
    async GetExamAttendances(): Promise<any> {
        return await axios
            .get(`/api/attendance`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //29 測驗成績查詢
    async GetScore(): Promise<any> {
        return await axios
            .get(`/api/score`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //30 測驗成績複查付費清單 & 狀態查詢
    async GetScoreCheckStatus(): Promise<any> {
        return await axios
            .get(`/api/scorecheck`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //31 測驗成績複查結果(單次)
    async GetScoreCheckResult(examId: string): Promise<any> {
        return await axios
            .get(`/api/scorecheckresult?exam=${examId}`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //37 證書預覽(下載)
    async GetCertificateFile(): Promise<any> {
        return await axios
            .get(`/api/certificate/file`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //38 職業道德及執業準則聲明書
    async GetAgreementMoral(): Promise<any> {
        return await axios
            .get(`/api/moral`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //39 更新職業道德及執業準則聲明書
    async SaveAgreementMoral(form: any): Promise<any> {
        return await axios
            .post(`/api/moral`, form, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }
    //40 計算機訂閱紀錄
    async GetSubscribeLogs(): Promise<any> {
        return await axios
            .get(`/api/subscribe`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }
    //41 新增計算機試用
    async tryout(form: any): Promise<any> {
        return await axios
            .post(`/api/tryout`, form, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //43 收據列表
    async GetReceipts(tag: string): Promise<any> {
        return await axios
            .get(`/api/receipts/${tag}`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }
    //46 收據內文
    async GetReceipt(receiptId: string): Promise<any> {
        return await axios
            .get(`/api/receipt/print/${receiptId}`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }
    //47 查詢RFA理財規劃顧問
    async Search(form: any): Promise<any> {
        return await axios
            .post(`/api/search`, form, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }
    //48 取消訂閱
    async Unsubscribe(form: any): Promise<any> {
        return await axios
            .post(`/api/unsubscribe`, form, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //49a 數位持續教育時數列表
    async GetOnlineEventHistory(pageIndex = 0, perPage = 100): Promise<any> {
        return await axios
            .get(`/api/online_training`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
                params: {
                    pageIndex,
                    perPage,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    //50 實體持續教育時數列表
    async GetOfflineEventHistory(pageIndex = 0, perPage = 100): Promise<any> {
        return await axios
            .get(`/api/offline_training`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
                params: {
                    pageIndex,
                    perPage,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    //51 活動列表
    async GetActivities(pageIndex = 0, perPage = 100): Promise<any> {
        return await axios
            .get(`/api/activity`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
                params: {
                    pageIndex,
                    perPage,
                },
            })
            .then((response) => {
                return response.data;
            });
    }

    //52 活動詳細
    async GetActivity(id: number): Promise<any> {
        return await axios
            .get(`/api/activity/${id}`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //53 活動報名
    async RegisterActivity(id: number): Promise<any> {
        return await axios
            .post(
                `/api/activity`,
                { activityId: id },
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            )
            .then((response) => {
                return response.data;
            });
    }

    //54 活動報名結果
    async GetEventRegistrationPayment(): Promise<any> {
        return await axios
            .get(`/api/paymentlist/activity`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }

    //55 換證前確認
    async CertificateRenewconfirm(): Promise<any> {
        return await axios
            .post(`/api/renewconfirm`, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true,
            })
            .then((response) => {
                return response.data;
            });
    }
}
