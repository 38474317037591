var converter = require("number-to-chinese-words");

converter.default.labels = Object.assign({}, converter.default.labels, {
    digits: ["零", "壹", "貳", "參", "肆", "伍", "陸", "柒", "捌", "玖"],
    units: [
        "",
        "拾",
        "佰",
        "仟",
        "萬",
        "拾",
        "佰",
        "仟",
        "億",
        "拾",
        "佰",
        "仟",
        "兆",
        "拾",
        "佰",
        "仟",
        "京",
        "拾",
        "佰",
        "仟",
        "垓",
    ],
});

export const toChineseWords = (number) => converter.toWords(Number(number));
