import React from "react";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";

export interface FormContainerProps {
    title: string | React.ReactNode;
    className?: string;
    children: React.ReactNode;
}

const FormContainer = ({ title, className, children }: FormContainerProps) => {
    const renderTitle = () => {
        if (typeof title === "string") {
            return <h3>{title}</h3>;
        }
        return title;
    };
    return (
        <Card className={className}>
            <Card.Header>{renderTitle()}</Card.Header>
            <Stack className="form-container-body-stack">
                <Card.Body className="dashboard-card-body">{children}</Card.Body>
            </Stack>
        </Card>
    );
};

export default FormContainer;
