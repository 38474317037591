import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import RfaApi from "../RfaApi";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import SuccessModal from "../components/signup/SuccessModal";
import { UserState } from "../components/types";
import { FormData } from "../components/signup/SignupForm";
import Actions from "../actions/actions";
import { useHistory } from "react-router-dom";
import SignupFormConfirm from "../components/signup/SignupFormConfirm";
import ConfirmPaymentModal from "../components/signup/ConfirmPaymentModal";
import PaymentErrorModal from "../components/signup/PaymentErrorModal";
import PaymentFailedErrorModal from "../components/signup/PaymentFailedErrorModal";

interface FormState {
    form: FormData;
}

const SignupConfirm = () => {
    const pageTitle = "測驗報名";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);
    const form = useSelector((state: FormState) => state.form);

    const [isConfirmPaymentVisible, setConfirmPaymentVisible] = useState<boolean>(false);
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isPaymentErrorVisible, setPaymentErrorVisible] = useState<boolean>(false);
    const [isPaymentFailedErrorVisible, setPaymentFailedErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [user, form]);

    const doPrevious = () => {
        Actions.updateForm({ ...form });
        history.push("/profile/signup");
    };

    const doNext = () => {
        console.log("doPayment");
        setConfirmPaymentVisible(true);
    };

    const doPayment = () => {
        rfaApi
            .RegisterSignup({ ...form }) //1.遞交報名表
            .then((result) => {
                //3.進入綠界交易畫面
                window.location.href = `/api/payment/${result}`;
            })
            .catch((err) => {
                //2. 遞交報名表失敗
                setErrorContent(err.response.data);
                console.log("Signup", err.response.data);
                setPaymentErrorVisible(true);
            });
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <SignupFormConfirm formData={form} onPrevious={doPrevious} onNext={doNext} />
                </FormContainer>
                <ConfirmPaymentModal
                    show={isConfirmPaymentVisible}
                    handleConfirm={doPayment}
                    handleClose={() => setConfirmPaymentVisible(false)}
                    userName={user.username}
                    userChName={form.chName}
                ></ConfirmPaymentModal>
                <SuccessModal show={isSuccessVisible} handleClose={() => setSuccessVisible(false)} />
                <PaymentErrorModal
                    show={isPaymentErrorVisible}
                    errorText={errorContent ? errorContent : ""}
                    handleClose={() => setPaymentErrorVisible(false)}
                />
                <PaymentFailedErrorModal
                    show={isPaymentFailedErrorVisible}
                    handleClose={() => setPaymentFailedErrorVisible(false)}
                />
            </Col>
            <div id="rfa_payment" />
            {/*Payment container*/}
        </Row>
    );
};

export default SignupConfirm;
