export const compareParametrizedPath = (href, path) => {
    if (href[0] !== "@") {
        return false;
    }

    const hrefWithoutAt = href.substr(1);

    return path.startsWith(hrefWithoutAt);
};

export const isActiveList = (hrefs, path) => {
    let i = 0,
        href;
    for (i; i < hrefs.length; ++i) {
        href = hrefs[i];
        if (href === path) {
            return true;
        } else if (compareParametrizedPath(href, path)) {
            return true;
        }
    }
    return false;
};

const getChildHrefs = (children) => children.map(({ href }) => href);

export const findActivePath = (menuEntries, path) => {
    let g = 0,
        menuEntry,
        currentGroup;
    for (g; g < menuEntries.length; ++g) {
        menuEntry = menuEntries[g];
        const { id: groupId, children } = menuEntry;
        currentGroup = groupId;

        const childHrefs = getChildHrefs(children);
        if (isActiveList(childHrefs, path)) {
            return {
                group: currentGroup,
                path,
            };
        }
    }

    return {
        group: currentGroup,
        path: null,
    };
};
