import React, { useEffect, useMemo, useState } from "react";
import FirstApplicationInfo from "../components/certificate/FirstApplicationInfo";
import FirstApplicationForm, { FormData } from "../components/certificate/FirstApplicationForm";
import PendingInfo from "../components/PendingInfo";
import RfaApi from "../RfaApi";
import ErrorModal from "../components/form/ErrorModal";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import { useHistory, useParams } from "react-router-dom";

const STATUS_NONE = 0;
const STATUS_PENDING = 1;

// TODO: two pages, first application info with button leading to the form page
const ScoreCheckRequest = () => {
    const pageTitle = "成績複查申請";
    // TODO: set pending from backend API
    const [status, setStatus] = useState(STATUS_NONE);
    const [formStep, setFormStep] = useState(0);
    const rfaApi = useMemo(() => new RfaApi(), []);
    const user = useSelector((state: UserState) => state.user);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    //const { id } = useParams<{ id?: string }>();
    const history = useHistory();

    useEffect(() => {
        //setErrorContent(`111年度第1次測驗放榜日期為111年5月30日 10:00`);
        //setErrorContent(`${user.exam.name}放榜日期為${user.exam.scorecheck_result} 10:00`);
        //setErrorVisible(true);
        const currentExam = user?.exam ?? null;

        rfaApi.InScorecheckPeriod(currentExam).then((allowed) => {
            if (allowed) {
                setStatus(STATUS_NONE);
            } else {
                setErrorContent(`非複查申請期間`);
                setErrorVisible(true);
            }
        });
    }, []);

    const onNextStep = () => {
        const nextStep = formStep + 1;
        setFormStep(nextStep);
    };

    const onSubmit = (values: FormData) => {
        const options = {
            ...values,
        };
        rfaApi
            .RegisterScorecheck(options)
            .then((data) => {
                //3.進入綠界交易畫面
                window.location.href = `/api/payment/${data}`;
            })
            .catch((err) => {
                console.log("RegisterScorecheck err:", err);
                if (err.response) {
                    setErrorContent(err.response.data);
                } else {
                    setErrorContent(err.message);
                }
                setErrorVisible(true);
            });
    };

    const resolveStep = () => {
        switch (formStep) {
            case 1:
                return <FirstApplicationForm onSubmit={onSubmit} />;
            default:
                return <FirstApplicationInfo onNextStep={onNextStep} />;
        }
    };

    const goBack = () => {
        history.goBack();
    };

    const resolveLayout = () => {
        switch (status) {
            case STATUS_PENDING:
                return <PendingInfo doPrevious={goBack} />;
            default:
                return resolveStep();
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    {resolveLayout()}
                </FormContainer>
                <ErrorModal
                    content={errorContent}
                    show={isErrorVisible}
                    handleClose={() => {
                        setErrorVisible(false);
                        history.push("/profile/score-test-status");
                    }}
                />
                <div id="rfa_payment" />
                {/*Payment container*/}
            </Col>
        </Row>
    );
};

export default ScoreCheckRequest;
