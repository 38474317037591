/**
 * 定義有哪些行為，統一觸發的類型
 */
// eslint-disable-next-line no-unused-vars
enum Action {
    // eslint-disable-next-line no-unused-vars
    USER_UPDATE = "USER_UPDATE",
    // eslint-disable-next-line no-unused-vars
    FORM_UPDATE = "FORM_UPDATE",
    // eslint-disable-next-line no-unused-vars
    PAYMET_UPDATE = "PAYMET_UPDATE",
}

export default Action;
