import { useState } from "react";
import RfaApi from "../../RfaApi";

export interface EventRegisterHookConfig {
    onError: (message: string) => void;
    onPayment: (fromHtml: string) => void;
    onSuccess: (result: any) => void;
}

export const RegisterErrorStatus = {
    NO_DATA: "無資料",
    FAILED: "報名失敗",
    PROCEDURE_ERROR: "付款程序註冊錯誤",
    ALREADY_REGISTERED: "報名失敗，已報名此活動",
    REACH_LIMIT: "報名失敗，超過此活動人數限制",
    INVALID_CERT: "活動僅限持有RFA有效證照之持證人，將導回首頁",
    ALREADY_PAID: "請使用狀態查詢了解付款狀況，勿重複申請付款",
};

function useEventRegister({ onError, onPayment, onSuccess }: EventRegisterHookConfig) {
    const rfaApi = new RfaApi();
    const [isProcessing, setIsProcessing] = useState(false);
    const doRegister = (id: number, isFreeEvent: boolean) => {
        setIsProcessing(true);
        rfaApi
            .RegisterActivity(id)
            .then((respData: any) => {
                if (isFreeEvent) {
                    onSuccess(respData);
                } else {
                    onPayment(respData);
                }
            })
            .catch((err: any) => {
                console.error(err);
                if ("response" in err && err.response.data) {
                    const message = err.response.data;
                    onError(message);
                } else {
                    onError(RegisterErrorStatus.FAILED);
                }
            })
            .finally(() => {
                setIsProcessing(false);
            });
    };
    return { isProcessing, doRegister };
}

export default useEventRegister;
