import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RfaApi from "../RfaApi";
import PaymentInfo, { PaymentForm } from "../components/PaymentInfo";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";

export interface PaymentLogProps {
    match: any;
}

function PaymentLog({ match }: PaymentLogProps) {
    const pageTitle = "支付结果";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);
    const [form, setForm] = useState<PaymentForm>();
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [paymentTag, setPaymentTag] = useState<string>();

    const doPrevious = () => {
        switch (paymentTag) {
            case "activity":
                history.push("/profile/event-registration-status");
                break;
            case "signup":
                history.push("/profile/signup-status");
                break;
            case "scorecheck":
                history.push("/profile/score-check");
                break;
            case "newcert":
            case "renew":
                history.push("/profile/certificate-status");
                break;
            default:
                history.push("/profile/dashboard");
        }
    };
    useEffect(() => {
        console.log("user", user);
        console.log(match.params);

        rfaApi
            .GetPaymentLog(match.params.logid)
            .then((result) => {
                console.log("GetPaymentLog:", result);
                setPaymentTag(result.paymentTag);
                setForm((prevState) => {
                    return { ...prevState, ...result };
                });
            })
            .catch((err) => {
                setErrorContent(err.response.data);
                setErrorVisible(true);
                history.push("/profile/dashboard");
            });

        window.scrollTo(0, 0);
    }, [user, rfaApi, match.params, history]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    {form && <PaymentInfo form={form} doPrevious={doPrevious} />}
                </FormContainer>
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
        </Row>
    );
}

export default PaymentLog;
