import user from "./user";
import form from "./form";
import payment from "./payment";
import { combineReducers } from "redux";

export default combineReducers({
    user,
    form,
    payment,
});
