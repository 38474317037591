import React from "react";

const Instruction = () => {
    return (
        <>
            <p>
                通過測驗且完成認證申請，使成為正式退休理財規劃顧問(RFA)；僅通過考試，但尚未提出認證申請者，不得對外宣稱為退休理財規劃顧問(RFA)。
            </p>
            <ol>
                <li>
                    <span className="agreement-bold">初次認證程序</span>
                    <ol>
                        <li>
                            凡通過本會RFA測驗者，申請認證資格，須依本會認證程序簽妥本會規定之紀律道德聲明書，待本會認證審查通過後，由本會核發RFA證書，後續每三年規定做換證申請。
                        </li>
                        <li>
                            本會於每年測驗放榜後，開放受理認證申請，申請人可至本會官網RFA專區提出線上申請，最新認證資訊及詳細時程請留意最新消息。
                        </li>
                    </ol>
                </li>
                <li>
                    <span className="agreement-bold">初次認證申請資格條件</span>
                    <ol>
                        <li>提供個人正確資訊並具備RFA測驗通過有效成績。</li>
                        <li>
                            符合專業道德條件，申請人將被要求揭露是否有過去或現在的訴訟或其他受調查之案件，並須完成RFA職業道德及執業準則聲明書，同意遵守RFA職業道德及執業準則，並同意本會做徵信調查。
                        </li>
                        <li>遵守RFA商標使用授權規範。</li>
                    </ol>
                </li>
                <li>
                    <span className="agreement-bold">初次認證申請費用：3,000元。</span>
                </li>
                <li>
                    <span className="agreement-bold">認證程序結果通知</span>
                    <ol>
                        <li>通過認證程序者：核發RFA證書，證書有效期為3年，需定期換證。</li>
                        <li>持證人可於證書有效期內使用商標。</li>
                        <li>需完成持續進修時數總時數，每年至少10小時，3年累計至少30小時。</li>
                        <li>每年7小時為本會認可相關課程活動，以及3小時為本會開設之持續教育課程。</li>
                    </ol>
                </li>
            </ol>
        </>
    );
};

export default Instruction;
