/**
 * activeHrefs - indicate which menu section should be marked if this is a multi-step page.
 * use @ to indicate path with parameters
 * @param user
 * @returns Array
 */
const entries = (user) => {
    if (user && user.menu) {
        //load menu from server
        return user.menu;
    }
    return [];
};

export default entries;
