import React, { createRef, MouseEventHandler, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Pdf from "react-to-pdf";
import RfaApi from "../RfaApi";
import moment from "moment";
import { toChineseWords } from "../components/payment/Numbers";
import { parseDate } from "../components/date/GregorianDate";
import Receipt from "../components/Receipt";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import { UserState } from "../components/types";

interface ReceiptContent {
    Year: string;
    logDate: string;
    serialnumber: string;
    printCount: number;
    chName: string;
    vatTitle: string;
    vatNumber: string;
    description: string;
    Amount: string;
    version: string; //自2023-9-1起 追加收據圖章多版本共存機制(每次會長改選以手動切換)
}

function getPdfName({ Year, logDate, serialnumber }: ReceiptContent) {
    return `rfa-receipt-${Year}.${logDate}-${serialnumber}.pdf`;
}

export interface ReceiptViewProps {
    match: any;
}

const ReceiptView = ({ match }: ReceiptViewProps) => {
    const history = useHistory();
    const rfaApi = new RfaApi();
    const user = useSelector((state: UserState) => state.user);
    const { id } = match.params;
    const pageTitle = "收據";

    const [log, setLog] = React.useState({});

    const doPrevious = () => {
        history.goBack();
    };

    const ref = createRef<HTMLDivElement>();
    const options = {
        orientation: "landscape",
        unit: "mm",
        format: [297, 210],
    };
    useEffect(() => {
        if (user && user.username) {
            rfaApi
                .GetReceipt(id)
                .then((rdata) => {
                    console.log("GetReceipt", rdata);
                    if (rdata && rdata.content) {
                        const pdate = parseDate(rdata.content.payAt);
                        rdata.content.Year = pdate.getFullYear() - 1911; //ROC year
                        rdata.content.logDate = moment(pdate).format("MM.DD");
                        rdata.content.Amount = toChineseWords(rdata.content.amount);
                        rdata.content.serialnumber = rdata.serialnumber;
                        setLog({
                            chName: rdata.content.chName,
                            vatTitle: rdata.content.vatTitle,
                            vatNumber: rdata.content.vatNumber,
                            printCount: 0,
                            serialnumber: rdata.content.serialnumber,
                            description: rdata.content.description,
                            Amount: rdata.content.Amount,
                            Year: rdata.content.Year,
                            logDate: rdata.content.logDate,
                            version: rdata.content.version, //自2023-9-1起 追加收據圖章多版本共存機制(每次會長改選以手動切換)
                        });
                    } else {
                        throw new Error("Invalid payment response");
                    }
                })
                .catch((err) => {
                    // console.log(err);
                    // Swal.fire({ icon: "error", title: "尚未完成繳費" });
                    // history.push("/profile/certificate-receipt-status");
                });
        }
    }, [user]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <div className="certificate-receipt">
                        <div className="container">
                            <div className="row receipt">
                                <div className="col">
                                    <Receipt log={log as ReceiptContent} ref={ref} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <Col>
                            <button type="button" className="btn btn-outline-primary w-100" onClick={doPrevious}>
                                返回
                            </button>
                        </Col>
                        <Col>
                            <Pdf targetRef={ref} filename={getPdfName(log as ReceiptContent)} options={options}>
                                {({ toPdf }: { toPdf: MouseEventHandler<HTMLButtonElement> }) => (
                                    <button className="btn btn-primary w-100" onClick={toPdf}>
                                        下載
                                    </button>
                                )}
                            </Pdf>
                        </Col>
                    </div>
                </FormContainer>
            </Col>
        </Row>
    );
};

export default ReceiptView;
