import React, { useEffect, useMemo, useState } from "react";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import RfaApi from "../RfaApi";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import SuccessModal from "../components/form/SuccessModal";
import ErrorModal from "../components/form/ErrorModal";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import Image from "react-bootstrap/Image";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import AvatarEditor from "react-avatar-editor";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

const Picture = () => {
    const pageTitle = "照片上傳";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const userInfo = useSelector((state: UserState) => state.user);
    const [version, setVersion] = useState<number>(Date.now()); //防止照片被cache住
    const [uploadedImage, setUploadedImage] = useState<string>("/profile.jpg");
    const [selectedImage, setSelectedImage] = useState<File | string>("/profile.jpg");
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    let editor: AvatarEditor;
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const updateVersion = () => {
        setVersion(Date.now());
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        if (editor) {
            // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
            // drawn on another canvas, or added to the DOM.
            //const canvas = editor.getImage();
            //console.log(canvas);
            // If you want the image resized to the canvas size (also a HTMLCanvasElement)
            //const canvasScaled = editor.getImageScaledToCanvas();
            //console.log(canvasScaled);
            const imgScaled = editor.getImageScaledToCanvas().toDataURL();
            //console.log(imgScaled);
            //let imageURL;
            fetch(imgScaled)
                .then((res) => {
                    const imgRes = res.blob();
                    //console.log(imgRes);
                    return imgRes;
                })
                .then((blob) => {
                    // imageURL = window.URL.createObjectURL(blob);
                    // console.log(imageURL);
                    rfaApi
                        .SavePicture(blob)
                        .then((data) => {
                            setSuccessVisible(true);
                            //console.log(data);
                            setUploadedImage(data);
                            updateVersion();
                        })
                        .catch((err) => {
                            setErrorVisible(true);
                        });
                });
        }
    };

    const imageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        //console.log(e);
        if (e.target.files && e.target.files.length > 0) {
            setSelectedImage(e.target.files[0]);
        }
    };

    const setEditorRef = (ed: AvatarEditor | null) => {
        if (ed) {
            editor = ed;
            const img = editor.getImageScaledToCanvas().toDataURL();
            //console.log(img);
        }
    };

    useEffect(() => {
        if (userInfo && userInfo.username) {
            rfaApi
                .GetPicture()
                .then((data) => {
                    setUploadedImage(data);
                    updateVersion();
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        window.scrollTo(0, 0);
    }, [userInfo, history, rfaApi]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <div>
            <Meta title={pageTitle} />
            <FormContainer title={pageTitle} className="dashboard-card">
                <Form onSubmit={handleSubmit}>
                    <Stack className="form-content">
                        <div className="text-danger fs-5">※「個人照片」僅顯示於「【RFA版】好命退休計算機」頁面。</div>
                        <fieldset>
                            <Row className="form-row">
                                <Col lg={6}>
                                    <Form.Group controlId="picture">
                                        <Form.Label>
                                            <b>現有照片</b>
                                        </Form.Label>
                                        <div className="form-subtitle">
                                            <Image
                                                src={`${uploadedImage}?v=${version}`}
                                                width={170}
                                                height={170}
                                            ></Image>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="form-row">
                                <Col lg={6}>
                                    <Form.Group controlId="picture-edit">
                                        <Form.Label>
                                            <b>編輯圖片</b>
                                        </Form.Label>
                                        <div className="form-subtitle">
                                            <AvatarEditor
                                                ref={(ref) => setEditorRef(ref)}
                                                image={selectedImage}
                                                width={200}
                                                height={200}
                                                border={50}
                                                color={[255, 255, 255, 0.8]} // RGBA
                                                scale={1.2}
                                                rotate={0}
                                            />
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="form-row">
                                <Col lg={6}>
                                    <Form.Group controlId="profileForm.picture">
                                        <Form.Control
                                            accept="image/*"
                                            type="file"
                                            required
                                            placeholder="請選擇檔案"
                                            onChange={imageChange}
                                        />
                                        <Form.Text id="fileTypeHelp" className="text-muted">
                                            {/* <small>檔案類型限制: jpg,png</small> */}
                                        </Form.Text>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </fieldset>
                        <Row>
                            <Col lg={6}>
                                <ButtonGroup className="d-flex justify-content-between btn-group-spacing">
                                    <Nav.Link
                                        className="btn btn-outline-primary"
                                        role="button"
                                        href="/profile/dashboard"
                                    >
                                        返回
                                    </Nav.Link>
                                    <Button type="submit" variant="primary">
                                        上傳
                                    </Button>
                                </ButtonGroup>
                            </Col>
                        </Row>
                    </Stack>
                </Form>
            </FormContainer>
            <SuccessModal
                content="圖片已上傳"
                show={isSuccessVisible}
                handleClose={() => {
                    setSuccessVisible(false);
                    //history.push("/profile/dashboard");
                }}
            />
            <ErrorModal content={"圖片上傳失敗"} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </div>
    );
};

export default Picture;
