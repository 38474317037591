import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import DashboardGrid from "../components/DashboardGrid";
import { Link } from "react-router-dom";
import RfaApi from "../RfaApi";

interface GridRow {
    id: number;
    title: string;
    date: string;
}

const TicketAdmissions = () => {
    const pageTitle = "准考證";
    const user = useSelector((state: UserState) => state.user);
    const [mockData, setMockData] = useState<Array<GridRow>>([]);
    const rfaApi = useMemo(() => new RfaApi(), [mockData]);
    const parseDataRow = (gridRow: GridRow, index: number) => {
        const { id, title, date } = gridRow;
        console.log("gridRow", gridRow);
        return [
            <Link key={id} to={`/profile/ticket-admission/${id}`}>
                {title}
            </Link>,
            date,
        ];
    };
    useEffect(() => {
        //get admission ticket by examid
        if (user && user.username) {
            rfaApi
                .GetExamTickets()
                .then((result) => {
                    console.log("result", result);
                    setMockData(result);
                })
                .catch((err) => {
                    console.log(err);
                    try {
                        // setErrorContent(err.response.data);
                        // setErrorVisible(true);
                    } catch {
                        // console.error("Error response");
                        // setErrorVisible(true);
                    }
                });
            window.scrollTo(0, 0);
        }
    }, [user]);
    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card ticket-admissions">
                    {mockData.length > 0 ? (
                        <DashboardGrid header={["項目", "考試日期"]} content={mockData.map(parseDataRow)} />
                    ) : (
                        <h3 className="no-ticket">無准考證資料</h3>
                    )}
                </FormContainer>
            </Col>
        </Row>
    );
};

export default TicketAdmissions;
