import React from "react";
import { Col, Row } from "react-bootstrap";
import HomeCard, { HomeCardProps } from "../components/home/HomeCard";
import imgBlockA from "../static/home/rfa.png";
import imgBlockB from "../static/home/computer-free.png";
import imgBlockC from "../static/home/computer-subscribe.png";

const cards: Array<HomeCardProps> = [
    {
        title: "【RFA專區】退休規劃理財顧問",
        description: "",
        link: "/rfa",
        linkTitle: "立即前往",
        image: imgBlockA,
    },
    {
        title: "【民眾版】好命退休計算機",
        description: "",
        link: "/RetireComputer-Person/",
        linkTitle: "開始計算",
        image: imgBlockB,
    },
    {
        title: "【RFA版】好命退休計算機(免費30天)",
        description: "",
        link: "/RetireComputer-Pro/",
        linkTitle: "開始計算",
        image: imgBlockC,
    },
];

const renderCard = (props: HomeCardProps) => {
    const { title } = props;

    return (
        <Col key={title} xs={12} md={6} lg={4}>
            <HomeCard {...props} />
        </Col>
    );
};

const Home = () => {
    return <Row className="justify-content-center g-4">{cards.map(renderCard)}</Row>;
};

export default Home;
