import React, { useEffect, useMemo, useState } from "react";
import AgreementForm from "../components/certificate/AgreementForm";
import { useSelector } from "react-redux";
import RfaApi from "../RfaApi";
import { apiError } from "../components/form/Alerts";
import { useHistory } from "react-router-dom";
import { UserState } from "../components/types";
import { FormData } from "../components/certificate/AgreementForm";
import Meta from "../components/Meta";
import { Col, Row } from "react-bootstrap";
import FormContainer from "../components/FormContainer";
import { UserProfile } from "../components/certificate/AgreementCorrectInformation";

export interface ApplicationChoiceProps {
    match: any;
}

const CertificateAgreements = ({ match }: ApplicationChoiceProps) => {
    const pageTitle = "認證申請/換證";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const user = useSelector((state: UserState) => state.user);
    const history = useHistory();
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const postAgreementForm = (formData: FormData) => {
        const applicationType = match.params.type;
        rfaApi
            .SaveAgreementMoral(formData)
            .then(() => {
                return history.push(`/profile/certificate-personal-data-authorization/${applicationType}`);
            })
            .catch(apiError);
    };

    useEffect(() => {
        if (user && user.rfa) {
            rfaApi.GetProfile().then(setUserProfile).catch(apiError);
        }
    }, [user, rfaApi]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    {userProfile && <AgreementForm onSubmit={postAgreementForm} userProfile={userProfile} />}
                </FormContainer>
            </Col>
        </Row>
    );
};

export default CertificateAgreements;
