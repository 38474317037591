import React from "react";
import MaterialIcon from "../material/MaterialIcon";

export interface PlanIconProps {
    isAvailable: boolean;
}

const PlanIcon = ({ isAvailable }: PlanIconProps) => {
    return isAvailable ? (
        <MaterialIcon icon="check" size={40} color="#60C213" />
    ) : (
        <MaterialIcon icon="close" size={40} color="#F33223" />
    );
};

export default PlanIcon;
