import twzipcode from "twzipcode-data";
import _ from "lodash";
const data = twzipcode();
const zipcodes = data.zipcodes;

export const findCityByArea = (areaId: string) => {
    if (!areaId) {
        return "";
    }
    const result = _.find(zipcodes, { id: areaId });
    //console.log("findCityByArea", areaId, result);
    if (!result) {
        return "";
    }

    return result.city;
};
