import React, { useEffect, useState } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { STR_REQUIRED_FIELD } from "../formik/Strings";
import { formikErrorFeedback } from "../formik/Form";

const FEE_VALUE = 200;
const sumTotal = (parts: Array<number>) => {
    return parts.reduce((total, checked) => {
        if (checked) {
            return total + FEE_VALUE;
        }

        return total;
    }, 0);
};

export interface FormData {
    // TODO: rewrite the form using formik
    singleChoiceTest: boolean;
    caseAnalysis: boolean;
}

type FormKeys = keyof FormData;

export interface FirstApplicationFormProps {
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
}

const FirstApplicationForm = ({ onSubmit }: FirstApplicationFormProps) => {
    const [fee, setFee] = useState(0);
    const formik = useFormik({
        initialValues: {
            singleChoiceTest: false,
            caseAnalysis: false,
        },
        validationSchema: Yup.object({
            singleChoiceTest: Yup.boolean().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            caseAnalysis: Yup.boolean().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
        }),
        onSubmit: (values, formikHelpers) => {
            onSubmit(values, formikHelpers);
        },
    });

    useEffect(() => {
        const partValues = [formik.values.singleChoiceTest, formik.values.caseAnalysis].map((value) => (value ? 1 : 0));
        const total = sumTotal(partValues);
        setFee(total);
    }, [formik]);

    const errorFeedback = (field: FormKeys) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field: FormKeys) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

    return (
        <div className="first-application-form">
            <h4 className="title">退休理財規劃顧問成績複查申請表</h4>
            <form className="form-first-application-form" onSubmit={formik.handleSubmit}>
                <fieldset className="form-align-middle mb-4">
                    <div className="col-auto text-end">申請複查科目</div>
                    <div className="col-auto">
                        <div className="row">
                            <div className="form-check form-check-inline">
                                <input
                                    id="singleChoiceTest"
                                    className={`form-check-input ${errorClass("singleChoiceTest")}`}
                                    type="checkbox"
                                    {...formik.getFieldProps("singleChoiceTest")}
                                />
                                {errorFeedback("singleChoiceTest")}
                                <label htmlFor="singleChoiceTest" className="form-check-label">
                                    單選測驗
                                </label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-check form-check-inline">
                                <input
                                    id="caseAnalysis"
                                    className={`form-check-input ${errorClass("caseAnalysis")}`}
                                    type="checkbox"
                                    {...formik.getFieldProps("caseAnalysis")}
                                />
                                {errorFeedback("caseAnalysis")}
                                <label htmlFor="caseAnalysis" className="form-check-label">
                                    個案分析
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="col-auto text-end">費用總計</div>
                    <div className="col-2">
                        <div className="input-group">
                            <input id="fee" className="form-control" type="text" value={fee} readOnly={true} />
                            <div className="input-group-append">
                                <span className="input-group-text">元</span>
                            </div>
                        </div>
                        <div className="row fee-note">{`每一科目複查費用新台幣${FEE_VALUE}元`}</div>
                    </div>
                </fieldset>
                <div className="d-grid my-4">
                    <button type="submit" name="save" className="btn btn-primary">
                        前往付款
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FirstApplicationForm;
