import React from "react";
import { useEffect, useState } from "react";
import { errorClass, formikErrorFeedback } from "./Form";
import { Form } from "react-bootstrap";
import "./date-select.sass";
import { getIn } from "formik";
import { getGregorianYearFromRocYear, getRocYear } from "../date/RocDate";
import { newDate } from "../date/GregorianDate";

const MAX_YEAR = getRocYear(new Date());
const MIN_YEAR = 50;

const calculateDaysCount = (year, month) => {
    const gregorianYear = getGregorianYearFromRocYear(year);
    const lastDate = new Date(gregorianYear, month, 0);

    return lastDate.getDate();
};

const getGregorianDate = (year, month, day) => {
    const gregorianYear = getGregorianYearFromRocYear(year);
    const monthDays = calculateDaysCount(year, month);
    if (day > monthDays) {
        day = monthDays;
    }
    const date = new Date(gregorianYear, month - 1, day);

    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`;
};

const DateSelect = ({ inputPrefix, formik, onChange, readOnly = false }) => {
    const initialDate = getIn(formik.values, inputPrefix);
    const [year, setYear] = useState(110);
    const [month, setMonth] = useState(12);
    const [day, setDay] = useState(31);

    const [monthDaysCount, setMonthDaysCount] = useState(31);

    useEffect(() => {
        const monthDays = calculateDaysCount(year, month);
        setMonthDaysCount(monthDays);
    }, [year, month]);

    useEffect(() => {
        const gregorianDate = getGregorianDate(year, month, day);
        formik.touched[inputPrefix] = true;
        onChange(gregorianDate);
    }, [year, month, day]);

    useEffect(() => {
        if (!initialDate) {
            return;
        }

        const date = newDate(initialDate);
        setYear(getRocYear(date));
        setMonth(date.getMonth() + 1);
        setDay(date.getDate());
    }, [initialDate]);

    const sequenceGenerator = function* (limit, min = 1) {
        let sequence = limit;
        while (sequence >= min) {
            yield sequence--;
        }

        return null;
    };
    const generateOptions = (iterator) => {
        const options = [];
        let value = iterator.next().value;
        while (value !== null) {
            options.push(
                <option key={value} value={value}>
                    {value}
                </option>
            );
            value = iterator.next().value;
        }

        return options;
    };
    const errorFeedback = (field) => {
        return formikErrorFeedback(formik, field);
    };
    return (
        <div className={`date-select ${errorClass(formik, inputPrefix)}`}>
            <div className="date-select-entry">
                <div className="input-group mb-3">
                    <Form.Select
                        id={`${inputPrefix}_year`}
                        className="custom-select year-select"
                        name={`${inputPrefix}_year`}
                        value={year}
                        readOnly={readOnly}
                        onChange={(e) => setYear(e.target.value)}
                    >
                        {generateOptions(sequenceGenerator(MAX_YEAR, MIN_YEAR))}
                    </Form.Select>
                    <div className="input-group-append">
                        <label className="input-group-text" htmlFor={`${inputPrefix}_year`}>
                            年
                        </label>
                    </div>
                </div>
            </div>
            <div className="date-select-entry">
                <div className="input-group mb-3">
                    <Form.Select
                        id={`${inputPrefix}_month`}
                        className="custom-select month-select"
                        name={`${inputPrefix}_month`}
                        value={month}
                        readOnly={readOnly}
                        onChange={(e) => setMonth(e.target.value)}
                    >
                        {generateOptions(sequenceGenerator(12))}
                    </Form.Select>
                    <div className="input-group-append">
                        <label className="input-group-text" htmlFor={`${inputPrefix}_month`}>
                            月
                        </label>
                    </div>
                </div>
            </div>
            <div className="date-select-entry">
                <div className="input-group mb-3">
                    <Form.Select
                        id={`${inputPrefix}_day`}
                        className="custom-select day-select"
                        name={`${inputPrefix}_day`}
                        value={day}
                        readOnly={readOnly}
                        onChange={(e) => setDay(e.target.value)}
                    >
                        {generateOptions(sequenceGenerator(monthDaysCount))}
                    </Form.Select>
                    <div className="input-group-append">
                        <label className="input-group-text" htmlFor={`${inputPrefix}_day`}>
                            日
                        </label>
                    </div>
                </div>
            </div>
            {errorFeedback(inputPrefix)}
        </div>
    );
};

export default DateSelect;
