import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

interface ICertificateButtonsProps {
    disabled: boolean;
}

const CertificateButtons = ({ disabled }: ICertificateButtonsProps) => {
    return (
        <Row className="gx-4">
            <Col>
                {disabled && (
                    <Link className="btn btn-outline-primary float-end" role="button" to="/profile/logo-download">
                        商標下載
                    </Link>
                )}
            </Col>
            <Col>
                <Link className="btn btn-primary" role="button" to="/profile/certificate-download">
                    證書下載
                </Link>
            </Col>
        </Row>
    );
};

export default CertificateButtons;
