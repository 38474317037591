/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Meta from "../components/Meta";
import SubscriptionTable from "../components/subscription/SubscriptionTable";
import SubscriptionTableMobile from "../components/subscription/SubscriptionTableMobile";
import { PlanDescriptionProps } from "../components/subscription/PlanDescriptionProps";
import { PlanProps } from "../components/subscription/Plan";
import FormContainer from "../components/FormContainer";
import ConfirmationForm, { FormData as SubscriptionFormData } from "../components/subscription/ConfirmationForm";
import SubscriptionSuccess from "../components/subscription/SubscriptionSuccess";
import SubscriptionFailure from "../components/subscription/SubscriptionFailure";
import SubscriptionRequired from "../components/subscription/SubscriptionRequired";
import TryOutRequired from "../components/subscription/TryOutRequired";
import RfaApi from "../RfaApi";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const plans: Array<PlanProps> = [
    {
        title: "一年期",
        price: 1200,
        link: "/subscription/case/1",
    },
    {
        title: "二年期",
        price: 2200,
        discountNote: "（可省下 200元）",
        link: "/subscription/case/2",
    },
    {
        title: "三年期",
        price: 3000,
        discountNote: "（可省下 600元）",
        link: "/subscription/case/3",
    },
];

const planDescriptions: Array<PlanDescriptionProps> = [
    {
        title: "退休金需求",
        description: (
            <>
                依：退休年齡、預計平均餘命、所得替代率、通貨膨脹率、退休後投資報酬率，
                <span className="mark-info">計算退休金總需求</span>
            </>
        ),
        isFree: true,
    },
    {
        title: "第一層社會保險退休金",
        description: (
            <>
                依：社會保險年資、最後（平均）投保金額（俸點）、退休年齡，
                <span className="mark-info">計算社會保險退休金</span>
            </>
        ),
        isFree: true,
    },
    {
        title: "第二層職業退休金",
        description: (
            <>
                依：工作年資、最後（平均）薪資（俸點）、退休年齡，<span className="mark-info">計算職業退休金</span>
            </>
        ),
        isFree: true,
    },
    {
        title: "退休金缺口",
        description: (
            <>
                依：退休金需求、社會保險退休金、職業退休金，<span className="mark-info">計算退休金缺口</span>
            </>
        ),
        isFree: true,
    },
    {
        title: "退休目標報酬率",
        description: (
            <>
                依：退休金缺口、距離退休年期、每月可投資金額，<span className="mark-info">計算退休目標報酬率</span>
            </>
        ),
        isFree: true,
    },
    {
        title: "第三層自行準備退休金",
        description: (
            <>
                可輸入自行準備退休金各項參數，<span className="mark-info">提高計算準確度</span>
            </>
        ),
        isFree: false,
    },
    {
        title: "退休金各項參數調整",
        description: (
            <>
                可<span className="mark-info">調整退休金各項參數</span>
                ，符合個人不同退休需求。如：旅遊費用、長照費用、通貨膨脹率、退休後投資報酬率、預計平均餘命
            </>
        ),
        isFree: false,
    },
    {
        title: "專屬個人化介面",
        description: <>會自動帶入登錄RFA本人的個人資訊，如：相片、任職公司、職稱…</>,
        isFree: false,
    },
];

const Subscription = () => {
    const pageTitle = "好命退休計算機RFA版";
    const user = useSelector((state: { user: any }) => state.user);
    const rfaApi = new RfaApi();
    const history = useHistory();
    const [idNumber, setIdNumber] = useState("");

    //TODO: pass form data to the ConfirmationForm

    const { action } = useParams<{ action?: string }>();
    const { code } = useParams<{ code?: string }>();
    const [selectedCase, setSelectedCase] = useState(0);
    useEffect(() => {
        switch (code) {
            case "1":
            case "2":
            case "3":
                setSelectedCase(Number.parseInt(code) - 1);
                break;
            default:
                setSelectedCase(0);
                break;
        }

        if (user && user.username) {
            //有證照或訂閱超過期限的User才能訂閱
            if (user.certificate) {
                rfaApi.GetProfile().then((data) => {
                    setIdNumber(data.idNum);
                });
                window.scrollTo(0, 0);
            } else {
                Swal.fire({ icon: "warning", title: "本訂閱服務對象僅限持有RFA有效證照之持證人" }).then(() => {
                    window.location.href = "/";
                    return;
                });
            }
        }
    }, [user, history, rfaApi]);

    const goPayment = () => {
        window.location.href = "/api/subscribe/" + (selectedCase + 2);
    };
    const goTryout = () => {
        rfaApi
            .tryout({})
            .then((res: any) => {
                window.location.href = "/RetireComputer-Pro/startpage.php";
            })
            .catch((err: { response: { data: any } }) => {
                console.log(err);
                Swal.fire({ icon: "error", title: err.response.data });
            });
    };
    return (
        <>
            <Meta title={pageTitle} />
            {action === "case" && !code ? (
                <>
                    <SubscriptionTable
                        title={pageTitle}
                        visibleBreakpoint={"lg"}
                        plans={plans}
                        planDescriptions={planDescriptions}
                    />
                    <SubscriptionTableMobile
                        title={pageTitle}
                        hiddenBreakpoint={"lg"}
                        plans={plans}
                        planDescriptions={planDescriptions}
                    />
                </>
            ) : null}
            {action === "case" && (code === "1" || code === "2" || code === "3") ? (
                <>
                    <FormContainer title="好命退休計算機RFA版" className="shadow-sm">
                        <ConfirmationForm
                            formData={{
                                subscriberName: user.name,
                                idNumber: idNumber,
                                certificateNumber: user.username,
                                selectedId: selectedCase + 1,
                                subscriptionId: `${plans[selectedCase].title}/${plans[selectedCase].price}元`,
                                terms: false,
                            }}
                            onSubmit={goPayment}
                        />
                    </FormContainer>
                </>
            ) : null}
            {action === "ok" ? <SubscriptionSuccess /> : null}
            {action === "error" ? <SubscriptionFailure /> : null}
            {user.tryout && !action && !code ? <SubscriptionRequired /> : null}
            {!user.tryout && !action && !code ? (
                <FormContainer title="好命退休計算機RFA版試用30天" className="shadow-sm">
                    <TryOutRequired
                        formData={{
                            subscriberName: user.name,
                            idNumber: idNumber,
                            certificateNumber: user.username,
                            terms: false,
                        }}
                        onSubmit={goTryout}
                    />
                </FormContainer>
            ) : null}
        </>
    );
};

export default Subscription;
