import React, { useEffect, useMemo, useState } from "react";
import { Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router-dom";
import RfaApi from "./RfaApi";
// Layout
import Layout from "./layout/Layout";
import ProfileLayout from "./layout/ProfileLayout";

// pages
import CertificateApplication from "./pages/CertificateApplication";
import CertificateDownload from "./pages/CertificateDownload";

import CertificateAgreements from "./pages/CertificateAgreements";
import CertificateAgreementsMoral from "./pages/CertificateAgreementsMoral";
import CertificatePersonalDataAuthorization from "./pages/CertificatePersonalDataAuthorization";
import CertificateStatus from "./pages/CertificateStatus";

import CertificateResult from "./pages/CertificateResult";
import Dashboard from "./pages/Dashboard";
import Home from "./pages/Home";
import Login from "./pages/LoginMain";
import LoginChange from "./pages/LoginChange";
import LogoDownload from "./pages/LogoDownload";
import LoginReset from "./pages/LoginReset";
import LoginPassword from "./pages/LoginPassword";
import NotFound from "./pages/NotFound";
import LearningLogin from "./pages/LearningLogin";
import PduApplication from "./pages/PduApplication";
import PduStatus from "./pages/PduStatus";
import PduRevise from "./pages/PduRevise";
import PduConfirm from "./pages/PduConfirm";
import PersonalDataAuthorization from "./pages/PersonalDataAuthorization";
import PaymentLog from "./pages/PaymentLog";
import Profile from "./pages/Profile";
import Picture from "./pages/Picture";
import Rfa from "./pages/Rfa";
import RcLogin from "./pages/RcLogin";

import ReceiptsCertificate from "./pages/ReceiptsCertificate";
import ReceiptsSubscription from "./pages/ReceiptsSubscription";
import ReceiptsSignup from "./pages/ReceiptsSignup";
import ReceiptsScorecheck from "./pages/ReceiptsScorecheck";
import ReceiptsEvents from "./pages/ReceiptsEvents";

import ReceiptView from "./pages/ReceiptView";

import ScoreCheck from "./pages/ScoreCheck";
import ScoreCheckRequest from "./pages/ScoreCheckRequest";
import ScoreStatus from "./pages/ScoreStatus";
import ScoreResult from "./pages/ScoreResult";
import ScoreReceipt from "./pages/ScoreReceipt";
import ScoreTestStatus from "./pages/ScoreTestStatus";
import Search from "./pages/Search";
import Signup from "./pages/Signup";
import SignupConfirm from "./pages/SignupConfirm";
import SignupHistory from "./pages/SignupHistory";
import SignupStatus from "./pages/SignupStatus";
import StatusSubscription from "./pages/StatusSubscription";
import Subscription from "./pages/Subscription";
import TermsOfService from "./pages/TermsOfService";
import TicketAdmission from "./pages/TicketAdmission";
import TicketAdmissions from "./pages/TicketAdmissions";
import TicketAttendance from "./pages/TicketAttendance";
import TicketAttendances from "./pages/TicketAttendances";
import WorkExperience from "./pages/WorkExperience";
import Events from "./pages/Events";
import EventRegistrationConfirm from "./pages/EventRegistrationConfirm";
import EventRegistrationStatus from "./pages/EventRegistrationStatus";
import EventHistory from "./pages/EventHistory";
import RecognitionM3Status from "./pages/RecognitionM3Status";
import RecognitionM3Confirm from "./pages/RecognitionM3Confirm";
import EventView from "./pages/EventView";

const isLoggedIn = (user) => user.rfa === true;
const ProfileLinks = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <ProfileLayout>
                <Route exact path={path} component={Profile} />
                <Route exact path={`${path}/dashboard`} component={Dashboard} />
                <Route exact path={`${path}/password`} component={LoginChange} />
                <Route exact path={`${path}/picture`} component={Picture} />
                <Route exact path={`${path}/signup`} component={Signup} />
                <Route exact path={`${path}/signupconfirm`} component={SignupConfirm} />
                <Route exact path={`${path}/signuphistory`} component={SignupHistory} />
                <Route exact path={`${path}/signup-status`} component={SignupStatus} />

                <Route exact path={`${path}/paymentlog/:logid`} component={PaymentLog} />

                <Route exact path={`${path}/work-experience`} component={WorkExperience} />
                <Route exact path={`${path}/personal-data-authorization`} component={PersonalDataAuthorization} />
                <Route exact path={`${path}/ticket-admission`} component={TicketAdmissions} />
                <Route exact path={`${path}/ticket-admission/:id`} component={TicketAdmission} />
                <Route exact path={`${path}/ticket-attendance`} component={TicketAttendances} />
                <Route exact path={`${path}/ticket-attendance/:id`} component={TicketAttendance} />

                <Route exact path={`${path}/score-test-status`} component={ScoreTestStatus} />
                <Route exact path={`${path}/score-check`} component={ScoreCheck} />
                <Route exact path={`${path}/score-check-request`} component={ScoreCheckRequest} />
                <Route exact path={`${path}/score-result`} component={ScoreResult} />
                <Route exact path={`${path}/score-receipt`} component={ScoreReceipt} />
                <Route exact path={`${path}/score-status/:id`} component={ScoreStatus} />

                <Route exact path={`${path}/certificate-application`} component={CertificateApplication} />
                <Route exact path={`${path}/certificate-agreements/:type`} component={CertificateAgreements} />
                <Route exact path={`${path}/certificate-moral`} component={CertificateAgreementsMoral} />
                <Route
                    exact
                    path={`${path}/certificate-personal-data-authorization/:type`}
                    component={CertificatePersonalDataAuthorization}
                />
                <Route exact path={`${path}/certificate-status`} component={CertificateStatus} />
                <Route exact path={`${path}/certificate-result`} component={CertificateResult} />

                <Route exact path={`${path}/certificate-download`} component={CertificateDownload} />
                <Route exact path={`${path}/logo-download`} component={LogoDownload} />
                <Route exact path={`${path}/learning/login`} component={LearningLogin} />
                <Route exact path={`${path}/pdu-application`} component={PduApplication} />
                <Route exact path={`${path}/pdu-confirm`} component={PduConfirm} />
                <Route exact path={`${path}/pdu-revise/:id`} component={PduRevise} />
                <Route exact path={`${path}/pdu-status`} component={PduStatus} />
                {/* subscription */}
                <Route path={`${path}/status/subscription`} component={StatusSubscription} />
                {/* receipts */}
                <Route path={`${path}/receipts/subscription`} component={ReceiptsSubscription} />
                <Route path={`${path}/receipts/signup`} component={ReceiptsSignup} />
                <Route path={`${path}/receipts/scorecheck`} component={ReceiptsScorecheck} />
                <Route path={`${path}/receipts/certificate`} component={ReceiptsCertificate} />
                <Route path={`${path}/receipts/events`} component={ReceiptsEvents} />
                <Route path={`${path}/receipt/:id`} component={ReceiptView} />
                {/* events or activity */}
                <Route path={`${path}/events`} component={Events} />
                <Route path={`${path}/event/:id`} component={EventView} />
                <Route path={`${path}/event-registration/:id`} component={EventRegistrationConfirm} />
                <Route path={`${path}/event-registration-status`} component={EventRegistrationStatus} />
                <Route path={`${path}/event-history`} component={EventHistory} />

                {/* m3 */}
                <Route path={`${path}/recognition-m3`} component={RecognitionM3Confirm} />
                <Route path={`${path}/recognition-m3-status`} component={RecognitionM3Status} />
            </ProfileLayout>
        </Switch>
    );
};

const App = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const [loading, setLoading] = useState(true);

    const user = useSelector((state) => state.user);

    useEffect(() => {
        rfaApi.checkUserLogin().then(() => {
            setLoading(false);
        });
    }, [rfaApi]);

    if (loading) {
        return <Layout />;
    }

    return (
        <Layout>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/rfa" component={Rfa} />
                <Route path="/terms-of-service" component={TermsOfService} />

                <Route exact path="/search" component={Search} />
                <Route exact path="/search/:p" component={Search} />
                <Route exact path="/search/:p/:q" component={Search} />

                <Route exact path="/rclogin" component={() => (isLoggedIn(user) ? <RcLogin /> : <Login />)} />
                <Route exact path="/login" component={() => (isLoggedIn(user) ? <></> : <Login />)} />
                <Route exact path="/reset" component={LoginReset} />
                <Route exact path="/reset/:code" component={LoginPassword} />

                <Route path="/profile">{isLoggedIn(user) ? <ProfileLinks user={user} /> : <Login />}</Route>
                <Route exact path="/subscription/:action/:code" component={Subscription}>
                    {isLoggedIn(user) ? <Subscription /> : <Login />}
                </Route>
                <Route exact path="/subscription/:action" component={Subscription}>
                    {isLoggedIn(user) ? <Subscription /> : <Login />}
                </Route>
                <Route path="/subscription" component={Subscription}>
                    {isLoggedIn(user) ? <Subscription /> : <Login />}
                </Route>

                <Route path="*" component={NotFound} />
            </Switch>
        </Layout>
    );
};

export default App;
