/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Meta from "../components/Meta";

const RcLogin = () => {
    // page content
    const pageTitle = "退休計算機登入";
    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (user && user.username) {
            if (user.rc === true) {
                //1.已登入且可存取計算機-->直接進入
                window.location.href = "/RetireComputer-Pro/startpage.php";
            } else {
                //2.已登入但無法存取計算機-->導入訂閱頁面
                window.location.href = "/subscription";
            }
        }
    }, [user]);
    return (
        <>
            <Meta title={pageTitle} />
        </>
    );
};

export default RcLogin;
