import React from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import MenuRfa from "../components/MenuRfa";

const ProfileLayout = ({ children }) => {
    const user = useSelector((state) => state.user);

    return (
        <Row className="gy-4 gx-4">
            <Col lg={3}>
                <MenuRfa user={user} />
            </Col>
            <Col sm={12} lg={9}>
                {children}
            </Col>
        </Row>
    );
};

export default ProfileLayout;
