import React, { useState } from "react";
import CertificateDetails, { CertificateProps } from "./CertificateDetails";
import CertificateButtons from "./CertificateButtons";
import { Card, Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export interface CertificatesProps {
    logoUrl: string;
    certificate: CertificateProps;
    previousCertificatesNumbers: Array<string>;
    disabled: boolean;
}

interface LogoSectionProps {
    logoUrl: string;
    hasCertificate: boolean;
    certificate: CertificateProps;
    disabled: boolean;
}

dayjs.extend(utc);
dayjs.extend(timezone);

const LogoSection = ({ disabled, logoUrl, hasCertificate, certificate }: LogoSectionProps) => {
    const hasLogo = () => logoUrl && logoUrl.length;

    // user.certificate.extendExpireDate && user.certificate.extendExpireDate >= formattedDate
    // const today = dayjs().tz("Asia/Taipei");
    // const formattedDate = today.format("YYYY-MM-DD");
    // const [disabled, setDisabled] = useState(false);

    // if (certificate.expireDate > formattedDate) {
    //     setDisabled(true);
    // }

    return (
        <>
            <Row className="justify-content-center pb-5">
                <Col>{hasLogo() ? <img className="logo-image" src={logoUrl} alt="rfa-logo" /> : <></>}</Col>
            </Row>
            <Row className="justify-content-center pt-5">
                <Col>{hasCertificate ? <CertificateButtons disabled={disabled} /> : <></>}</Col>
            </Row>
        </>
    );
};

const Certificates = ({ disabled, logoUrl, certificate, previousCertificatesNumbers }: CertificatesProps) => {
    return (
        <Card className="dashboard-card mx-2">
            <Card.Header>
                <h4>持證資格/持續教育時數</h4>
            </Card.Header>
            <Card.Body>
                <Row className="dashboard-certificate-logo align-items-center gy-5">
                    <Col md="auto" lg={4} className="dashboard-logo">
                        <LogoSection
                            logoUrl={logoUrl}
                            hasCertificate={!!certificate}
                            certificate={certificate}
                            disabled={disabled}
                        />
                    </Col>
                    <Col lg={6} className="offset-lg-1">
                        {certificate ? (
                            <CertificateDetails
                                certificate={certificate}
                                isProgressVisible={previousCertificatesNumbers.length > 0}
                            />
                        ) : (
                            <></>
                        )}
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default Certificates;
