import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import DashboardGrid from "../components/DashboardGrid";
import { Link } from "react-router-dom";
import RfaApi from "../RfaApi";

import Swal from "sweetalert2";

enum PaymentStepEnum {
    FAILED = "-1",
    PENDING = "0",
    PAID = "1",
}

const PaymentResult: { [key: string]: string } = {
    "0": "-",
    "1": "已付款",
    "2": "待付款",
    "10100073": "待繳費",
    "10100058": "付款失敗",
    "10100248": "拒絕交易",
    "10100249": "交易失敗",
    "10100251": "卡片過期",
    "10100282": "3D授權未完成",
};

//mapping to api response fields
interface GridRow {
    id: number;
    title: string;
    payAt: string;
    expireAt: string;
    amount: string;
    status: string;
    transactionId: string;
    unsubscribed: number;
    payStep: PaymentStepEnum;
}

const SubscriptionStatus = ({ payStep }: GridRow) => {
    if (payStep === PaymentStepEnum.PENDING) {
        return <>未完成</>;
    }
    if (payStep === PaymentStepEnum.FAILED) {
        return <>失敗</>;
    }
    if (payStep === PaymentStepEnum.PAID) {
        return <>已完成</>;
    }
    return <></>;
};
const CloumnAmount = ({ amount }: GridRow) => {
    return <>NT$ {amount}</>;
};
const PaymentStatus = ({ status }: GridRow) => {
    return <>{PaymentResult[status]}</>;
};

const PaymentOptions = ({ id, payStep, transactionId, expireAt, unsubscribed }: GridRow) => {
    if (payStep === PaymentStepEnum.PENDING) {
        return (
            <Link className="btn btn-primary" to={`/payment/log/${id}`}>
                重新繳費
            </Link>
        );
    }
    if (payStep === PaymentStepEnum.FAILED) {
        return (
            <Link className="btn btn-outline-primary" to={`/subscription/case`}>
                重新訂閱
            </Link>
        );
    }
    if (payStep === PaymentStepEnum.PAID) {
        if (transactionId === "tryout") {
            return (
                <Link className="btn btn-outline-primary" to={`/subscription/case`}>
                    付費訂閱
                </Link>
            );
        } else {
            if (unsubscribed === 0) {
                return (
                    <Link
                        className="btn btn-outline-primary"
                        onClick={() => {
                            const unsubscribeApi = new RfaApi();
                            unsubscribeApi
                                .Unsubscribe({ user_paymentId: id, transactionId: transactionId })
                                .then((res_data) => {
                                    //console.log("Unsubscribe", res_data);
                                    if (res_data) {
                                        //console.log(`DoUnsubscribe: ${transactionId}`);
                                        Swal.fire({ icon: "error", title: res_data }).then(() => {
                                            window.location.reload();
                                        });
                                    } else {
                                        Swal.fire({ icon: "error", title: "取消訂閱失敗" });
                                    }
                                })
                                .catch((err) => {
                                    //console.log(err);
                                    Swal.fire({ icon: "error", title: err.response.data });
                                });
                        }}
                        to={`/profile/status/subscription`}
                    >
                        取消訂閱
                    </Link>
                );
            } else {
                return (
                    <Link className="btn btn-outline-primary" to={`/subscription/case`}>
                        重新訂閱
                    </Link>
                );
            }
        }
    }
    return <>-</>;
};

const StatusSubscription = () => {
    const pageTitle = "好命退休計算機RFA版訂閱狀態";
    //const history = useHistory();
    const rfaApi = new RfaApi();
    const user = useSelector((state: UserState) => state.user);
    const [subscribeLogs, setSubscribeLogs] = useState<Array<GridRow>>([]);
    const parseDataRow = (gridRow: GridRow, index: number) => {
        const { id, title, payAt } = gridRow;
        if (index === 0) {
            return [
                title, //項目
                payAt, //申請日期
                <CloumnAmount key={id} {...gridRow} />, //費用
                <SubscriptionStatus key={id} {...gridRow} />, //申請狀態
                <PaymentStatus key={id} {...gridRow} />, //付款狀態
                <PaymentOptions key={id} {...gridRow} />, //付款操作
            ];
        } else {
            return [
                title, //項目
                payAt, //申請日期
                <CloumnAmount key={id} {...gridRow} />, //費用
                <SubscriptionStatus key={id} {...gridRow} />, //申請狀態
                <PaymentStatus key={id} {...gridRow} />, //付款狀態
                <></>, //付款操作
            ];
        }
    };
    useEffect(() => {
        if (user && user.username) {
            rfaApi
                .GetSubscribeLogs()
                .then((res_data) => {
                    //console.log("GetSubscribeLogs", res_data);
                    if (res_data) {
                        setSubscribeLogs(res_data);
                    } else {
                        throw new Error("Invalid payment response");
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [user]);
    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <DashboardGrid
                        header={["項目", "申請日期", "費用", "申請狀態", "付款狀態", "付款操作"]}
                        content={subscribeLogs.map(parseDataRow)}
                    />
                </FormContainer>
            </Col>
        </Row>
    );
};

export default StatusSubscription;
