import React from "react";
import { Stack } from "react-bootstrap";

export interface FirstApplicationInfoProps {
    onNextStep: () => void;
}

const FirstApplicationInfo = ({ onNextStep }: FirstApplicationInfoProps) => {
    return (
        <Stack gap={4}>
            <h4 className="title">退休理財規劃顧問成績複查須知</h4>
            <p>申請前請詳閱下列注意事項，同意後始得提出申請：</p>
            <ol className="chinese-ol">
                <li>
                    本會成績複查程序：
                    <ol>
                        <li>核對考生成績單各項成績結果一致無誤。</li>
                        <li>檢閱考生答案卷（單選測驗或個案分析）之作答是否符合規定。</li>
                        <li>以人工閱卷方式重新核算成績。</li>
                        <li>複查結果將以電子郵件方式寄回申請人。</li>
                    </ol>
                </li>
                <li>
                    申請成績複查，不得要求提供正確答案、閱覽、複印或退還答案卷，亦不得要求告知查核人員之姓名或其他有關資料。
                </li>
                <li>複查費用：申請複查每一科目費用新台幣200元</li>
                <li>成績複查需要3～5個工作天，成績複查結果將以電子郵件方式寄出，並可於線上系統查看。</li>
                <li>於該次測驗成績公布日後，三天內提出申請、完成付款，逾期者以「申請逾期」查覆。</li>
            </ol>
            <div className="row">
                <div className="d-grid">
                    <button type="button" name="save" className="btn btn-primary" onClick={onNextStep}>
                        下一步
                    </button>
                </div>
            </div>
        </Stack>
    );
};

export default FirstApplicationInfo;
