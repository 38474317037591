import React from "react";
import AgreementRadioGroup, { Option } from "./AgreementRadioGroup";

const values: Array<Option> = [
    {
        value: 0,
        label: "不同意",
    },
    {
        value: 1,
        label: "同意",
    },
];

export interface AgreementControlProps {
    formik: any;
    name: string;
    onChange: (value: string, name: string) => void;
}

const AgreementControl = ({ formik, name, onChange }: AgreementControlProps) => {
    return <AgreementRadioGroup values={values} name={name} onChange={onChange} formik={formik} />;
};

export default AgreementControl;
