import React from "react";
import "../../css/payment/barcode-info.sass";

const BarcodeInfo = () => {
    return (
        <div className="barcode-info">
            <p>注意事項：</p>
            <ol>
                <li>
                    本繳費單請以<span className="info-red">雷射印表機</span>列印。
                </li>
                <li>
                    條碼的入帳時間為3-5個工作日，若您超過入帳時間未收到通知，請與綠界聯繫。
                    <br /> 連絡電話：(02)2655-1775。
                </li>
                <li>
                    超商條碼的繳費期限為7天，請務必於期限內進行繳款。 <br />
                    例：08/01的20:15分購買商品，繳費期限為7天，表示08/08的20:15分前您必須前往繳費。
                </li>
                <li>提醒您！超商店員不會在結帳時另收手續費。</li>
                <li>此付款系統由「綠界科技ECPay有限公司」提供。</li>
            </ol>
        </div>
    );
};

export default BarcodeInfo;
