import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import RfaApi from "../RfaApi";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import { UserState } from "../components/types";
import { useHistory, useParams } from "react-router-dom";
import SuccessModal from "../components/m3/SuccessModal";
import ConfirmPaymentModal from "../components/m3/ConfirmPaymentModal";
import PaymentErrorModal from "../components/m3/PaymentErrorModal";
import PaymentFailedErrorModal from "../components/m3/PaymentFailedErrorModal";
import { FormData } from "../components/m3/RecognizeM3Form";
import RecognizeM3FormConfirm from "../components/m3/RecognizeM3FormConfirm";
import useUserProfile from "../components/events/useUserProfile";
import ErrorModal from "../components/form/ErrorModal";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

interface FormState {
    form: FormData;
}

const pageTitle = "認列CFP®/AFP之M3課程";

const RecognitionM3Confirm = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const userInfo = useSelector((state: UserState) => state.user);
    const [form, setForm] = useState<FormData>({ chName: "", idNumber: "", unit: "" });
    const [errorContent, setErrorContent] = useState<string | null>(null);
    const [isConfirmPaymentVisible, setConfirmPaymentVisible] = useState<boolean>(false);
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isPaymentErrorVisible, setPaymentErrorVisible] = useState<boolean>(false);
    const [isPaymentFailedErrorVisible, setPaymentFailedErrorVisible] = useState<boolean>(false);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const handleApiError = (message: string) => {
        setErrorContent(message);
    };
    //hook for load data
    const userProfileLoader = useUserProfile({ onError: handleApiError });
    const isLoading = userProfileLoader.isLoading;
    const profile = userProfileLoader.profile;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [userInfo, form]);

    useEffect(() => {
        if (profile) {
            setForm({
                chName: profile.chName,
                idNumber: profile.idNum,
                unit: profile.company,
            });
        }
    }, [profile]);

    const goToPersonalInformation = () => {
        history.push("/profile");
    };

    const doNext = () => {
        console.log("doPayment");
        setConfirmPaymentVisible(true);
    };

    const doPayment = () => {
        rfaApi
            .RegisterM3()
            .then((data) => {
                //3.進入綠界交易畫面
                window.location.href = `/api/payment/${data}`;
            })
            .catch((err) => {
                //3.取得最終交易參數失敗 (試圖重複報名)
                //setErrorContent(err.response.data);
                console.log("RegisterM3", err.response.data);
                setPaymentErrorVisible(true);
            });
    };

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                {!isLoading && profile ? (
                    <>
                        <FormContainer title={pageTitle} className="dashboard-card">
                            <RecognizeM3FormConfirm
                                formData={form}
                                onPrevious={goToPersonalInformation}
                                onNext={doNext}
                            />
                        </FormContainer>
                        <ConfirmPaymentModal
                            show={isConfirmPaymentVisible}
                            handleConfirm={doPayment}
                            handleClose={() => setConfirmPaymentVisible(false)}
                            chName={form.chName}
                            idNumber={form.idNumber}
                        ></ConfirmPaymentModal>
                        <SuccessModal show={isSuccessVisible} handleClose={() => setSuccessVisible(false)} />
                        <PaymentErrorModal
                            show={isPaymentErrorVisible}
                            handleClose={() => setPaymentErrorVisible(false)}
                        />
                        <PaymentFailedErrorModal
                            show={isPaymentFailedErrorVisible}
                            handleClose={() => setPaymentFailedErrorVisible(false)}
                        />
                    </>
                ) : (
                    <>載入中...</>
                )}
            </Col>
            <div id="rfa_payment" />
            {/*Payment container*/}
            <ErrorModal
                content={errorContent || ""}
                show={!!errorContent}
                handleClose={() => {
                    setErrorContent(null);
                }}
            />
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
};

export default RecognitionM3Confirm;
