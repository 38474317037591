import { newDate } from "./GregorianDate";

const getRocDateFromDate = (date) => `${date.getFullYear() - 1911}年${date.getMonth() + 1}月${date.getDate()}日`;
const getRocDateFromString = (dateString) => getRocDateFromDate(newDate(dateString));

export const getRocDate = (date) => {
    if (date instanceof Date) {
        return getRocDateFromDate(date);
    } else if (typeof date === "string") {
        return getRocDateFromString(date);
    }

    return "";
};

export const getRocDateDots = (date) => {
    return `${date.getFullYear() - 1911}.${date.getMonth() + 1}.${date.getDate()}`;
};

export const getRocYear = (date) => parseInt(date.getFullYear(), 10) - 1911;
export const getGregorianYearFromRocYear = (rocYear) => parseInt(rocYear, 10) + 1911;
