export const modifySurName = (value: string) => {
    const lowerCaseAll = value.toLowerCase();

    return lowerCaseAll.charAt(0).toUpperCase() + lowerCaseAll.slice(1);
};

export const modifyGivenName = (value: string) => {
    let lowerCaseAll = value.toLowerCase();
    //1.remove '-' from head & tail
    lowerCaseAll = lowerCaseAll.replace(/^-+/, "");
    lowerCaseAll = lowerCaseAll.replace(/-+$/, "");
    //remove repeated '-' & space
    lowerCaseAll = lowerCaseAll.replace(/([-,])\1+/, "-");
    lowerCaseAll = lowerCaseAll.replace(" ", "-");
    lowerCaseAll = lowerCaseAll.replace("- ", "-");
    lowerCaseAll = lowerCaseAll.replace(" -", "-");
    //2.split string by -
    const words = lowerCaseAll.split("-");
    let dashString = "";
    //3.upper case for first letter
    words.forEach(function (dword) {
        dashString += dword.charAt(0).toUpperCase() + dword.slice(1);
        dashString += "-";
    });
    dashString = dashString.slice(0, dashString.length - 1);

    return dashString.trim();
};

export const modifyIdNum = (value: string) => {
    return value.charAt(0).toUpperCase() + value.slice(1);
};

export const modifyCfpAfp = (value: string) => {
    return value.toUpperCase();
};
