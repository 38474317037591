import React, { useEffect, useState } from "react";
import { FormDataEntry } from "../form/FormDataConfirm";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FormDataView from "../form/FormDataView";
import { FormData } from "./RecognizeM3Form";

export interface EventRegisterFormConfirmProps {
    formData: FormData;
    onPrevious: () => void;
    onNext: () => void;
}

const RecognizeM3FormConfirm = ({ formData, onPrevious, onNext }: EventRegisterFormConfirmProps) => {
    const [confirmEntries, setConfirmEntries] = useState<Array<FormDataEntry>>([]);
    useEffect(() => {
        const entries: Array<FormDataEntry> = [];
        entries.push({ label: "中文姓名", value: formData.chName });
        entries.push({ label: "身分證字號", value: formData.idNumber });
        entries.push({ label: "現職服務單位", value: formData.unit });

        setConfirmEntries(entries);
    }, [formData]);

    return (
        <div className="form-confirm">
            <Row>
                <Col>
                    <FormDataView entries={confirmEntries} className="gray-labels" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <p>
                        欲認列CFP®之M3員工福利與退休金規劃課程者，本會將代收資料處理費800元整，申請完成後將轉您的資料與資料處理費至臺灣理財顧問認證協會，始完成認列申請。
                    </p>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup className="d-flex justify-content-between btn-group-spacing">
                        <Button variant="outline-primary" role="button" onClick={onPrevious}>
                            修改個人資料
                        </Button>
                        <Button type="submit" variant="primary" onClick={onNext}>
                            下一步
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </div>
    );
};

export default RecognizeM3FormConfirm;
