import React from "react";
import ErrorModal from "../modals/ErrorModal";

export interface PaymentErrorModalProps {
    show: boolean;
    handleClose: () => void;
}

const PaymentErrorModal = ({ show, handleClose }: PaymentErrorModalProps) => {
    return <ErrorModal show={show} handleClose={handleClose} title="請使用【M3狀態】了解付款狀態，勿重複申請付款。" />;
};

export default PaymentErrorModal;
