import twzipcode from "twzipcode-data";
import _ from "lodash";
const zipcodes = twzipcode().zipcodes;

export interface CountyCode {
    id: string;
    label: string;
}

export const countyCodes: Array<CountyCode> = [
    { id: "", label: "請選擇縣市" },
    { id: "臺北市", label: "臺北市" },
    { id: "基隆市", label: "基隆市" },
    { id: "新北市", label: "新北市" },
    { id: "宜蘭縣", label: "宜蘭縣" },
    { id: "新竹市", label: "新竹市" },
    { id: "新竹縣", label: "新竹縣" },
    { id: "桃園市", label: "桃園市" },
    { id: "苗栗縣", label: "苗栗縣" },
    { id: "臺中市", label: "臺中市" },
    { id: "彰化縣", label: "彰化縣" },
    { id: "南投縣", label: "南投縣" },
    { id: "嘉義市", label: "嘉義市" },
    { id: "嘉義縣", label: "嘉義縣" },
    { id: "雲林縣", label: "雲林縣" },
    { id: "臺南市", label: "臺南市" },
    { id: "高雄市", label: "高雄市" },
    { id: "澎湖縣", label: "澎湖縣" },
    { id: "屏東縣", label: "屏東縣" },
    { id: "臺東縣", label: "臺東縣" },
    { id: "花蓮縣", label: "花蓮縣" },
    { id: "金門縣", label: "金門縣" },
    { id: "連江縣", label: "連江縣" },
];

export const findCountyByArea = (areaId: string) => {
    if (!areaId) {
        return "";
    }

    const result = _.find(zipcodes, { id: areaId });
    //console.log("findCountyByArea", areaId, result);
    if (!result) {
        return "";
    }

    return result.county;
};
