import React, { useEffect } from "react";
import { useFormik } from "formik";
import { ButtonGroup } from "react-bootstrap";
import * as Yup from "yup";
import { formikErrorFeedback, useFormError } from "./formik/Form";
import DateSelect from "./formik/DateSelect";
import { STR_REQUIRED_FIELD, strNumberGreaterThan, strNumberLessThan } from "./formik/Strings";
import "../css/form-vertical.sass";
import PduAttachment from "./PduAttachment";
import { countDecimals } from "./math/decimal";
import { isDateLaterOrEqual, newDate } from "./date/GregorianDate";
import { showErrorAlert } from "./form/Errors";

const PduForm = ({ formData, onSubmit, doPrevious, readonly = false }) => {
    const formik = useFormik({
        initialValues: {
            id: "",
            category: "",
            hours: 0,
            startDate: new Date(),
            endDate: new Date(),
            trainingUnit: "",
            courseName: "",
            attachment: "",
        },
        validationSchema: Yup.object({
            category: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            hours: Yup.number()
                .test("maxDigits", "請輸入到小數點後第一位", (value) => countDecimals(value) <= 1)
                .positive()
                .min(0.1, strNumberGreaterThan(0))
                .max(100_000_000, strNumberLessThan(100_000_000))
                .required(STR_REQUIRED_FIELD)
                .typeError(STR_REQUIRED_FIELD),
            startDate: Yup.date().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            endDate: Yup.date()
                .required(STR_REQUIRED_FIELD)
                .typeError(STR_REQUIRED_FIELD)
                .test("dateLaterOrEqual", "進修開始日期請早於等於進修結束日期", (value) =>
                    isDateLaterOrEqual(value, formik.values.startDate)
                ),
            trainingUnit: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            courseName: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
            attachment: Yup.string().nullable(),
        }),
        onSubmit: (values) => {
            onSubmit(values);
        },
    });

    const setFormikDate = (field, date) => {
        if (!date) {
            return;
        }
        const dateObject = newDate(date);
        if (!dateObject) {
            return;
        }

        formik.setFieldValue(field, date);
    };

    useEffect(() => {
        formik.setValues({ ...formik.values, ...formData });
        setFormikDate("endDate", formData.endDate);
        setFormikDate("startDate", formData.startDate);
    }, [formData]);

    const isFormError = useFormError(formik);
    useEffect(() => {
        if (!readonly && isFormError) {
            showErrorAlert();
        }
    }, [isFormError]);

    const errorFeedback = (field) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

    return (
        <form className="form-pdu-application form-vertical" onSubmit={formik.handleSubmit}>
            <fieldset disabled={readonly}>
                <input name="id" type="hidden" {...formik.getFieldProps("id")} />
                <div className="row">
                    <div className="col-auto">
                        <label className="form-label" htmlFor="category">
                            進修類別
                        </label>
                    </div>
                    <div className="col-auto">
                        <input
                            type="text"
                            className={`form-control ${errorClass("category")}`}
                            {...formik.getFieldProps("category")}
                        />
                        {errorFeedback("category")}
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <label className="form-label" htmlFor="hours">
                            進修小時
                        </label>
                    </div>
                    <div className="col-auto">
                        <input
                            type="number"
                            step="0.1"
                            className={`form-control ${errorClass("hours")}`}
                            {...formik.getFieldProps("hours")}
                        />
                        {errorFeedback("hours")}
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <label className="form-label" htmlFor="startDate">
                            進修開始日期
                        </label>
                    </div>
                    <div className="col-auto">
                        <DateSelect
                            inputPrefix="startDate"
                            formik={formik}
                            readOnly={readonly}
                            onChange={(date) => formik.setFieldValue("startDate", date)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <label className="form-label" htmlFor="endDate">
                            進修結束日期
                        </label>
                    </div>
                    <div className="col-auto">
                        <DateSelect
                            inputPrefix="endDate"
                            formik={formik}
                            readOnly={readonly}
                            onChange={(date) => formik.setFieldValue("endDate", date)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <label className="form-label" htmlFor="trainingUnit">
                            進修單位
                        </label>
                    </div>
                    <div className="col-auto">
                        <input
                            type="text"
                            className={`form-control ${errorClass("trainingUnit")}`}
                            {...formik.getFieldProps("trainingUnit")}
                        />
                        {errorFeedback("trainingUnit")}
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <label className="form-label" htmlFor="category">
                            課程/活動名稱
                        </label>
                    </div>
                    <div className="col-auto">
                        <input
                            type="text"
                            className={`form-control ${errorClass("courseName")}`}
                            {...formik.getFieldProps("courseName")}
                        />
                        {errorFeedback("courseName")}
                    </div>
                </div>
                <div className="row">
                    <div className="col-auto">
                        <label className="form-label" htmlFor="attachment">
                            (選填)附件上傳(小於3MB)
                        </label>
                    </div>
                    <div className="col-auto">
                        <PduAttachment name="attachment" formik={formik} readOnly={readonly} />
                    </div>
                </div>
            </fieldset>
            <div className="row">
                <ButtonGroup size="lg" className="d-flex justify-content-around">
                    {doPrevious && (
                        <button type="button" className="btn btn-secondary" onClick={doPrevious}>
                            上一步
                        </button>
                    )}
                    <button type="submit" name="save" className="btn btn-primary">
                        申請
                    </button>
                </ButtonGroup>
            </div>
        </form>
    );
};

export default PduForm;
