import React from "react";
import "../css/form-vertical.sass";
import { formikErrorFeedback } from "./formik/Form";

const PduAttachment = ({ formik, readOnly, name }) => {
    //console.log("PduAttachment", readOnly, name);
    //console.log(formik);
    const errorFeedback = (field) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

    const clearAttachment = () => {
        document.getElementById(name).value = "";
        formik.setFieldValue(name, "");
        formik.setFieldValue("file", null);
    };
    const canRemoveAttachment = () => {
        const attachment = formik.values[name];
        if (attachment) {
            return true;
        }
        return false;
    };
    const setAttachment = (e) => {
        if (e.currentTarget.files.length > 0) {
            formik.setFieldValue(name, e.currentTarget.files[0].name);
            formik.setFieldValue("file", e.currentTarget.files[0]);
        } else {
            clearAttachment();
        }
        console.log("setAttachment", formik.values[name]);
    };
    const getFileName = () => {
        const file = formik.values[name];
        if (file?.name?.length) {
            return file.name;
        }
        if (formik.values[name]) {
            return formik.values[name];
        }
        return "";
    };

    return (
        <>
            <div className="input-group mb-3">
                <input type="text" className="form-control" value={getFileName()} readOnly={true} />
                {!readOnly && (
                    <label className="custom-file-upload">
                        <input
                            type="file"
                            id={name}
                            className={`form-control ${errorClass(name)} d-none`}
                            onChange={setAttachment}
                        />
                        <i
                            className="btn btn-light"
                            uk-icon="upload"
                            style={{
                                border: "0px solid #ccc",
                                display: "inline-block",
                                padding: "6px 12px",
                                cursor: "pointer",
                            }}
                        ></i>
                        選擇檔案
                    </label>
                )}
                {!readOnly && canRemoveAttachment() && (
                    <div className="input-group-append">
                        <button className="btn btn-light btn-remove-file" type="button" onClick={clearAttachment}>
                            <span className="uk-icon" uk-icon="trash" />
                        </button>
                    </div>
                )}
                {errorFeedback(name)}
            </div>
        </>
    );
};

export default PduAttachment;
