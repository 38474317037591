import React, { useState, useEffect } from "react";
import { Modal, Button, Image } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import { Advertisement } from "../../components/types";
import Form from "react-bootstrap/Form";

interface AdModalProps {
    show: boolean;
    handleClose: () => void;
    item: Advertisement;
    isChecked: boolean;
    setIsChecked: (value: boolean) => void;
    saveAdvertisent: (value: boolean) => void;
}

const AdModal = ({ show, handleClose, item, isChecked, setIsChecked, saveAdvertisent }: AdModalProps) => {
    // const [isChecked, setIsChecked] = useState(false);

    // 需要把內層的狀態傳出給父層 讓 父層關閉廣告
    const closeAdvertisentDialog = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
    };

    return (
        <Modal
            show={show && item.enable === "true"}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered={true}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
        >
            {/* <Modal.Header closeButton></Modal.Header> */}
            <Modal.Body className="pa-4" id="contained-modal-title-vcenter">
                <a href={item.link} target="_blank" rel="noreferrer" key={item.id}>
                    <Image src={item.adImgUrl} fluid style={{ display: "block", margin: "auto" }}></Image>
                </a>
                <Stack
                    gap={3}
                    className="d-flex justify-content-between align-items-center flex-row"
                    style={{ marginTop: "10px" }}
                >
                    <div style={{ opacity: 0 }}>34234324323423</div>
                    <Button
                        variant="primary"
                        onClick={(e) => {
                            e.preventDefault();
                            saveAdvertisent(isChecked);
                        }}
                    >
                        關閉
                    </Button>
                    <Form.Group className="d-flex">
                        {/* (e) => setIsChecked(e.target.checked) */}
                        <Form.Check type="checkbox" id="noAdverts">
                            <Form.Check.Input type="checkbox" checked={isChecked} onChange={closeAdvertisentDialog} />
                            <Form.Check.Label>今日不再顯示</Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </Stack>
            </Modal.Body>
        </Modal>
    );
};

export default AdModal;
