import React, { useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { formikErrorFeedback } from "../formik/Form";
import { STR_REQUIRED_FIELD } from "../formik/Strings";
import { Col, Row } from "react-bootstrap";
import YesNoSelect from "../formik/YesNoSelect";
import GenericSelect from "../formik/GenericSelect";
import { companyTypes, TYPE_OTHER_COMPANY } from "../company/CompanyType";
import DependentField from "../form/DependentField";
import useCompanies from "../company/useCompanies";
import { countyCodes, findCountyByArea } from "../address/CountyCode";
import { schemaDesc as profileSchemaDesc } from "../profile/ProfileValidationSchema";
import { modifySurName, modifyGivenName, modifyIdNum, modifyCfpAfp } from "../profile/FieldModifiers";
import RadioGroup from "../formik/RadioGroup";
import { testTypes } from "../signup/TestType";
import { testAreas } from "../signup/TestArea";
import { addressTypes } from "../address/AddressType";
import useRegions from "../address/useRegions";
import { findZipcodeByArea } from "../address/Zipcode";

const validationSchema = Yup.object({
    applyType: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    applyArea: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    ...profileSchemaDesc,
});

export interface FormData {
    applyType: string;
    applyArea: string;
    chName: string;
    SurName: string;
    GivenName: string;
    cfpAfp: string;
    certNum: string;
    idNum: string;
    birthday: string;
    companyType: string;
    company: string;
    mainAddress: string;
    vatTitle: string;
    vatNumber: string;
    eduLevel: string;
    graduateYear: string;
    school: string;
    eduClass: string;
    workPhone: string;
    homePhone: string;
    mobile: string;
    email: string;
    companyArea: string;
    companyZipcode: string;
    companyCounty: string;
    companyAddress: string;
    homeArea: string;
    homeZipcode: string;
    homeCounty: string;
    homeAddress: string;
}

type FormKeys = keyof FormData;

export interface SignupFormProps {
    formData?: FormData;
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
}

const hasCfpAfp = (formData?: FormData) => {
    if (!formData) {
        return "0";
    }
    const { certNum } = formData;
    const hasNumber = certNum?.length ?? false;

    return hasNumber ? "1" : "0";
};

const SignupForm = ({ formData, onSubmit }: SignupFormProps) => {
    const formik = useFormik({
        initialValues: {
            applyType: "",
            applyArea: "",
            chName: "",
            SurName: "",
            GivenName: "",
            cfpAfp: "",
            certNum: "",
            idNum: "",
            birthday: "",
            companyType: "",
            company: "",
            mainAddress: "",
            vatTitle: "",
            vatNumber: "",
            eduLevel: "",
            graduateYear: "",
            school: "",
            eduClass: "",
            workPhone: "",
            homePhone: "",
            mobile: "",
            email: "",
            companyArea: "",
            companyZipcode: "",
            companyCounty: "",
            companyAddress: "",
            homeArea: "",
            homeZipcode: "",
            homeCounty: "",
            homeAddress: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            const { cfpAfp } = values;
            if (cfpAfp !== "1") {
                values.certNum = "";
            }

            onSubmit(values, formikHelpers);
        },
        enableReinitialize: true,
    });

    const companies = useCompanies(formik.values.companyType);
    const companyAreas = useRegions(formik.values.companyCounty);
    const homeAreas = useRegions(formik.values.homeCounty);

    useEffect(() => {
        const counties = {
            companyCounty: "",
            homeCounty: "",
        };
        if (formData?.companyArea) {
            counties.companyCounty = findCountyByArea(formData?.companyArea);
        }
        if (formData?.homeArea) {
            counties.homeCounty = findCountyByArea(formData?.homeArea);
        }

        formik.setValues({
            ...formik.values,
            ...formData,
            ...counties,
            ...{
                cfpAfp: hasCfpAfp(formData),
            },
        });
    }, [formData]);

    useEffect(() => {
        const companyZipcode = findZipcodeByArea(formik.values.companyArea);
        formik.setValues({
            ...formik.values,
            ...{
                companyZipcode,
            },
        });
    }, [formik.values.companyArea]);

    useEffect(() => {
        const homeZipcode = findZipcodeByArea(formik.values.homeArea);

        formik.setValues({
            ...formik.values,
            ...{
                homeZipcode,
            },
        });
    }, [formik.values.homeArea]);

    const errorFeedback = (field: string) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field: FormKeys) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack className="form-content">
                <fieldset>
                    <Row className="form-row">
                        <Col>
                            <RadioGroup
                                {...formik.getFieldProps("applyType")}
                                label={"身份"}
                                errorClass={errorClass("applyType")}
                                values={testTypes}
                                onChange={(value) => formik.setFieldValue("applyType", value)}
                            />
                            {errorFeedback("applyType")}
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col>
                            <RadioGroup
                                {...formik.getFieldProps("applyArea")}
                                label={"考區"}
                                errorClass={errorClass("applyArea")}
                                values={testAreas}
                                onChange={(value) => formik.setFieldValue("applyArea", value)}
                            />
                            {errorFeedback("applyArea")}
                        </Col>
                    </Row>
                </fieldset>
                <fieldset>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="signupForm.chName">
                                <Form.Label>中文姓名</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="王大明"
                                    pattern="^[\u4e00-\u9fa5]{2,7}$"
                                    className={`form-control ${errorClass("chName")}`}
                                    {...formik.getFieldProps("chName")}
                                />
                                {errorFeedback("chName")}
                                <Form.Text id="chNameHelp" className="text-muted">
                                    <small>請輸入中文姓名，符合中華民國身分證記載，2到7個繁體中文字</small>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="signupForm.SurName">
                                <Form.Label>英文姓</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Wang"
                                    pattern="^[a-zA-Z]{2,8}$"
                                    className={`form-control ${errorClass("SurName")}`}
                                    {...formik.getFieldProps("SurName")}
                                    onBlur={(e) => {
                                        const surname = modifySurName(e.target.value);
                                        formik.handleBlur(e);
                                        formik.setFieldValue("SurName", surname);
                                    }}
                                />
                                {errorFeedback("SurName")}
                                <Form.Text id="SurNameHelp" className="text-muted">
                                    <small>請輸入英文姓，符合中華民國護照記載，限英文半形字</small>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="signupForm.GivenName">
                                <Form.Label>英文名</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Da-Ming"
                                    pattern="^[a-zA-Z-]{2,16}$"
                                    className={`form-control ${errorClass("GivenName")}`}
                                    {...formik.getFieldProps("GivenName")}
                                    onBlur={(e) => {
                                        const givenName = modifyGivenName(e.target.value);
                                        formik.handleBlur(e);
                                        formik.setFieldValue("GivenName", givenName);
                                    }}
                                />
                                {errorFeedback("GivenName")}
                                <Form.Text id="GivenNameHelp" className="text-muted">
                                    <small>請輸入英文名，符合中華民國護照記載，限英文半形字或減號</small>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
                <fieldset>
                    <Row className="form-row">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="profileForm.cfpAfp">
                                <YesNoSelect
                                    label={"是否持有CFP/AFP證照"}
                                    errorClass={errorClass("cfpAfp")}
                                    {...formik.getFieldProps("cfpAfp")}
                                    onChange={(value) => formik.setFieldValue("cfpAfp", value)}
                                />
                                {errorFeedback("cfpAfp")}
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <DependentField isVisible={formik.values.cfpAfp === "1"}>
                                <Form.Group controlId="profileForm.certNum">
                                    <Form.Label>證號</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="請輸入CFP/AFP證照號碼"
                                        className={`form-control ${errorClass("certNum")}`}
                                        {...formik.getFieldProps("certNum")}
                                        onChange={(e) => {
                                            e.target.value = modifyCfpAfp(e.target.value);
                                            formik.handleChange(e);
                                        }}
                                    />
                                    {errorFeedback("certNum")}
                                </Form.Group>
                            </DependentField>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.idNum">
                                <Form.Label>身分證字號</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    pattern="^[a-zA-Z]\d{9}$"
                                    className={`form-control ${errorClass("idNum")}`}
                                    {...formik.getFieldProps("idNum")}
                                    onChange={(e) => {
                                        e.target.value = modifyIdNum(e.target.value);
                                        formik.handleChange(e);
                                    }}
                                />
                                {errorFeedback("idNum")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.birthday">
                                <Form.Label>出生年月日</Form.Label>
                                <Form.Control
                                    type="date"
                                    required
                                    className={`form-control ${errorClass("birthday")}`}
                                    {...formik.getFieldProps("birthday")}
                                />
                                {errorFeedback("birthday")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="profileForm.companyType">
                                <GenericSelect
                                    label="現職服務單位類別"
                                    options={companyTypes}
                                    errorClass={errorClass("companyType")}
                                    {...formik.getFieldProps("companyType")}
                                    onChange={(value) => formik.setFieldValue("companyType", value)}
                                />

                                {errorFeedback("companyType")}
                            </Form.Group>
                        </Col>
                        {formik.values.companyType !== TYPE_OTHER_COMPANY && (
                            <Col xs={12} lg={6}>
                                <Form.Group controlId="profileForm.company">
                                    <GenericSelect
                                        label="現職服務單位"
                                        options={companies}
                                        errorClass={errorClass("company")}
                                        {...formik.getFieldProps("company")}
                                        onChange={(value) => formik.setFieldValue("company", value)}
                                    />
                                </Form.Group>
                            </Col>
                        )}
                        {formik.values.companyType === TYPE_OTHER_COMPANY && (
                            <Col xs={12} lg={5} className="align-self-end">
                                <Form.Group controlId="profileForm.company">
                                    <Form.Control
                                        {...formik.getFieldProps("company")}
                                        placeholder="請輸入現職服務單位"
                                    />
                                    {errorFeedback("company")}
                                </Form.Group>
                            </Col>
                        )}
                    </Row>
                </fieldset>
                <fieldset>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.vatTitle">
                                <Form.Label>收據抬頭</Form.Label>
                                <Form.Control
                                    type="text"
                                    className={`form-control ${errorClass("vatTitle")}`}
                                    {...formik.getFieldProps("vatTitle")}
                                />
                                {errorFeedback("vatTitle")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.vatNumber">
                                <Form.Label>統一編號</Form.Label>
                                <Form.Control
                                    type="text"
                                    className={`form-control ${errorClass("vatNumber")}`}
                                    {...formik.getFieldProps("vatNumber")}
                                />
                                {errorFeedback("vatNumber")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.eduLevel">
                                <Form.Label>最高學歷</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="大學"
                                    className={`form-control ${errorClass("eduLevel")}`}
                                    {...formik.getFieldProps("eduLevel")}
                                />
                                {errorFeedback("eduLevel")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.graduateYear">
                                <Form.Label>學位取得年度</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="2010"
                                    className={`form-control ${errorClass("graduateYear")}`}
                                    {...formik.getFieldProps("graduateYear")}
                                />
                                {errorFeedback("graduateYear")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="profileForm.school">
                                <Form.Label>畢業學校</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="OO大學"
                                    className={`form-control ${errorClass("school")}`}
                                    {...formik.getFieldProps("school")}
                                />
                                {errorFeedback("school")}
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="profileForm.eduClass">
                                <Form.Label>科系</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="OO學系"
                                    className={`form-control ${errorClass("eduClass")}`}
                                    {...formik.getFieldProps("eduClass")}
                                />
                                {errorFeedback("eduClass")}
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
                <fieldset>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.workPhone">
                                <Form.Label>公司電話</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="(02)3334567"
                                    className={`form-control ${errorClass("workPhone")}`}
                                    {...formik.getFieldProps("workPhone")}
                                />
                                {errorFeedback("workPhone")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.homePhone">
                                <Form.Label>住家電話</Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="(08)9998765"
                                    className={`form-control ${errorClass("homePhone")}`}
                                    {...formik.getFieldProps("homePhone")}
                                />
                                {errorFeedback("homePhone")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.mobile">
                                <Form.Label>行動電話</Form.Label>
                                <Form.Control
                                    type="tel"
                                    required
                                    placeholder="0987654321"
                                    pattern="[0-9]{10}"
                                    className={`form-control ${errorClass("mobile")}`}
                                    {...formik.getFieldProps("mobile")}
                                />
                                {errorFeedback("mobile")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.email">
                                <Form.Label>電子郵件信箱</Form.Label>
                                <Form.Control
                                    type="email"
                                    required
                                    placeholder="name@example.com"
                                    className={`form-control ${errorClass("email")}`}
                                    {...formik.getFieldProps("email")}
                                />
                                {errorFeedback("email")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row gy-4">
                        <Col xs={6} lg={2}>
                            <Form.Label>公司地址</Form.Label>
                            <Form.Group controlId="profileForm.companyZipcode">
                                <Form.Control
                                    type="text"
                                    readOnly
                                    required
                                    placeholder="郵遞區號"
                                    pattern="[0-9]{3,3}|[0-9]{5,5}|[0-9]{6,6}"
                                    className={`form-control ${errorClass("companyZipcode")}`}
                                    {...formik.getFieldProps("companyZipcode")}
                                />
                                {errorFeedback("companyArea")}
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={5} className="align-self-end">
                            <Form.Group controlId="profileForm.companyCounty">
                                <GenericSelect
                                    options={countyCodes}
                                    errorClass={errorClass("companyCounty")}
                                    {...formik.getFieldProps("companyCounty")}
                                    onChange={(value) => formik.setFieldValue("companyCounty", value)}
                                />
                                {errorFeedback("companyCounty")}
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={5} className="align-self-end">
                            <Form.Group controlId="profileForm.companyArea">
                                <GenericSelect
                                    options={companyAreas}
                                    errorClass={errorClass("companyArea")}
                                    {...formik.getFieldProps("companyArea")}
                                    onChange={(value) => formik.setFieldValue("companyArea", value)}
                                />
                                {errorFeedback("companyArea")}
                            </Form.Group>
                        </Col>
                        <Form.Group controlId="profileForm.companyAddress">
                            <Form.Control
                                type="text"
                                required
                                placeholder="請輸入地址"
                                className={`form-control ${errorClass("companyAddress")}`}
                                {...formik.getFieldProps("companyAddress")}
                            />
                            {errorFeedback("companyAddress")}
                        </Form.Group>
                    </Row>

                    <Row className="form-row gy-4">
                        <Col xs={6} lg={2}>
                            <Form.Label>住家地址</Form.Label>
                            <Form.Group controlId="profileForm.homeZipcode">
                                <Form.Control
                                    type="text"
                                    readOnly
                                    required
                                    placeholder="郵遞區號"
                                    pattern="[0-9]{3,3}|[0-9]{5,5}|[0-9]{6,6}"
                                    className={`form-control ${errorClass("homeZipcode")}`}
                                    {...formik.getFieldProps("homeZipcode")}
                                />
                                {errorFeedback("homeZipcode")}
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={5} className="align-self-end">
                            <Form.Group controlId="profileForm.homeCounty">
                                <GenericSelect
                                    options={countyCodes}
                                    errorClass={errorClass("homeCounty")}
                                    {...formik.getFieldProps("homeCounty")}
                                    onChange={(value) => formik.setFieldValue("homeCounty", value)}
                                />
                                {errorFeedback("homeCounty")}
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={5} className="align-self-end">
                            <Form.Group controlId="profileForm.homeArea">
                                <GenericSelect
                                    options={homeAreas}
                                    errorClass={errorClass("homeArea")}
                                    {...formik.getFieldProps("homeArea")}
                                    onChange={(value) => formik.setFieldValue("homeArea", value)}
                                />
                                {errorFeedback("homeArea")}
                            </Form.Group>
                        </Col>
                        <Form.Group controlId="profileForm.homeAddress">
                            <Form.Control
                                type="text"
                                required
                                placeholder="請輸入地址"
                                className={`form-control ${errorClass("homeAddress")}`}
                                {...formik.getFieldProps("homeAddress")}
                            />
                            {errorFeedback("homeAddress")}
                        </Form.Group>
                    </Row>
                    <Row className="form-row">
                        <Col>
                            <RadioGroup
                                {...formik.getFieldProps("mainAddress")}
                                label="主要聯絡地址"
                                errorClass={errorClass("mainAddress")}
                                values={addressTypes}
                                onChange={(value) => formik.setFieldValue("mainAddress", value)}
                            />
                            {errorFeedback("mainAddress")}
                        </Col>
                    </Row>
                </fieldset>
                <Button type="submit" variant="primary">
                    下一步
                </Button>
            </Stack>
        </Form>
    );
};

export default SignupForm;
