import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import MaterialIcon from "../components/material/MaterialIcon";

const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <Container className="footer" fluid>
            <Row className="justify-content-lg-center">
                <Col lg={4}>
                    <p>RFA(Retirement Financial Advisor)</p>
                    <p>
                        <small>
                            退休理財規劃顧問是一套專屬台灣的退休規劃培訓與認證，為首張深入本土的退休理財規劃證照。
                        </small>
                    </p>
                </Col>
                <Col lg={3} className="footer-contact">
                    <Row>
                        <Stack gap={2}>
                            <p>聯絡協會</p>
                            <div className="icon-wrapper">
                                <MaterialIcon icon="email" />
                                <small>pfaservice@pension.org.tw</small>
                            </div>
                            <div className="icon-wrapper">
                                <MaterialIcon icon="phone" />
                                <small>02-2506-8185</small>
                            </div>
                            <div className="icon-wrapper">
                                <MaterialIcon icon="print" />
                                <small>02-2506-8165</small>
                            </div>
                            <div className="icon-wrapper">
                                <MaterialIcon icon="location_on" />
                                <small>台北市中山區南京東路二段150號11樓106室</small>
                            </div>
                        </Stack>
                    </Row>
                </Col>
                <Col lg={2}>
                    <Row>
                        <Stack gap={2}>
                            <p>條款說明</p>
                            <small>
                                <a href="/terms-of-service">使用者條款</a>
                            </small>
                            <small>
                                <a href="/terms-of-service">隱私權政策</a>
                            </small>
                        </Stack>
                    </Row>
                </Col>
            </Row>
            <Row className="footer-copy justify-content-center">
                <Col xs="auto">
                    <p>© {year} 中華民國退休基金協會 All Rights Reserved.</p>
                </Col>
            </Row>
        </Container>
    );
};

export default Footer;
