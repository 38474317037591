import React, { useEffect } from "react";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import { formikErrorFeedback } from "./formik/Form";
import { STR_REQUIRED_FIELD, STR_REQUIRED_NUMBER, strNumberGreaterThan, strNumberLessThan } from "./formik/Strings";
import "../css/form-vertical.sass";
import { countDecimals } from "./math/decimal";
import Form from "react-bootstrap/Form";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import GenericSelect from "../components/formik/GenericSelect";
import { companyTypes, TYPE_OTHER_COMPANY } from "../components/company/CompanyType";
import { Col, Row } from "react-bootstrap";
import NumberInput from "../components/form/NumberInput";
import useCompanies from "./company/useCompanies";

const HOURS_IN_A_WEEK = 24 * 7;

const validationSchema = Yup.object({
    company: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    jobTitle: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    companyType: Yup.number().min(1, STR_REQUIRED_FIELD).required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    workDate: Yup.date().typeError(STR_REQUIRED_FIELD).required(STR_REQUIRED_FIELD),
    workHoursPerWeek: Yup.number()
        .typeError(STR_REQUIRED_NUMBER)
        .test("maxDigits", "請輸入到小數點後第一位", (value) => countDecimals(value) <= 1)
        .positive()
        .min(0.1, strNumberGreaterThan(0))
        .max(HOURS_IN_A_WEEK, strNumberLessThan(HOURS_IN_A_WEEK))
        .required(STR_REQUIRED_FIELD)
        .typeError(STR_REQUIRED_FIELD),
    totalYears: Yup.number()
        .positive()
        .integer()
        .min(0, strNumberGreaterThan(0))
        .required(STR_REQUIRED_FIELD)
        .typeError(STR_REQUIRED_FIELD),
    totalMonths: Yup.number()
        .positive()
        .integer()
        .min(0, strNumberGreaterThan(0))
        .max(12, strNumberLessThan(12))
        .required(STR_REQUIRED_FIELD)
        .typeError(STR_REQUIRED_FIELD),
    description: Yup.string().max(500).required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
});

export interface FormData {
    company: string;
    jobTitle: string;
    companyType: string;
    workDate: string;
    workHoursPerWeek: number;
    totalYears: number;
    totalMonths: number;
    description: string;
}

type FormKeys = keyof FormData;

export interface WorkExperienceFormProps {
    formData?: FormData;
    submitButtonTitle: string;
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
}

const WorkExperienceForm = ({ formData, submitButtonTitle, onSubmit }: WorkExperienceFormProps) => {
    const formik = useFormik<FormData>({
        initialValues: {
            company: "",
            jobTitle: "",
            companyType: "",
            workDate: "",
            workHoursPerWeek: 0,
            totalYears: 0,
            totalMonths: 0,
            description: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            onSubmit(values, formikHelpers);
        },
        enableReinitialize: true,
    });

    const companies = useCompanies(formik.values.companyType);

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            ...formData,
        });
    }, [formData]);

    const errorFeedback = (field: FormKeys) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field: FormKeys) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack className="form-content">
                <fieldset>
                    <Row className="form-row">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="workExperienceForm.companyType">
                                <GenericSelect
                                    label="現職服務單位類別"
                                    options={companyTypes}
                                    errorClass={errorClass("companyType")}
                                    {...formik.getFieldProps("companyType")}
                                    onChange={(value) => formik.setFieldValue("companyType", value)}
                                />
                                {errorFeedback("companyType")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col xs={12} lg={6}>
                            {formik.values.companyType !== TYPE_OTHER_COMPANY && (
                                <Form.Group controlId="workExperienceForm.company">
                                    <GenericSelect
                                        label="現職服務單位"
                                        options={companies}
                                        errorClass={errorClass("company")}
                                        {...formik.getFieldProps("company")}
                                        onChange={(value) => formik.setFieldValue("company", value)}
                                    />
                                    {errorFeedback("company")}
                                </Form.Group>
                            )}
                            {formik.values.companyType === TYPE_OTHER_COMPANY && (
                                <Form.Group controlId="workExperienceForm.company">
                                    <Form.Label>現職服務單位</Form.Label>
                                    <Form.Control
                                        {...formik.getFieldProps("company")}
                                        placeholder="請輸入現職服務單位"
                                    />
                                    {errorFeedback("company")}
                                </Form.Group>
                            )}
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="workExperienceForm.jobTitle">
                                <Form.Label>職稱</Form.Label>
                                <Form.Control
                                    type="text"
                                    placeholder="請輸入內容"
                                    className={`form-control ${errorClass("jobTitle")}`}
                                    {...formik.getFieldProps("jobTitle")}
                                />
                                {errorFeedback("jobTitle")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="workExperienceForm.workDate">
                                <Form.Label>本工作到職日</Form.Label>
                                <Form.Control
                                    type="date"
                                    className={`form-control ${errorClass("workDate")}`}
                                    {...formik.getFieldProps("workDate")}
                                />
                                {errorFeedback("workDate")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col xs={12} lg={6}>
                            <Form.Label>每週工作時數</Form.Label>
                            <Form.Group controlId="workExperienceForm.workHoursPerWeek">
                                <NumberInput
                                    step={0.1}
                                    placeholder="小時"
                                    appendText="小時"
                                    className={`form-control ${errorClass("workHoursPerWeek")}`}
                                    {...formik.getFieldProps("workHoursPerWeek")}
                                    onChange={(value) => formik.setFieldValue("workHoursPerWeek", value)}
                                />
                                {errorFeedback("workHoursPerWeek")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col xs={6} lg={3}>
                            <Form.Label>總計年資</Form.Label>
                            <Form.Group controlId="workExperienceForm.totalYears">
                                <NumberInput
                                    step={1}
                                    min={0}
                                    placeholder="0"
                                    appendText="年"
                                    className={`form-control ${errorClass("totalYears")}`}
                                    {...formik.getFieldProps("totalYears")}
                                    onChange={(value) => formik.setFieldValue("totalYears", value)}
                                />
                                {errorFeedback("totalYears")}
                            </Form.Group>
                        </Col>
                        <Col xs={6} lg={3} className="align-self-end">
                            <Form.Group controlId="workExperienceForm.totalMonths">
                                <NumberInput
                                    step={1}
                                    max={12}
                                    min={0}
                                    placeholder="0"
                                    appendText="月"
                                    className={`form-control ${errorClass("totalMonths")}`}
                                    {...formik.getFieldProps("totalMonths")}
                                    onChange={(value) => formik.setFieldValue("totalMonths", value)}
                                />

                                {errorFeedback("totalMonths")}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col>
                            <Form.Label>請簡述實質工作內容</Form.Label>
                            <Form.Group controlId="workExperienceForm.description">
                                <Form.Control
                                    type="text"
                                    as="textarea"
                                    placeholder="必填"
                                    maxLength={500}
                                    rows={7}
                                    className={`form-control ${errorClass("description")}`}
                                    {...formik.getFieldProps("description")}
                                />
                                {errorFeedback("description")}
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
                <Button type="submit" variant="primary">
                    {submitButtonTitle}
                </Button>
            </Stack>
        </Form>
    );
};

export default WorkExperienceForm;
