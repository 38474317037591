import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import { FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { formikErrorFeedback } from "../components/formik/Form";
import { STR_REQUIRED_FIELD } from "../components/formik/Strings";
import { ButtonGroup, Col, Row } from "react-bootstrap";

const STR_PASSWORD_TOO_SHORT = "密碼長度不足";
const STR_PASSWORD_DOES_NOT_MATCH = "密碼輸入不一致";
const validationSchema = Yup.object({
    pass: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    newpass: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD).min(8, STR_PASSWORD_TOO_SHORT),
    confirmation: Yup.string()
        .required(STR_REQUIRED_FIELD)
        .typeError(STR_REQUIRED_FIELD)
        .oneOf([Yup.ref("newpass")], STR_PASSWORD_DOES_NOT_MATCH),
});

export interface FormData {
    pass: string;
    newpass: string;
    confirmation: string;
}

type FormKeys = keyof FormData;

export interface LoginChangeFormProps {
    formData?: FormData;
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
}

const LoginChangeForm = ({ formData, onSubmit }: LoginChangeFormProps) => {
    const formik = useFormik({
        initialValues: {
            pass: "",
            newpass: "",
            confirmation: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, formikHelpers) => {
            onSubmit(values, formikHelpers);
        },
        enableReinitialize: true,
    });

    useEffect(() => {
        formik.setValues({
            ...formik.values,
            ...formData,
        });
    }, [formData]);

    const errorFeedback = (field: string) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field: FormKeys) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack className="form-content">
                <fieldset>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.pass">
                                <Form.Label>舊密碼</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="請輸入舊密碼"
                                    className={`form-control ${errorClass("pass")}`}
                                    {...formik.getFieldProps("pass")}
                                />
                                {errorFeedback("pass")}
                                <Form.Text id="loginHelp" className="text-muted">
                                    <small>預設為手機號碼共10碼</small>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.newpass">
                                <Form.Label>新密碼</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="請輸入新密碼"
                                    className={`form-control ${errorClass("newpass")}`}
                                    {...formik.getFieldProps("newpass")}
                                />
                                {errorFeedback("newpass")}
                                <Form.Text id="loginHelp" className="text-muted">
                                    <small>密碼長度至少需要8碼</small>
                                </Form.Text>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="form-row">
                        <Col lg={6}>
                            <Form.Group controlId="profileForm.confirmation">
                                <Form.Label>確認新密碼</Form.Label>
                                <Form.Control
                                    type="password"
                                    required
                                    placeholder="請輸入新密碼"
                                    className={`form-control ${errorClass("confirmation")}`}
                                    {...formik.getFieldProps("confirmation")}
                                />
                                {errorFeedback("confirmation")}
                            </Form.Group>
                        </Col>
                    </Row>
                </fieldset>
                <Row>
                    <Col lg={6}>
                        <ButtonGroup className="d-flex justify-content-between btn-group-spacing">
                            <Nav.Link className="btn btn-outline-primary" role="button" href="/profile/dashboard">
                                返回
                            </Nav.Link>
                            <Button type="submit" variant="primary">
                                確定
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </Stack>
        </Form>
    );
};

export default LoginChangeForm;
