import React from "react";

export type CellValue = React.ReactNode | string;
export type RowValues = Array<CellValue>;
export interface DashboardGridProps {
    header: Array<string>;
    content: Array<RowValues>;
    isLoading?: boolean;
}

const renderContent = (values: Array<CellValue>, key: number) => {
    return (
        <tr key={key}>
            {values.map((value, key) => {
                return <td key={key}>{value}</td>;
            })}
        </tr>
    );
};

const DashboardGrid = ({ header, content, isLoading = false }: DashboardGridProps) => {
    const renderTBody = () => {
        if (isLoading) {
            return (
                <tbody>
                    <tr>
                        <td colSpan={header.length}>載入中...</td>
                    </tr>
                </tbody>
            );
        }
        if (content.length === 0) {
            return (
                <tbody>
                    <tr>
                        <td colSpan={header.length}>無資料</td>
                    </tr>
                </tbody>
            );
        }
        return <tbody>{content.map(renderContent)}</tbody>;
    };
    return (
        <div className="rounded-table-wrapper table-responsive">
            <table className="table dashboard-table">
                <thead className="thead-light">
                    <tr>
                        {header.map((value) => (
                            <th key={value} scope="col">
                                {value}
                            </th>
                        ))}
                    </tr>
                </thead>
                {renderTBody()}
            </table>
        </div>
    );
};

export default DashboardGrid;
