import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import RfaApi from "../RfaApi";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";
import { UserState } from "../components/types";
import SignupForm, { FormData } from "../components/signup/SignupForm";
import Actions from "../actions/actions";
import { useHistory } from "react-router-dom";

const Signup = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);
    const [formData, setFormData] = useState<FormData>();
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [inPeriod, setInPeriod] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [examTitle, setExamTitle] = useState<string>();

    const responseToForm = (serverForm: FormData) => {
        setFormData({ ...serverForm });
    };

    const onError = (error: any) => {
        setErrorContent(error?.response?.data ?? "出問題了");
        setErrorVisible(true);
    };

    const confirmForm = (formData: FormData) => {
        Actions.updateForm(formData);
        history.push("/profile/signupconfirm");
    };

    useEffect(() => {
        if (user && user.username) {
            const currentExam = user?.exam ?? null;
            rfaApi.InSignUpPeriod(currentExam).then((allowNext) => {
                console.log("allowSignup", allowNext);
                setInPeriod(allowNext);
                if (allowNext === true) {
                    setExamTitle(user?.exam?.name ?? "");
                    rfaApi.GetProfile().then(responseToForm).catch(onError);
                } else {
                    setErrorContent("非考試報名期間");
                    setErrorVisible(true);
                }
            });
        }
    }, [user, rfaApi]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={examTitle} />
                <FormContainer title={examTitle} className="dashboard-card">
                    <SignupForm formData={formData} onSubmit={confirmForm} />
                </FormContainer>
                <ErrorModal
                    content={errorContent}
                    show={isErrorVisible}
                    handleClose={() => {
                        setErrorVisible(false);
                        if (inPeriod === false) {
                            history.push("/profile/dashboard");
                        }
                    }}
                />
            </Col>
        </Row>
    );
};

export default Signup;
