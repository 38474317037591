import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import RfaApi from "../RfaApi";
import { FormikErrors } from "formik";
import Meta from "../components/Meta";
import LoginForm, { FormData } from "../components/LoginForm";
import FormContainer from "../components/FormContainer";
import { Col, Row } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import ErrorModal from "../components/form/ErrorModal";

const STR_LOGIN_ERROR = "帳號錯誤";
const STR_PASSWORD_ERROR = "密碼錯誤";
const GRC_ACTION = "login";

type FormDataWithToken = FormData & {
    token: string;
};

const LoginInner = () => {
    const pageTitle = "登入";
    const history = useHistory();
    const rfaApi = useMemo(() => new RfaApi(), []);
    const { executeRecaptcha } = useGoogleReCaptcha();
    const [formErrors, setFormErrors] = useState<FormikErrors<FormData>>({});
    const [formDataWithToken, setFormDataWithToken] = useState<FormDataWithToken | null>(null);
    const [isGRCError, setIsGRCError] = useState(false);

    const handleLogin = useCallback(
        (formData: FormData) => {
            if (!executeRecaptcha) {
                return;
            }
            executeRecaptcha(GRC_ACTION)
                .then((token) => {
                    setFormDataWithToken({
                        ...formData,
                        token,
                    });
                })
                .catch((error) => {
                    setIsGRCError(true);
                });
        },
        [executeRecaptcha]
    );

    const doLogin = useCallback(
        ({ login, password, token }: FormDataWithToken) => {
            rfaApi.UserLogin(login, password, token).then((result) => {
                if (result.status === "ok") {
                    rfaApi.checkUserLogin().then(() => {
                        if (window.location.pathname === "/login") {
                            history.push("/");
                        }
                    });
                } else {
                    setFormErrors({ login: STR_LOGIN_ERROR, password: STR_PASSWORD_ERROR });
                }
            });
        },
        [formDataWithToken]
    );

    useEffect(() => {
        if (formDataWithToken) {
            doLogin(formDataWithToken);
        }
    }, [formDataWithToken]);

    return (
        <Row className="justify-content-center">
            <Col sm={12} md={8} xl={4}>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="form-narrow">
                    <LoginForm formData={{ login: "", password: "" }} onSubmit={handleLogin} errors={formErrors} />
                    {/* <GoogleReCaptcha onVerify={handleVerify} /> */}
                </FormContainer>
                <ErrorModal content="reCAPTCHA載入失敗" show={isGRCError} handleClose={() => setIsGRCError(false)} />
            </Col>
        </Row>
    );
};

export default LoginInner;
