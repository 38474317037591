import React from "react";

interface RenewfeeType {
    id: number;
    paymentId: number;
    title: string;
    price: string;
    createdAt: string;
    updatedAt: string;
}

const InstructionRenewal = ({ renewfee }: { renewfee: RenewfeeType[] }) => {
    console.log(renewfee);

    return (
        <ol>
            <li>
                <span className="agreement-bold">換證程序</span>
                <p>
                    持證人需於每3年換證一次（以證書有效截止日期為主），過期未換證者不可對外宣稱持證資格且不可使用商標；換證申請時效最多保留3年，逾期未換證者將取消持證資格，須重新取得RFA測驗通過有效成績，方可再度參加認證程序。
                </p>
            </li>
            <li>
                <span className="agreement-bold">換證應備資格</span>
                <ol>
                    <li>持續進修時數(CPD)總時數，每年至少10小時，3年累計至少30小時。</li>
                    <li>
                        每年7小時為本會認可相關課程活動（選修），以及3小時為本會開設之持續教育課程（必修），持續教育課程認可說明及方式詳如附件。
                    </li>
                </ol>
            </li>
            <li>
                <span className="agreement-bold">換證費用</span>
                <ol>
                    {/* {renewfee.map((item: RenewfeeType) => (
                        <li key={item.id}>
                            {item.title}：{item.price}元
                        </li>
                    ))} */}
                    <li>1. 證書到期日前2個月內，2,500元。</li>
                    <li>2. 證書到期日後6個月內，3,000元。</li>
                    <li>3. 證書到期日後第7個月至三年內，4,000元。</li>
                </ol>
            </li>
            <li>
                <span className="agreement-bold">換證應備資料</span>
                <ol>
                    <li>
                        符合專業道德條件，申請人將被要求揭露是否有過去或現在的訴訟或其他受調查之案件，並須完成RFA職業道德及執業準則聲明書，同意遵守RFA職業道德及執業準則，並同意本會做徵信調查。
                    </li>
                    <li>遵守RFA商標使用授權規範。</li>
                    <li>
                        紙本證書寄發時間：本會於每月25日前完成換證文件審查作業，於每月30日前寄發已完成換證程序之新證書（如遇假日，則延後一天寄發）。
                    </li>
                </ol>
            </li>
        </ol>
    );
};

export default InstructionRenewal;
