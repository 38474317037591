import React from "react";
import { FormLabel, InputGroup } from "react-bootstrap";

export interface RadioGroupProps {
    name: string;
    values: Array<Record<string, string>>;
    label: string;
    errorClass: string;
    value: string;
    onChange: (value: string) => void;
    variant?: "inline" | "stacked";
}

/**
 * @param name
 * @param onChange a function which has one parameter - value
 * @param values
 * @param label
 * @param errorMessage
 * @returns {JSX.Element}
 * @constructor
 */
const RadioGroup = ({ name, onChange, values, value, label, errorClass, variant = "inline" }: RadioGroupProps) => {
    const radioLayoutClass = variant === "inline" ? "form-check form-check-inline" : "form-check";

    return (
        <>
            {label && <FormLabel>{label}</FormLabel>}
            <InputGroup>
                {values.map(({ id: optionValue, label }) => {
                    const id = `${name}-${optionValue}`;

                    return (
                        <div key={optionValue} className={radioLayoutClass}>
                            <input
                                className={`form-check-input ${errorClass}`}
                                type="radio"
                                name={name}
                                id={id}
                                value={optionValue}
                                checked={optionValue === value}
                                onChange={(e) => onChange(e.target.value)}
                            />
                            <label className="form-check-label" htmlFor={id}>
                                {label}
                            </label>
                        </div>
                    );
                })}
            </InputGroup>
        </>
    );
};

export default RadioGroup;
