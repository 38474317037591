import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import RfaApi from "../RfaApi";
import { newDate } from "../components/date/GregorianDate";
import { UserState } from "../components/types";
import ExamTicket, { Admission } from "../components/ExamTicket";
import { useParams } from "react-router-dom";
import Meta from "../components/Meta";
import ErrorModal from "../components/form/ErrorModal";
import FormContainer from "../components/FormContainer";
import { Col, Row } from "react-bootstrap";

const TicketAttendance = () => {
    const pageTitle = "應考證明";
    const rfaApi = useMemo(() => new RfaApi(), []);
    const user = useSelector((state: UserState) => state.user);
    const [admissionData, setAdmissionData] = useState<Admission>();
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const { id } = useParams<{ id?: string }>();

    useEffect(() => {
        // TODO: get admission ticket by id
        rfaApi
            .GetExamTicket(id)
            .then((result) => {
                console.log(result);
                const ticketData = {
                    title: result.title,
                    chName: result.chName,
                    ticketNumber: result.ticketNumber,
                    test_date: newDate(result.test_date),
                    stage2: {
                        startTime: newDate(result.stage2.startAt),
                        endTime: newDate(result.stage2.endAt),
                        isAttended: result.stage2.isAttended,
                        title: result.stage2.title,
                    },
                    area: result.area,
                    address: result.location,
                };
                let stage1Data;
                if (result.stage1) {
                    stage1Data = {
                        startTime: newDate(result.stage1.startAt),
                        endTime: newDate(result.stage1.endAt),
                        isAttended: result.stage1.isAttended,
                        title: result.stage1.title,
                    };
                }
                setAdmissionData({ ...ticketData, stage1: stage1Data });
            })
            .catch((err) => {
                console.log(err);
                try {
                    setErrorContent(err.response.data);
                    setErrorVisible(true);
                } catch {
                    console.error("Error response");
                    setErrorVisible(true);
                }
            });

        window.scrollTo(0, 0);
    }, [user, rfaApi]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    {admissionData?.ticketNumber ? (
                        <ExamTicket admission={admissionData} isPassed={true} />
                    ) : (
                        <h3 className="no-ticket">無應考證明資料</h3>
                    )}
                </FormContainer>
                <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
        </Row>
    );
};

export default TicketAttendance;
