import React from "react";
import { Link } from "react-router-dom";
import { getRocDate } from "../date/RocDate";
import SeatLimit from "./SeatLimit";
import EventDateTime from "./EventDateTime";
import "react-quill/dist/quill.snow.css";
import { ActivityData } from "../../type";
import { minsToHours } from "./helper";
import dayjs from "dayjs";

export interface EventProps {
    data: ActivityData;
}

interface EntryProps {
    label: string;
    value: React.ReactNode;
}

const Entry = ({ label, value }: EntryProps) => {
    return (
        <div className="entry">
            {label}
            {value}
        </div>
    );
};

interface ContentEntryProps {
    label: string;
    content: string;
}

const ContentEntry = ({ label, content }: ContentEntryProps) => {
    return (
        <div>
            <div className="entry">{label}</div>
            <div className="ql-snow">
                <div className="ql-editor p-0 w-100" dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
};

const EventButtons = ({ data }: EventProps) => {
    return (
        <div className="row">
            <div className="col">
                <Link className="btn btn-outline-primary w-100" role="button" to={"/profile/events"}>
                    返回
                </Link>
            </div>
            <div className="col">
                <Link className="btn btn-primary w-100" role="button" to={`/profile/event-registration/${data.id}`}>
                    立即報名
                </Link>
            </div>
        </div>
    );
};

const Event = ({ data }: EventProps) => {
    const {
        //shortDescription,
        name,
        activityDate,
        startTime,
        endTime,
        place,
        orgName,
        headCountLimit,
        mins,
        amount,
        startDate,
        endDate,
        activityContent,
        memo,
    } = data;
    const hours = minsToHours(mins);
    const mActivityDate = dayjs(activityDate).toDate();
    const mStartDate = dayjs(startDate).toDate();
    const mEndDate = dayjs(endDate).toDate();
    return (
        <div className="event-view">
            <h4 className="h4">{name}</h4>
            <Entry label={"開課單位："} value={orgName} />
            <Entry
                label={"活動日期："}
                value={<EventDateTime date={mActivityDate} startTime={startTime} endTime={endTime} />}
            />
            <Entry label={"人數限制："} value={<SeatLimit seatLimit={headCountLimit} />} />
            <Entry label={"開放報名日期："} value={getRocDate(mStartDate)} />
            <Entry label={"截止報名日期："} value={getRocDate(mEndDate)} />
            <Entry label={"活動地點："} value={place} />
            <Entry label={"教育時數："} value={`${hours}小時`} />
            <Entry label={"活動報名費用："} value={amount === 0 ? "免費" : amount} />
            <ContentEntry label="活動內容說明：" content={activityContent} />
            <ContentEntry label="備註說明：" content={memo} />
            <EventButtons data={data} />
        </div>
    );
};

export default Event;
