import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import MaterialIcon from "../material/MaterialIcon";
import { isNumeric } from "rxjs/internal-compatibility";

export interface NumberInputProps {
    className?: string;
    value?: number;
    step?: number;
    max?: number;
    min?: number;
    placeholder?: string;
    required?: boolean;
    size?: "lg" | "sm" | undefined;
    appendText?: string;
    onChange: (value: number | null) => void;
}

const NumberInput = (props: NumberInputProps) => {
    const { appendText, ...htmlProps } = props;
    const { value, onChange } = props;

    const onIncrement = () => {
        if (isNumeric(value)) {
            onChange(value + 1);
        } else {
            onChange(1);
        }
    };
    const onDecrement = () => {
        if (isNumeric(value)) {
            onChange(value - 1);
        } else {
            onChange(-1);
        }
    };
    const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const stringValue = event.target.value;
        const numericValue = parseFloat(stringValue);
        if (isNumeric(numericValue)) {
            onChange(numericValue);
        } else {
            onChange(null);
        }
    };

    return (
        <div className="input-number-wrapper">
            <Form.Control type="number" {...htmlProps} value={value ?? ""} onChange={onChangeHandler} />
            <div className="input-group-append">
                {appendText && <div className="input-group-text">{appendText}</div>}
                <div className="input-number-controls">
                    <Button onClick={onDecrement}>
                        <MaterialIcon icon="remove_circle_outline_outlined" />
                    </Button>
                    <Button onClick={onIncrement}>
                        <MaterialIcon icon="add_circle_outline_outlined" />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NumberInput;
