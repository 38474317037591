import React from "react";
import { formatDate } from "../date/GregorianDate";
import { Link } from "react-router-dom";

export const PDU_STATUS_PROCESSING = "審核中";
export const PDU_STATUS_PASSED = "審核通過";

const statusCodes: { [key: number]: string } = {
    0: PDU_STATUS_PROCESSING,
    10: PDU_STATUS_PASSED,
};

export interface PduResult {
    id: string;
    courseName: string;
    updatedAt: Date;
    hours: string;
    status: number;
}

export interface PduStatusGridProps {
    pduResults: Array<PduResult>;
}

const PduStatusGrid = ({ pduResults }: PduStatusGridProps) => {
    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col">課程/活動名稱</th>
                        <th scope="col">申請日期</th>
                        <th scope="col">申請時數</th>
                        <th scope="col">狀態</th>
                        <th scope="col">修改</th>
                    </tr>
                </thead>
                <tbody>
                    {pduResults && pduResults.length > 0 ? (
                        pduResults.map(({ id, courseName, updatedAt, hours, status }, index) => (
                            <tr key={"pdu-" + index}>
                                <td>{courseName}</td>
                                <td>{formatDate(updatedAt)}</td>
                                <td>{hours}</td>
                                <td>{statusCodes[status]}</td>
                                <td>
                                    {status === 0 ? (
                                        <Link to={`/profile/pdu-revise/${id}`} className="btn btn-primary">
                                            {" "}
                                            修改{" "}
                                        </Link>
                                    ) : (
                                        "-"
                                    )}
                                </td>
                            </tr>
                        ))
                    ) : (
                        <></>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default PduStatusGrid;
