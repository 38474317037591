import React from "react";

const formatTimeZero = (timePart: number) => {
    if (timePart < 10) {
        return `0${timePart}`;
    }

    return timePart;
};

const formatTime = (dateTime: Date) => {
    const hours = formatTimeZero(dateTime.getHours());
    const minutes = formatTimeZero(dateTime.getMinutes());

    return `${hours} : ${minutes}`;
};

export interface ExamDetails {
    startTime: Date;
    endTime: Date;
    isAttended: number;
    title: string;
}

export interface ExamTimeProps {
    label: string;
    details: ExamDetails;
}
// isCfpAfpQualified
const ExamTime = ({ label, details: { startTime, endTime, isAttended } }: ExamTimeProps) => {
    return (
        <div className="exam-time">
            <span>{label}</span>
            <span>
                {formatTime(startTime)} - {formatTime(endTime)}
                {isAttended === null ? "" : ""}
                {isAttended === 1 ? " 出席" : ""}
                {isAttended === 0 ? " 缺考" : ""}
            </span>
        </div>
    );
};

export default ExamTime;
