import React from "react";
import GenericSelect from "./GenericSelect";

const options = [
    {
        id: 0,
        label: "否",
    },
    {
        id: 1,
        label: "是",
    },
];

export interface YesNoSelectProps {
    name: string;
    errorClass: string;
    label: string;
    value: number;
    onChange: (value?: string) => void;
}

const YesNoSelect = ({ name, errorClass, label, value, onChange }: YesNoSelectProps) => {
    return (
        <GenericSelect
            name={name}
            errorClass={errorClass}
            label={label}
            value={value}
            options={options}
            onChange={onChange}
        />
    );
};

export default YesNoSelect;
