import { useEffect, useMemo, useState } from "react";
import RfaApi from "../../RfaApi";
import { Option } from "../formik/GenericSelect";

type SelectOption = Option<string, string>;

export interface Company {
    id: string;
    name: string;
}

const useCompanies = (companyType: string) => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const [companies, setCompanies] = useState<Array<SelectOption>>([]);

    const fetchCompanies = (companyType: string) => {
        rfaApi.GetCompanyByType(companyType).then((companyData: Array<Company>) => {
            const companiesList = companyData.map(({ id, name }) => {
                return {
                    id: name,
                    label: name,
                };
            });
            setCompanies([...companiesList]);
        });
    };

    useEffect(() => {
        if (companyType) {
            fetchCompanies(companyType);
        }
    }, [companyType]);

    return companies;
};

export default useCompanies;
