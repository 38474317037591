import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Accordion, ListGroup, Nav, Navbar } from "react-bootstrap";
import menu_rfa from "../data/menu_rfa";
import "../css/menu-rfa.sass";
import { findActivePath } from "./url/Path";
import MaterialIcon from "../components/material/MaterialIcon";

const renderMenuGroup = ({ id, label, icon, children }, activePath) => {
    return (
        <Accordion.Item eventKey={id} key={id}>
            <Accordion.Header>
                <MaterialIcon icon={icon} size={21} color={"#FFF"} className="menu-icon" />
                <span className="menu-label">{label}</span>
                <MaterialIcon icon="keyboard_arrow_up" color="#FFF" size={21} className="menu-arrow" />
            </Accordion.Header>
            <Accordion.Body>{children.map((child) => renderMenuTree(child, activePath))}</Accordion.Body>
        </Accordion.Item>
    );
};

const renderMenuItem = ({ href, label, activeHrefs }, activePath) => {
    return (
        <ListGroup.Item as="a" key={href} action variant="light" href={href} active={href === activePath}>
            {label}
        </ListGroup.Item>
    );
};

const renderMenuTree = (entry, activePath) => {
    if (entry.children) {
        return renderMenuGroup(entry, activePath);
    }
    if (entry.visible !== false) {
        return renderMenuItem(entry, activePath);
    }
};

const UserInfo = ({ user }) => {
    const { name } = user;

    return (
        <div className="user-info">
            <MaterialIcon icon="account_circle" color="#0E4E96" size={26} />
            <span className="user-name">{name}</span>
        </div>
    );
};

const RfaNavigation = ({ user }) => {
    const [activeKey, setActiveKey] = useState();
    const [activePath, setActivePath] = useState();
    const pathCallback = useCallback(() => {
        const currentPath = window.location.pathname;
        const { group, path } = findActivePath(menu_rfa(user), currentPath);

        setActiveKey(group);
        setActivePath(path);
    }, [window.location.pathname, user]);

    useEffect(() => {
        pathCallback();
    }, []);

    if (activeKey === undefined) {
        return <></>;
    }

    return (
        <Navbar collapseOnSelect expand="lg" className="menu-rfa">
            <div className="menu-rfa-header">
                <UserInfo user={user} />
                <Navbar.Toggle aria-controls="responsive-navbar-nav">
                    <span className="toggler-label">選單</span>
                    <MaterialIcon icon="close" color="#0E4E96" size={30} />
                </Navbar.Toggle>
            </div>
            <Navbar.Collapse id="responsive-navbar-nav">
                <div className="menu-rfa-content-wrapper">
                    <Nav className="flex-column">
                        <Accordion defaultActiveKey={activeKey} flush>
                            {menu_rfa(user).map((child) => renderMenuTree(child, activePath))}
                        </Accordion>
                    </Nav>
                </div>
            </Navbar.Collapse>
        </Navbar>
    );
};

const MenuRfa = ({ user }) => {
    return <RfaNavigation user={user} />;
};

export default withRouter(MenuRfa);
