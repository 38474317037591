import React from "react";
import Nav from "react-bootstrap/Nav";

export interface PlanProps {
    title: string;
    price: number;
    discountNote?: string;
    link: string;
}

const Plan = ({ title, price, discountNote, link }: PlanProps) => {
    return (
        <>
            <div className="plan-title">{title}</div>
            <div className="plan-price">
                {price}
                <small>元</small>
                {discountNote ? <div className="plan-discount-note">{discountNote}</div> : <></>}
            </div>
            <div>
                <Nav.Link className="btn btn-primary py-2" role="button" href={link}>
                    選擇方案
                </Nav.Link>
            </div>
        </>
    );
};

export default Plan;
