import React from "react";
import Card from "react-bootstrap/Card";
import Stack from "react-bootstrap/Stack";
import MaterialIcon from "../components/material/MaterialIcon";

export interface FormFailureProps {
    className?: string;
    children: React.ReactNode;
}

const FormFailure = ({ className, children }: FormFailureProps) => {
    return (
        <Card className={`form-narrow ${className}`}>
            <Stack gap={5} className="d-flex justify-content-between align-items-center">
                <MaterialIcon icon="cancel" size={100} color="#f33223" />
                {children}
            </Stack>
        </Card>
    );
};

export default FormFailure;
