import React from "react";

export interface DependentFieldProps {
    isVisible: boolean;
    children: React.ReactElement;
}

const DependentField = ({ isVisible, children }: DependentFieldProps) => {
    return isVisible ? children : <></>;
};

export default DependentField;
