import React from "react";
import { PlanDescriptionProps } from "./PlanDescriptionProps";
import PlanIcon from "./PlanIcon";

const PlanDescriptionMobile = ({ title, description, isFree }: PlanDescriptionProps) => {
    return (
        <tr className="subscription-plan-description">
            <td colSpan={3} className="description">
                <p>{title}</p>
                <small>{description}</small>
            </td>
            <td>
                <PlanIcon isAvailable={isFree} />
            </td>
            <td>
                <PlanIcon isAvailable={true} />
            </td>
        </tr>
    );
};

export default PlanDescriptionMobile;
