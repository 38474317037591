import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "react-bootstrap";

export interface CertificateLogoProps {
    logoUrl: string;
}

const CertificateLogo = ({ logoUrl }: CertificateLogoProps) => {
    return (
        <Stack gap={5} className="logo-download">
            <div className="row mb-5">
                <div className="col text-center">
                    <img src={logoUrl} alt="rfa-logo" width="300" className="logo-img" />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <Link
                        type="button"
                        className="btn btn-primary w-100"
                        href={logoUrl}
                        target="_blank"
                        to={"../rfa-logo.png"}
                    >
                        下載
                    </Link>
                </div>
            </div>
        </Stack>
    );
};

export default CertificateLogo;
