import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { FormDataEntry } from "../form/FormDataConfirm";
import { FormData } from "./EventRegisterForm";
import { ButtonGroup, Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FormDataView from "../form/FormDataView";
import { ActivityData } from "../../type";
import { getRocDate } from "../date/RocDate";

export interface EventRegisterFormConfirmProps {
    formData: FormData;
    event: ActivityData;
    onPrevious: () => void;
    onNext: () => void;
}

const EventRegisterFormConfirm = ({ event, formData, onPrevious, onNext }: EventRegisterFormConfirmProps) => {
    const { activityDate, startTime, endTime } = event;
    const mEventData = dayjs(activityDate).toDate();
    const fullEventTime = `${getRocDate(mEventData)} ${startTime}-${endTime}`;
    const [confirmEntries, setConfirmEntries] = useState<Array<FormDataEntry>>([]);
    useEffect(() => {
        const entries: Array<FormDataEntry> = [];
        entries.push({ label: "活動名稱", value: event.name });
        entries.push({ label: "活動時間", value: fullEventTime });
        entries.push({ label: "中文姓名", value: formData.chName });
        entries.push({ label: "行動電話", value: formData.mobile });
        entries.push({ label: "電子郵件信箱", value: formData.email });

        setConfirmEntries(entries);
    }, [formData]);

    return (
        <div className="form-confirm">
            <Row>
                <Col>
                    <FormDataView entries={confirmEntries} className="gray-labels" />
                </Col>
            </Row>
            <Row>
                <Col>
                    <ButtonGroup className="d-flex justify-content-between btn-group-spacing">
                        <Button variant="outline-primary" role="button" onClick={onPrevious}>
                            修改個人資料
                        </Button>
                        <Button type="submit" variant="primary" onClick={onNext}>
                            下一步
                        </Button>
                    </ButtonGroup>
                </Col>
            </Row>
        </div>
    );
};

export default EventRegisterFormConfirm;
