import React from "react";
import { dark, light, mdInactive, sizes } from "./config/mappings";
import { isNumeric } from "rxjs/internal-compatibility";

interface MaterialIconProps {
    icon: string;
    size?: string | number;
    invert?: boolean;
    inactive?: boolean;
    color?: string;
    className?: string;
    style?: string;
}

/**
 * Override for faulty MaterialIcons component
 */
const MaterialIcon = ({ size, invert, inactive, style, className, color, icon }: MaterialIconProps) => {
    const processProps = () => {
        let sizeMapped;
        if (isNumeric(size)) {
            sizeMapped = size;
        } else if (size) {
            sizeMapped = sizes[size] ?? sizes["small"];
        } else {
            sizeMapped = sizes["small"];
        }
        const defaultColor = invert && "invert" ? light : dark;
        const inactiveColor = inactive && "inactive" ? mdInactive : "";
        const propStyle = style || {};
        const styleOverride = Object.assign(propStyle, { color: color ? color : "", fontSize: sizeMapped });
        const clsName = `material-icons ${sizeMapped} ${defaultColor} ${inactiveColor} ${className ?? ""}`;

        return {
            icon,
            styleOverride,
            clsName,
        };
    };

    const { styleOverride, clsName } = processProps();

    return (
        <i className={clsName} style={styleOverride}>
            {icon}
        </i>
    );
};

export default MaterialIcon;
