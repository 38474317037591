import React, { useEffect, useState } from "react";
import { FormikErrors, FormikHelpers, useFormik } from "formik";
import * as Yup from "yup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";
import { formikErrorFeedback } from "../components/formik/Form";
import { STR_REQUIRED_FIELD } from "../components/formik/Strings";

const validationSchema = Yup.object({
    login: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
    password: Yup.string().required(STR_REQUIRED_FIELD).typeError(STR_REQUIRED_FIELD),
});

export interface FormData {
    login: string;
    password: string;
}

type FormKeys = keyof FormData;

export interface LoginFormProps {
    formData: FormData;
    onSubmit: (values: FormData, formikHelpers: FormikHelpers<FormData>) => void;
    errors?: FormikErrors<FormData>;
}

const LoginForm = ({ formData, onSubmit, errors }: LoginFormProps) => {
    const formik = useFormik({
        initialValues: {
            login: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit,
    });

    useEffect(() => {
        formik.setValues({ ...formik.values, ...formData });
    }, [formData]);

    useEffect(() => {
        errors &&
            formik.setErrors({
                ...formik.errors,
                ...errors,
            });
    }, [errors]);

    const errorFeedback = (field: string) => {
        return formikErrorFeedback(formik, field);
    };
    const errorClass = (field: FormKeys) => (formik.touched[field] && formik.errors[field] ? "is-invalid" : "");

    return (
        <Form onSubmit={formik.handleSubmit}>
            <Stack className="form-content">
                <fieldset>
                    <Form.Group controlId="loginForm.login">
                        <Form.Label>課程結業證號 / 身分證字號</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="請輸入課程結業證號或身分證字號"
                            className={`form-control ${errorClass("login")}`}
                            {...formik.getFieldProps("login")}
                        />
                        {errorFeedback("login")}
                        <Form.Text id="loginHelp" className="text-muted">
                            <small>*第一次登入請輸入課程結業證號</small>
                        </Form.Text>
                    </Form.Group>
                    <Form.Group controlId="loginForm.password">
                        <Form.Label>密碼</Form.Label>
                        <Form.Control
                            type="password"
                            placeholder="請輸入密碼"
                            className={`form-control ${errorClass("password")}`}
                            {...formik.getFieldProps("password")}
                        />
                        {errorFeedback("password")}
                        <Form.Text id="passwordHelp" className="text-muted d-flex justify-content-between">
                            <small>*預設為手機號碼共10碼</small>
                            <small>
                                <a href="/reset">忘記密碼?</a>
                            </small>
                        </Form.Text>
                    </Form.Group>
                </fieldset>
                <fieldset>
                    <Form.Group>
                        <Form.Check type="checkbox" id="rememberMe">
                            <Form.Check.Input type="checkbox" />
                            <Form.Check.Label>
                                <small>記住我的帳號密碼</small>
                            </Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </fieldset>
                <div className="fs-5 text-danger">
                    <ul>
                        <li>RFA專業理財顧問立即登入訂閱「好命退休計算機RFA版」，即享免費試用30天！</li>{" "}
                        <li>提醒您，訂閱前，請至【個人資料-照片上傳】處，上傳個人照片，打造您個人顧問品牌形象！</li>
                    </ul>
                </div>
                {/* <fieldset>
                    <Form.Group>
                        <Form.Check type="checkbox" id="noAdverts">
                            <Form.Check.Input type="checkbox" {...formik.getFieldProps("noAdverts")} />
                            <Form.Check.Label>
                                <p className="mb-0">不再顯示廣告</p>
                            </Form.Check.Label>
                        </Form.Check>
                    </Form.Group>
                </fieldset> */}
                <Button type="submit" variant="primary">
                    登入
                </Button>
            </Stack>
        </Form>
    );
};

export default LoginForm;
