import React, { useMemo, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import RfaApi from "../RfaApi";
import { useHistory } from "react-router-dom";
import LoginChangeForm, { FormData } from "../components/LoginChangeForm";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import SuccessModal from "../components/form/SuccessModal";
import ErrorModal from "../components/form/ErrorModal";

const LoginChange = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const user = useSelector((state: UserState) => state.user);
    const pageTitle = "密碼修改";
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);

    const submitChangePassword = ({ pass, newpass }: FormData) => {
        rfaApi.changePassword(pass, newpass).then((result) => {
            if (result.confirm === true) {
                setSuccessVisible(true);
            } else {
                setErrorVisible(true);
            }
        });
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <div className="form-subtitle">
                        目前登入&nbsp;{user.username}&nbsp;{user.name}
                    </div>
                    <LoginChangeForm onSubmit={submitChangePassword} />
                </FormContainer>
                <SuccessModal
                    content="密碼已更新"
                    show={isSuccessVisible}
                    handleClose={() => {
                        setSuccessVisible(false);
                        history.push("/profile/dashboard");
                    }}
                />
                <ErrorModal content={"密碼更新失敗"} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
            </Col>
        </Row>
    );
};

export default LoginChange;
