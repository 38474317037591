import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import DashboardGrid from "../components/DashboardGrid";
import { Link } from "react-router-dom";
import RfaApi from "../RfaApi";
import Swal from "sweetalert2";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";
enum PaymentStepEnum {
    FAILED = "-1",
    PENDING = "0",
    PAID = "1",
}
const PaymentResult: { [key: string]: string } = {
    "0": "-",
    "1": "已付款",
    "2": "待付款",
    "10100073": "待繳費",
    "10100058": "付款失敗",
    "10100248": "拒絕交易",
    "10100249": "交易失敗",
    "10100251": "卡片過期",
    "10100282": "3D授權未完成",
};
//mapping to api response fields
interface GridRow {
    transactionId: string;
    title: string;
    transactionAt: string; //交易時間
    amount: string; //金額
    status: string; //交易狀態
    payStep: PaymentStepEnum; //付款狀態
}
const CloumnAmount = ({ amount }: GridRow) => {
    return <>NT$ {amount}</>;
};
const PaymentStatus = ({ payStep, status }: GridRow) => {
    if (payStep === PaymentStepEnum.PENDING) {
        return <>未完成</>;
    }
    if (payStep === PaymentStepEnum.FAILED) {
        return <>取消</>;
    }
    if (payStep === PaymentStepEnum.PAID) {
        return <>已完成</>;
    }
    return <></>;
};
const PaymentOptions = ({ transactionId, payStep, status }: GridRow) => {
    if (payStep === PaymentStepEnum.PENDING) {
        return (
            <>
                <Link className="btn btn-primary" to={`/profile/paymentlog/${transactionId}`}>
                    待付款
                </Link>{" "}
                &nbsp;
                <Link
                    className="btn btn-outline-primary"
                    onClick={() => {
                        Swal.fire({
                            icon: "warning",
                            title: "確定取消此次付款\n並重新繳費？",
                            showCancelButton: true,
                            confirmButtonText: "是",
                            cancelButtonText: "再考慮",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                console.log(`signup abort confirmed.`);
                                const resetApi = new RfaApi();
                                resetApi
                                    .CancelPayment({ transactionId: transactionId })
                                    .then((res_data) => {
                                        //console.log("CancelPayment", res_data);
                                        if (res_data) {
                                            //console.log(`CancelPayment: ${transactionId}`);
                                            Swal.fire({ icon: "error", title: res_data }).then(() => {
                                                window.location.reload();
                                            });
                                        } else {
                                            Swal.fire({ icon: "error", title: "取消付款失敗" });
                                        }
                                    })
                                    .catch((err) => {
                                        //console.log(err);
                                        Swal.fire({ icon: "error", title: err.response.data });
                                    });
                            }
                        });
                    }}
                    to={`/profile/certificate-status`}
                >
                    重新繳費
                </Link>
            </>
        );
    }
    if (payStep === PaymentStepEnum.FAILED) {
        return (
            <>
                <Link className="btn btn-outline-primary" to={`/profile/certificate-application`}>
                    重新繳費
                </Link>
            </>
        );
    }
    return <>已付款</>;
};

const CertificateStatus = () => {
    const pageTitle = "認證申請/換證狀態";

    const rfaApi = new RfaApi();
    const userInfo = useSelector((state: UserState) => state.user);
    const [paymentLogs, setPaymentLogs] = useState<Array<GridRow>>([]);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const parseDataRow = (gridRow: GridRow, index: number) => {
        const { transactionId, title, transactionAt, status } = gridRow;
        const transactionDate = dayjs(transactionAt).format("YYYY/MM/DD");
        if (index === 0) {
            return [
                title, //項目
                transactionDate, //申請日期
                <CloumnAmount key={transactionId} {...gridRow} />, //費用
                <PaymentStatus key={transactionId} {...gridRow} />, //付款狀態
                <PaymentOptions key={transactionId} {...gridRow} />, //付款操作
            ];
        } else {
            return [
                title, //項目
                transactionDate, //申請日期
                <CloumnAmount key={transactionId} {...gridRow} />, //費用
                <PaymentStatus key={transactionId} {...gridRow} />, //付款狀態
                <>{PaymentResult[status]}</>, //付款操作
            ];
        }
    };
    useEffect(() => {
        if (userInfo && userInfo.username) {
            rfaApi
                .GetCertificatePayment()
                .then((res_data: any) => {
                    console.log("GetCertificatePayment", res_data);
                    if (res_data) {
                        setPaymentLogs(res_data);
                    } else {
                        throw new Error("Invalid payment response");
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    }, [userInfo]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <DashboardGrid
                        header={["項目", "申請日期", "費用", "付款狀態", "付款操作"]}
                        content={paymentLogs.map(parseDataRow)}
                    />
                </FormContainer>
            </Col>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
};

export default CertificateStatus;
// import React from "react";
// import { Col, Row } from "react-bootstrap";
// import Meta from "../components/Meta";
// import FormContainer from "../components/FormContainer";
// import DashboardGrid from "../components/DashboardGrid";
// import { Link } from "react-router-dom";

// enum PaymentStatusEnum {
//     PENDING,
//     PAID,
// }

// interface GridRow {
//     id: number;
//     label: string;
//     date: string;
//     price: string;
//     applicationStatus: string;
//     paymentStatus: PaymentStatusEnum;
// }

// const mockData: Array<GridRow> = [
//     {
//         id: 1,
//         label: "111年第1次退休理財規劃顧問(RFA)測驗成績複查申請",
//         date: "2022/03/01",
//         price: "NT$200",
//         applicationStatus: "審核中",
//         paymentStatus: PaymentStatusEnum.PAID,
//     },
//     {
//         id: 2,
//         label: "111年第1次退休理財規劃顧問(RFA)測驗成績複查申請",
//         date: "2022/03/01",
//         price: "NT$200",
//         applicationStatus: "已收到認證申請",
//         paymentStatus: PaymentStatusEnum.PAID,
//     },
//     {
//         id: 3,
//         label: "111年第1次退休理財規劃顧問(RFA)測驗成績複查申請",
//         date: "2022/03/01",
//         price: "NT$200",
//         applicationStatus: "處理中",
//         paymentStatus: PaymentStatusEnum.PENDING,
//     },
// ];

// const PaymentStatus = ({ id, paymentStatus }: GridRow) => {
//     if (paymentStatus === PaymentStatusEnum.PENDING) {
//         // TODO: get real link
//         return (
//             <Link className="btn btn-primary" to={`/profile/certificate-payment-status?id=${id}`}>
//                 待付款
//             </Link>
//         );
//     }

//     return <>已付款</>;
// };

// const PaymentResult = ({ id, paymentStatus }: GridRow) => {
//     if (paymentStatus === PaymentStatusEnum.PENDING) {
//         // TODO: get real link
//         return (
//             <Link className="btn btn-outline-primary" to={`/profile/certificate-payment-result?id=${id}`}>
//                 重新繳費
//             </Link>
//         );
//     }

//     return <>-</>;
// };

// const CertificateStatus = () => {
//     const pageTitle = "認證申請/換證收據";

//     const parseDataRow = (gridRow: GridRow) => {
//         const { id, label, date, price, applicationStatus } = gridRow;

//         return [
//             label,
//             date,
//             price,
//             applicationStatus,
//             <PaymentStatus key={id} {...gridRow} />,
//             <PaymentResult key={id} {...gridRow} />,
//         ];
//     };

//     return (
//         <Row className="justify-content-center">
//             <Col>
//                 <Meta title={pageTitle} />
//                 <FormContainer title={pageTitle} className="dashboard-card">
//                     {/*TODO: switch layout with preview of the receipt details*/}
//                     <DashboardGrid
//                         header={["項目", "申請日期", "費用", "申請狀態", "付款狀態", "付款操作"]}
//                         content={mockData.map(parseDataRow)}
//                     />
//                 </FormContainer>
//             </Col>
//         </Row>
//     );
// };

// export default CertificateStatus;
