import React, { useState } from "react";
import CheckboxAgreement from "./CheckboxAgreement";

export interface AgreementScrollProps {
    // TODO: update type
    formik: any;
    children: React.ReactNode;
    name: string;
}

const AgreementScroll = ({ formik, children, name }: AgreementScrollProps) => {
    const [checkboxDisabled, setCheckboxDisabled] = useState(true);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const element = e.currentTarget;

        const scrollDistance = element.scrollHeight;
        const scrollLimit = Math.ceil(element.offsetHeight + element.scrollTop);

        const isDisabled = scrollDistance > scrollLimit;
        setCheckboxDisabled(isDisabled);

        return true;
    };

    return (
        <div>
            <div className="agreement-content-wrapper">
                <div className="agreement-content-scroll" onScroll={handleScroll}>
                    {children}
                </div>
            </div>
            <CheckboxAgreement formik={formik} name={name} disabled={checkboxDisabled} />
        </div>
    );
};

export default AgreementScroll;
