import React from "react";
import AgreementScroll from "./AgreementScroll";
import { UserProfile } from "./AgreementCorrectInformation";

export interface AgreementFinancialAdvisorProps {
    // TODO: update types
    formik: any;
    userProfile: UserProfile;
}

const AgreementFinancialAdvisor = ({ formik, userProfile }: AgreementFinancialAdvisorProps) => {
    const { chName } = userProfile;

    return (
        <>
            <AgreementScroll formik={formik} name="agreementFinancialAdvisor">
                <p>二、同意接受中華民國退休基金協會有關退休理財規劃顧問各項要求之聲明</p>
                <p>立聲明書人 {chName} 充分了解並同意接受下列中華民國退休基金協會有關退休理財規劃顧問(RFA)各項要求：</p>
                <ol>
                    <li>
                        立聲明書人了解RFA，退休理財規劃顧問(Retirement Financial Advisor, RFA)及商標
                        <span className="rfa-icon" />
                        之使用權，將在立聲明書人通過中華民國退休基金協會認證程序後，3年內有效。
                        在3年有效期間屆滿前，若立聲明書人無法依規定完成證照更新手續，上述之商標
                        <span className="rfa-icon" />
                        使用權即行終止。立聲明書人同意立即停止使用RFA相關商標
                        <span className="rfa-icon" />。
                        立聲明書人並充分了解若無法維持受證狀態，中華民國退休基金協會有權終止立聲明書人對於相關RFA商標
                        <span className="rfa-icon" />
                        之使用權利。
                    </li>
                    <li>
                        立聲明書人已經詳細閱讀，並確知必須切實遵行「中華民國退休基金協會退休理財規劃顧問職業規範」。
                        立聲明書人同時承諾，日後中華民國退休基金協會修正該項職業規範及其相關執業準則內容時，亦將嚴格遵守該最新修正之職業規範及其相關執業準則。
                    </li>
                    <li>
                        立聲明書人同意並了解中華民國退休基金協會有權對於立聲明書人進行徵信調查。
                        若中華民國退休基金協會發現立聲明書人未能確實依「中華民國退休基金協會理退休理財規劃顧問職業規範」執行各項業務時，中華民國退休基金協會得隨時撤銷或停止立聲明書人認證資格及RFA商標
                        <span className="rfa-icon" />
                        之使用權，立聲明書人不得異議。
                    </li>
                    <li>
                        立聲明書人同意在
                        RFA認證程序完成後，不得要求中華民國退休基金協會協助分發至金融機構服務，或向各金融機構推介。
                    </li>
                </ol>
                <p>
                    ※維持授證狀態：在認證有效期間內未違反認證規定，故未被撤銷或停止認證資格。
                    立聲明書人鄭重聲明，已經詳細閱讀並了解本聲明書所列各項要求，並同意確實遵守。
                </p>
            </AgreementScroll>
        </>
    );
};

export default AgreementFinancialAdvisor;
