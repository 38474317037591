import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import RfaApi from "../RfaApi";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";
import { UserState } from "../components/types";
import SignupForm, { FormData } from "../components/signup/SignupForm";
import Actions from "../actions/actions";
import { useHistory } from "react-router-dom";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

const Signup = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const userInfo = useSelector((state: UserState) => state.user);
    const [formData, setFormData] = useState<FormData>();
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [inPeriod, setInPeriod] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [examTitle, setExamTitle] = useState<string>();
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const responseToForm = (serverForm: FormData) => {
        setFormData({ ...serverForm });
    };

    const onError = (error: any) => {
        setErrorContent(error?.response?.data ?? "出問題了");
        setErrorVisible(true);
    };

    const confirmForm = (formData: FormData) => {
        Actions.updateForm(formData);
        history.push("/profile/signupconfirm");
    };

    useEffect(() => {
        if (userInfo && userInfo.username) {
            const currentExam = userInfo?.exam ?? null;
            rfaApi.InSignUpPeriod(currentExam).then((allowNext) => {
                console.log("allowSignup", allowNext);
                setInPeriod(allowNext);
                if (allowNext === true) {
                    setExamTitle(userInfo?.exam?.name ?? "");
                    rfaApi.GetProfile().then(responseToForm).catch(onError);
                    // 有考試再跳出彈窗
                    // const advert = localStorage.getItem("noAdverts") as string;
                    // const advertObj = JSON.parse(advert);
                    // if (advertObj && advertObj.date === dayjs().format("YYYY/MM/DD") && advertObj.noAdverts) {
                    //     setShow(false);
                    // } else {
                    //     setShow(true);
                    // }
                } else {
                    setErrorContent("非考試報名期間");
                    setErrorVisible(true);
                }
            });
        }
    }, [userInfo, rfaApi]);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <>
            <Row className="justify-content-center">
                <Col>
                    <Meta title={examTitle} />
                    <FormContainer title={examTitle} className="dashboard-card">
                        <SignupForm formData={formData} onSubmit={confirmForm} />
                    </FormContainer>
                    <ErrorModal
                        content={errorContent}
                        show={isErrorVisible}
                        handleClose={() => {
                            setErrorVisible(false);
                            if (inPeriod === false) {
                                history.push("/profile/dashboard");
                            }
                        }}
                    />
                </Col>
            </Row>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </>
    );
};

export default Signup;
