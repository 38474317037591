// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../static/stamps/exam-proof.png";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".exam-ticket-stamp-wrapper {\n  position: relative;\n}\n\n.exam-ticket-stamp-field {\n  padding: 0;\n  width: 262px;\n  height: 165px;\n  position: absolute;\n  bottom: 10%;\n  right: 2%;\n}\n.exam-ticket-stamp-field .stamp {\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center center;\n  background-size: cover;\n  width: 279px;\n  height: 125px;\n}", "",{"version":3,"sources":["webpack://src/css/exam-ticket.sass"],"names":[],"mappings":"AAIA;EACE,kBAAA;AAHF;;AAKA;EACE,UAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,WAAA;EACA,SAAA;AAFF;AAIE;EACE,2EAAA;EACA,sBAAA;EACA,YAAA;EACA,aAAA;AAFJ","sourcesContent":["$stamp-height: 128px\n$stamp-offset: -55px\n$stamp-margin: divide($stamp-height, 2) + $stamp-offset\n\n.exam-ticket-stamp-wrapper\n  position: relative\n\n.exam-ticket-stamp-field\n  padding: 0\n  width: 262px\n  height: 165px\n  position: absolute\n  bottom: 10%\n  right: 2%\n\n  .stamp\n    background: url(\"../static/stamps/exam-proof.png\") no-repeat center center\n    background-size: cover\n    width: 279px\n    height: 125px\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
