import React from "react";
import receiptImage2023 from "../static/receipt/blank_receipt.2023.png";
import receiptImage2021 from "../static/receipt/blank_receipt.2021.png";

interface PaymentLog {
    Year: string;
    logDate: string;
    serialnumber: string;
    printCount: number;
    chName: string;
    vatTitle: string;
    vatNumber: string;
    description: string;
    Amount: string;
    version: string;
}

function getReceiptImage({ version }: PaymentLog) {
    console.log("receipt version:", version);

    if (version === "2023") {
        return receiptImage2023; //自2023-9-1起 追加收據圖章多版本共存機制(每次會長改選以手動切換)
    }

    return receiptImage2021; //原始版本收據章
}

export interface ReceiptNewProps {
    log: PaymentLog;
}

const Receipt = React.forwardRef(({ log }: ReceiptNewProps, ref: React.LegacyRef<HTMLDivElement>) => {
    return (
        <div id="receipt-container" ref={ref} style={{ position: "relative", width: 894, height: 670 }}>
            <img width="894" height="628" src={getReceiptImage(log)} alt="receipt" />
            <p style={{ position: "absolute", top: 132, left: 136 }} className="ft11">
                {log.serialnumber}
            </p>
            <p style={{ position: "absolute", top: 200, left: 165 }} className="ft12">
                {log.chName}
            </p>
            <p style={{ position: "absolute", top: 248, left: 165 }} className="ft12">
                {log.vatTitle}
            </p>
            <p style={{ position: "absolute", top: 248, left: 600 }} className="ft14">
                {log.vatNumber}
            </p>
            <p
                style={{
                    position: "absolute",
                    top: 300,
                    left: 165,
                    whiteSpace: "pre-line",
                    lineHeight: 1.5,
                }}
                className="ft12"
            >
                {log.description}
            </p>
            <p style={{ position: "absolute", top: 444, left: 165 }} className="ft12">
                新臺幣{log.Amount}元整
            </p>
            <p style={{ position: "absolute", top: 490, left: 744 }} className="ft12">
                {log.Year}.{log.logDate}
            </p>
        </div>
    );
});

export default Receipt;
