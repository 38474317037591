import React from "react";
import { PlanDescriptionProps } from "./PlanDescriptionProps";
import PlanDescriptionMobile from "./PlanDescriptionMobile";
import Plan, { PlanProps } from "./Plan";

export interface SubscriptionTableMobileProps {
    title: string;
    hiddenBreakpoint: "sm" | "md" | "lg";
    plans: Array<PlanProps>;
    planDescriptions: Array<PlanDescriptionProps>;
}

const renderPlan = (props: PlanProps) => {
    const { title } = props;
    return <Plan {...props} key={title} />;
};

const renderPlanDescription = (props: PlanDescriptionProps) => {
    const { title } = props;
    return <PlanDescriptionMobile key={title} {...props} />;
};

const SubscriptionTableMobile = ({
    title,
    hiddenBreakpoint,
    plans,
    planDescriptions,
}: SubscriptionTableMobileProps) => {
    return (
        <div className="full-width-table-container">
            <table className={`table table-bordered table-fixed d-${hiddenBreakpoint}-none subscription-table-mobile`}>
                <thead>
                    <tr>
                        <th className="py-4" colSpan={5}>
                            <h3 className="h3">{title}</h3>
                        </th>
                    </tr>
                    <tr>
                        <th colSpan={5}>
                            <div className="subscription-plans-grid">{plans.map(renderPlan)}</div>
                        </th>
                    </tr>
                    <tr className="mark-secondary">
                        <th colSpan={5}>民眾版 / RFA版，計算機功能差異</th>
                    </tr>
                    <tr className="mark-secondary">
                        <th colSpan={3}>項目與說明</th>
                        <th>民眾版</th>
                        <th>訂閱版</th>
                    </tr>
                </thead>
                <tbody>{planDescriptions.map(renderPlanDescription)}</tbody>
            </table>
        </div>
    );
};

export default SubscriptionTableMobile;
