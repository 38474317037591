import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, ButtonGroup, Col, Container, Dropdown, Row } from "react-bootstrap";
import RfaApi from "../RfaApi";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import "../css/grid.sass";

const SignupHistory = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const user = useSelector((state) => state.user);

    const [pageData, setPageData] = useState(undefined);
    const [filter, setFilter] = useState(null);

    const doPrevious = () => {
        history.push("/profile/dashboard");
    };
    const showLog = (param) => (e) => {
        history.push("/profile/paymentlog/" + param);
    };
    const PayStepCodes = {
        0: "-",
        1: "已完成",
        "-1": "失敗",
        "-10": "作廢",
    };
    const StatusCodes = {
        0: "-",
        1: "已付款",
        2: "待付款",
        10100073: "待繳費",
        10100058: "付款失敗",
        10100248: "拒絕交易",
        10100249: "交易失敗",
        10100251: "卡片過期",
        10100282: "3D授權未完成",
    };
    useEffect(() => {
        rfaApi.GetSignupPayment().then((data) => {
            console.log("GetSignupPayment", data);
            if (data && data.status) {
                setPageData([data]);
            } else {
                Swal.fire({ icon: "error", title: "尚未報名完成" });
                history.push("/profile/signup");
            }
        });
        window.scrollTo(0, 0);
    }, [user, rfaApi, history]);

    const sortAsc = () => {
        setFilter("升序");
        const sorted = [...pageData].sort((a, b) => {
            return Number(dayjs(b.TradeDate).format("YYYY")) - Number(dayjs(a.TradeDate).format("YYYY"));
        });
        return setPageData(sorted);
    };

    const sortDesc = () => {
        setFilter("降序");
        const sorted = [...pageData].sort((a, b) => {
            return Number(dayjs(a.TradeDate).format("YYYY")) - Number(dayjs(b.TradeDate).format("YYYY"));
        });
        return setPageData(sorted);
    };

    return (
        <Container className="canvas">
            <h2 className="canvas-title">測驗報名狀態查詢</h2>
            <Row className="grid-filters">
                <Col>
                    <Dropdown>
                        <Dropdown.Toggle variant="outline-secondary" className="filter">
                            <span>年份排序: </span>
                            {filter === null ? "選擇" : filter}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item as="button" onClick={sortAsc}>
                                升序
                            </Dropdown.Item>
                            <Dropdown.Item as="button" onClick={sortDesc}>
                                降序
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row className="grid-content">
                <Col sm={12}>
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">報名項目</th>
                                    <th scope="col">報名日期</th>
                                    <th scope="col">報名費</th>
                                    <th scope="col">報名狀態</th>
                                    <th scope="col">付款狀況</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pageData && pageData.length > 0 ? (
                                    pageData.map(
                                        ({ title, status, amount, payStep, transactionAt, transactionId }, index) => (
                                            <tr key={"paylog-" + index}>
                                                <td>{title}</td>
                                                <td>{dayjs(transactionAt).format("YYYY/MM/DD")}</td>
                                                <td>NT${amount}</td>
                                                <td>{PayStepCodes[payStep]}</td>
                                                <td>
                                                    {status === "10100073" || status === "2" ? (
                                                        <Button as="a" onClick={showLog(transactionId)}>
                                                            {StatusCodes[status]}
                                                        </Button>
                                                    ) : (
                                                        <>{StatusCodes[status]}</>
                                                    )}
                                                </td>
                                            </tr>
                                        )
                                    )
                                ) : (
                                    <></>
                                )}
                            </tbody>
                        </table>
                    </div>
                </Col>
            </Row>
            <Row>
                <ButtonGroup size="lg" className="d-flex justify-content-around">
                    <button type="button" className="btn btn-secondary" onClick={doPrevious}>
                        回首頁
                    </button>
                </ButtonGroup>
            </Row>
        </Container>
    );
};

export default SignupHistory;
