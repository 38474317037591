import React from "react";
import clsx from "clsx";
import MaterialIcon from "../material/MaterialIcon";
import { Modal, ModalProps } from "react-bootstrap";
import Button from "react-bootstrap/Button";

export type ErrorModalProps = Omit<ModalProps, "onHide"> & {
    content?: string;
    handleClose: () => void;
};

const ErrorModal = ({ className, content, handleClose, ...props }: ErrorModalProps) => {
    return (
        <Modal className={clsx(className, "form-alert alert-error")} onHide={handleClose} backdrop="static" {...props}>
            <Modal.Body>
                <MaterialIcon icon="cancel" size={100} color="#f33223" />
                <p className="px-4">{content ?? "出問題了"}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-primary" onClick={handleClose}>
                    OK
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ErrorModal;
