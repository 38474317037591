import React from "react";
import Nav from "react-bootstrap/Nav";
import FormNoIcon from "../../components/FormNoIcon";

const SubscriptionRequired = () => {
    return (
        <FormNoIcon>
            <p>此為【好命退休計算機RFA版】，您需登入並訂閱方可使用。 </p>
            <Nav.Link className="btn btn-primary w-100" role="button" href="/subscription/case">
                立即訂閱
            </Nav.Link>
        </FormNoIcon>
    );
};

export default SubscriptionRequired;
