import React, { useEffect, useState } from "react";
import PduForm from "../components/PduForm";
import RfaApi from "../RfaApi";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import Actions from "../actions/actions";

/**
 * Page for PDU Application edit
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PduRevise = () => {
    const [pduData, setPduData] = useState({});
    const rfaApi = new RfaApi();
    const history = useHistory();
    const form = useSelector((state) => state.form);

    const postPduApplication = (form) => {
        //alert(JSON.stringify(form));
        rfaApi
            .SavePdu(form)
            .then((response) => {
                Swal.fire({ icon: "success", title: response });
                history.push("/profile/pdu-status");
            })
            .catch((ex) => {
                Swal.fire({ icon: "error", title: ex.response.data });
            });
    };
    const doPrevious = () => {
        Actions.updateForm({ ...form });
        if (form.id) {
            history.push(`/profile/pdu-revise/${form.id}`);
        } else {
            history.push("/profile/pdu-application");
        }
    };

    useEffect(() => {
        setPduData(form);
    }, [form]);

    return (
        <div className="canvas pdu-confirm">
            <h2 className="canvas-title">時數申請</h2>
            <PduForm formData={pduData} onSubmit={postPduApplication} doPrevious={doPrevious} readonly={true} />
        </div>
    );
};

export default PduRevise;
