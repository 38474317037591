import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import { Col, Row } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import DashboardGrid from "../components/DashboardGrid";
import { Link } from "react-router-dom";
import RfaApi from "../RfaApi";
import Swal from "sweetalert2";
import dayjs from "dayjs";
enum PaymentStepEnum {
    FAILED = "-1",
    PENDING = "0",
    PAID = "1",
}
const PaymentResult: { [key: string]: string } = {
    "0": "-",
    "1": "已付款",
    "2": "待付款",
    "10100073": "待繳費",
    "10100058": "付款失敗",
    "10100248": "拒絕交易",
    "10100249": "交易失敗",
    "10100251": "卡片過期",
    "10100282": "3D授權未完成",
};
//mapping to api response fields
interface GridRow {
    id: number; //付款id
    transactionId: string;
    title: string;
    transactionAt: string; //交易時間
    amount: string; //金額
    status: string; //交易狀態
    payStep: PaymentStepEnum; //付款狀態
    activityId: number;
}
const CloumnAmount = ({ amount }: GridRow) => {
    return <>NT$ {amount}</>;
};
const PaymentStatus = ({ payStep, status }: GridRow) => {
    if (payStep === PaymentStepEnum.PENDING) {
        return <>未完成</>;
    }
    if (payStep === PaymentStepEnum.FAILED) {
        return <>取消</>;
    }
    if (payStep === PaymentStepEnum.PAID) {
        return <>已完成</>;
    }
    return <></>;
};
const PaymentOptions = ({ id, transactionId, payStep, status, activityId }: GridRow) => {
    if (payStep === PaymentStepEnum.PENDING) {
        return (
            <>
                <Link className="btn btn-primary" to={`/profile/paymentlog/${transactionId}`}>
                    待付款
                </Link>{" "}
                &nbsp;
                <Link
                    className="btn btn-outline-primary"
                    onClick={() => {
                        Swal.fire({
                            icon: "warning",
                            title: "確定取消此次付款\n並重新繳費？",
                            showCancelButton: true,
                            confirmButtonText: "是",
                            cancelButtonText: "再考慮",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                console.log(`paymet abort confirmed.`);
                                const resetApi = new RfaApi();
                                resetApi
                                    .CancelPayment({ transactionId: transactionId })
                                    .then((res_data) => {
                                        //console.log("CancelPayment", res_data);
                                        if (res_data) {
                                            //console.log(`CancelPayment: ${transactionId}`);
                                            Swal.fire({ icon: "error", title: res_data }).then(() => {
                                                window.location.reload();
                                            });
                                        } else {
                                            Swal.fire({ icon: "error", title: "取消付款失敗" });
                                        }
                                    })
                                    .catch((err) => {
                                        //console.log(err);
                                        Swal.fire({ icon: "error", title: err.response.data });
                                    });
                            }
                        });
                    }}
                    to={`/profile/event-registration-status`}
                >
                    重新繳費
                </Link>
            </>
        );
    }
    if (payStep === PaymentStepEnum.FAILED) {
        return (
            <>
                <Link className="btn btn-outline-primary" to={`/profile/event-registration/${activityId}`}>
                    重新報名
                </Link>
            </>
        );
    }
    return <>已付款</>;
};

const EventRegistrationStatus = () => {
    const pageTitle = "活動報名狀態";

    const rfaApi = new RfaApi();
    const user = useSelector((state: UserState) => state.user);
    const [paymentLogs, setPaymentLogs] = useState<Array<GridRow>>([]);
    const parseDataRow = (gridRow: GridRow, index: number) => {
        const { transactionId, title, transactionAt, status, id } = gridRow;
        const transactionDate = dayjs(transactionAt).format("YYYY/MM/DD");
        return [
            title, //項目
            transactionDate, //申請日期
            <CloumnAmount key={transactionId} {...gridRow} />, //費用
            <PaymentStatus key={transactionId} {...gridRow} />, //付款狀態
            <PaymentOptions key={transactionId} {...gridRow} />, //付款操作
        ];
        //if (index === 0) {
        // } else {
        //     return [
        //         title, //項目
        //         transactionDate, //申請日期
        //         <CloumnAmount key={transactionId} {...gridRow} />, //費用
        //         <PaymentStatus key={transactionId} {...gridRow} />, //付款狀態
        //         <>{PaymentResult[status]}</>, //付款操作
        //     ];
        // }
    };
    useEffect(() => {
        if (user && user.username) {
            rfaApi
                .GetEventRegistrationPayment()
                .then((res_data: any) => {
                    console.log("GetCertificatePayment", res_data);
                    if (res_data) {
                        setPaymentLogs(res_data);
                    } else {
                        throw new Error("Invalid payment response");
                    }
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    }, [user]);

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card">
                    <DashboardGrid
                        header={["項目", "申請日期", "費用", "付款狀態", "付款操作"]}
                        content={paymentLogs.map(parseDataRow)}
                    />
                </FormContainer>
            </Col>
        </Row>
    );
};

export default EventRegistrationStatus;
