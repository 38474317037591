import React, { useEffect, useState } from "react";
import { Pagination } from "react-bootstrap";
import { ExtractRouteParams, generatePath } from "react-router";
import { useParams } from "react-router-dom";
import MaterialIcon from "../../components/material/MaterialIcon";

type PathParams = ExtractRouteParams<string>;

const generateSearchPath = (params: PathParams, page: number) => {
    if (page === 1) {
        return generatePath("/search/");
    }
    return generatePath("/search/:p", { ...params, p: page });
};

const getPathPageLink = (params: PathParams, page: number) => {
    const searchParams = new URLSearchParams(window.location.search);
    const serializedParams = searchParams.toString();

    const searchPath = generateSearchPath(params, page);
    if (serializedParams === "") {
        return searchPath;
    }

    return searchPath + "?" + serializedParams;
};

interface NextPrevProps {
    params: PathParams;
    active: number;
}

const ICON_SIZE = 12;

const createPaginationPrev = ({ params, active }: NextPrevProps) => {
    const prevPage = active - 1;
    const href = getPathPageLink(params, prevPage);
    return (
        <Pagination.Item href={href}>
            <span aria-hidden="true">
                <MaterialIcon icon="chevron_left_rounded" size={ICON_SIZE} />
            </span>
        </Pagination.Item>
    );
};

const createPaginationNext = ({ params, active }: NextPrevProps) => {
    const nextPage = +active + 1;
    const href = getPathPageLink(params, nextPage);
    return (
        <Pagination.Item href={href}>
            <MaterialIcon icon="chevron_right_rounded" size={ICON_SIZE} />
        </Pagination.Item>
    );
};

interface CreatePaginationItemsProps {
    params: PathParams;
    pagesCount: number;
    active: number;
}

const isPrevVisible = (active: number) => active > 1;
const isNextVisible = (pagesCount: number, active: number) => active < pagesCount;

function genPages(current: number, last: number) {
    const delta = 1,
        left = current - delta,
        right = current + delta + 1,
        range = [],
        rangeWithDots = [];
    let l;

    for (let i = 1; i <= last; i++) {
        if (i === 1 || i === last || (i >= left && i < right)) {
            range.push(i);
        }
    }

    for (const i of range) {
        if (l) {
            if (i - l === 2) {
                rangeWithDots.push(l + 1);
            } else if (i - l !== 1) {
                rangeWithDots.push(-l);
            }
        }
        rangeWithDots.push(i);
        l = i;
    }

    return rangeWithDots;
}

const createPaginationItems = ({ params, pagesCount, active }: CreatePaginationItemsProps) => {
    const pages = genPages(+active, +pagesCount);
    const items: Array<React.ReactNode> = pages.map((number) => {
        if (number < 0) {
            return (
                <Pagination.Item key={number} disabled={true}>
                    ...
                </Pagination.Item>
            );
        }
        const href = getPathPageLink(params, number);
        return (
            <Pagination.Item key={number} active={number === active} href={href}>
                {number}
            </Pagination.Item>
        );
    });

    return items;
};

export interface SearchPaginationProps {
    pageSize: number;
    total: number;
    active: number;
}

const getPagesCount = (total: number, pageSize: number) => {
    if (total === 0) {
        return 0;
    }
    if (total < pageSize) {
        return 1;
    }
    return Math.ceil(total / pageSize);
};

type PaginationItems = Array<React.ReactNode>;

const SearchPagination = ({ pageSize, total, active }: SearchPaginationProps) => {
    const [pagesCount, setPagesCount] = useState<number>(0);
    const [items, setItems] = useState<PaginationItems>([]);
    const [hasPrev, setHasPrev] = useState<boolean>(false);
    const [hasNext, setHasNext] = useState<boolean>(false);
    const params = useParams();

    useEffect(() => {
        const count = getPagesCount(total, pageSize);
        setPagesCount(count);
    }, [total, pageSize]);

    useEffect(() => {
        const paginationItems = createPaginationItems({ params, pagesCount, active });
        setItems(paginationItems);
    }, [params, pagesCount, active]);

    useEffect(() => {
        const value = isPrevVisible(active);
        setHasPrev(value);
    }, [active]);

    useEffect(() => {
        const value = isNextVisible(pagesCount, active);
        setHasNext(value);
    }, [active, pagesCount]);

    return (
        <div className="d-flex align-items-center flex-column gap-4">
            <span className="page-info">
                共{total}筆，{pagesCount}頁
            </span>
            <Pagination>
                {hasPrev && createPaginationPrev({ params, active })}
                {items}
                {hasNext && createPaginationNext({ params, active })}
            </Pagination>
        </div>
    );
};

export default SearchPagination;
