import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { APPLICATION_RENEWAL, NEW_APPLICATION } from "./Application";
import InstructionRenewal from "./InstructionRenewal";
import Instruction from "./Instruction";
import FormContainer from "../FormContainer";
import { Stack } from "react-bootstrap";
import RfaApi from "../../RfaApi";
import SuccessModal from "../form/SuccessModal";
import ErrorModal from "../form/ErrorModal";

interface RenewfeeType {
    id: number;
    paymentId: number;
    title: string;
    price: string;
    createdAt: string;
    updatedAt: string;
}

export interface ApplicationChoiceProps {
    onChoice: (step: number) => void;
}

const ApplicationChoice = ({ onChoice }: ApplicationChoiceProps) => {
    const rfaApi = useMemo(() => new RfaApi(), []);

    // // Error / Success 錯誤訊息
    const [isSuccessVisible, setSuccessVisible] = useState<boolean>(false);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [successContent, setSuccessContent] = useState<string>();
    const [renewfee, setRenewfee] = useState<RenewfeeType[]>([]);
    // 串接

    const renewconfirm = () => {
        rfaApi
            .CertificateRenewconfirm()
            .then((res) => {
                // 有符合資格
                setSuccessContent(res.feeTitle);
                setSuccessVisible(true);
            })
            .catch((err) => {
                // 不符合資格
                setErrorContent(err.response.data);
                setErrorVisible(true);
                return;
            });
    };
    // Modal 按鈕確認後再設定 APPLICATION_RENEWAL值 再轉跳畫面
    const goToFrom = () => {
        setErrorVisible(false);
        onChoice(APPLICATION_RENEWAL);
    };

    useEffect(() => {
        rfaApi.GetRenewfee().then((res) => {
            setRenewfee(res);
        });
    }, []);

    return (
        <Stack gap={5}>
            <FormContainer className="dashboard-card secondary" title="一、認證申請">
                <div className="row">
                    <div className="col-auto">
                        <Instruction />
                    </div>
                    <div className="d-grid">
                        <Link to="#" className="btn btn-primary" onClick={() => onChoice(NEW_APPLICATION)}>
                            認證申請
                        </Link>
                    </div>
                </div>
            </FormContainer>
            <FormContainer className="dashboard-card secondary" title="二、認證換證">
                <div className="row">
                    <div className="col-auto">
                        <InstructionRenewal renewfee={renewfee} />
                    </div>
                    <div className="d-grid">
                        <Link to="#" className="btn btn-primary" onClick={renewconfirm}>
                            認證換證
                        </Link>
                    </div>
                </div>
            </FormContainer>
            <SuccessModal content={successContent} show={isSuccessVisible} handleClose={goToFrom} />
            <ErrorModal content={errorContent} show={isErrorVisible} handleClose={() => setErrorVisible(false)} />
        </Stack>
    );
};

export default ApplicationChoice;
