import React from "react";
import { Card } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";

export interface HomeCardProps {
    title: string;
    description: string;
    link: string;
    linkTitle: string;
    image: string;
}

const HomeCard = ({ title, description, link, linkTitle, image }: HomeCardProps) => {
    return (
        <Card className="home-card no-padding shadow-sm">
            <img className="card-img-top" src={image} alt={title} />
            <Card.Body>
                <h2 className="home-card-title">{title}</h2>
                <p className="home-card-description">{description}</p>
                <div className="d-grid gap-2 d-md-flex py-3 justify-content-md-start">
                    <Nav.Link className="btn btn-primary px-4 me-md-2" role="button" href={link}>
                        {linkTitle}
                    </Nav.Link>
                </div>
            </Card.Body>
        </Card>
    );
};

export default HomeCard;
