import React from "react";
import { useSelector } from "react-redux";
import RfaApi from "../RfaApi";
import { Nav, Navbar } from "react-bootstrap";
import imgLogo160 from "../static/rfa-logo-160.png";
import imgLogo197 from "../static/rfa-logo-197.png";

const Menu = () => {
    const rfaApi = new RfaApi();
    const user = useSelector((state) => state.user);
    return (
        <div className="app-layout-header-container">
            <Navbar collapseOnSelect expand="lg" variant="light">
                <Navbar.Brand href="/">
                    <picture>
                        <source media="(max-width: 991px)" srcSet={imgLogo160} />
                        <source media="(min-width: 992px)" srcSet={imgLogo197} />
                        <img src={imgLogo197} className="header-logo" alt="RFA Logo" />
                    </picture>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="menu-topright">
                        {user?.pages?.nav && (
                            <>
                                <Nav.Link
                                    as="a"
                                    style={{ color: "#014190" }}
                                    target="_blank"
                                    href={user?.pages?.nav.links[0].href}
                                >
                                    {user?.pages?.nav.links[0].text}
                                </Nav.Link>
                                <Nav.Link
                                    as="a"
                                    style={{ color: "#014190" }}
                                    target="_blank"
                                    href={user?.pages?.nav.links[1].href}
                                >
                                    {user?.pages?.nav.links[1].text}
                                </Nav.Link>
                                <Nav.Link
                                    as="a"
                                    style={{ color: "#014190" }}
                                    target="_blank"
                                    href={user?.pages?.nav.links[2].href}
                                >
                                    {user?.pages?.nav.links[2].text}
                                </Nav.Link>
                                <Nav.Link
                                    as="a"
                                    style={{ color: "#014190" }}
                                    target="_blank"
                                    href={user?.pages?.nav.links[3].href}
                                >
                                    {user?.pages?.nav.links[3].text}
                                </Nav.Link>
                                <Nav.Link as="a" style={{ color: "#014190" }} href={user?.pages?.nav.links[4].href}>
                                    {user?.pages?.nav.links[4].text}
                                </Nav.Link>
                            </>
                        )}

                        <div className="menu-user">
                            {user.name ? (
                                <>
                                    <Nav.Link as="a" style={{ color: "#014190" }} href="/profile/dashboard">
                                        &nbsp;&nbsp;
                                        <span className="uk-margin-small-right" uk-icon="user" />
                                        {user.name}{" "}
                                    </Nav.Link>
                                    <Nav.Link
                                        as="a"
                                        style={{ color: "#014190" }}
                                        href="#"
                                        onClick={() => {
                                            rfaApi.UserLogout().then(() => {
                                                window.location.reload();
                                            });
                                        }}
                                    >
                                        &nbsp;&nbsp;
                                        <span className="uk-margin-small-right" uk-icon="sign-out" />
                                        登出
                                    </Nav.Link>
                                </>
                            ) : (
                                <Nav.Link as="a" style={{ color: "#014190" }} href="/login">
                                    &nbsp;&nbsp;
                                    <span className="uk-margin-small-right" uk-icon="sign-in" />
                                    登入
                                </Nav.Link>
                            )}
                        </div>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    );
};

export default Menu;
