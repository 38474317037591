import React from "react";
import { Card } from "react-bootstrap";
import Details from "./Details";

export interface UserProps {
    chineseName: string;
    englishName: string;
}

export interface ProfileProps {
    idNum: string;
    email: string;
    phone: string;
    company: string;
    applicationStatus: string;
}

export interface UserDetailsProps {
    user: UserProps;
    profile: ProfileProps;
}

const UserDetails = ({ user, profile }: UserDetailsProps) => {
    const { chineseName, englishName } = user;
    const { idNum, email, phone, company, applicationStatus } = profile;

    return (
        <Card className="dashboard-card">
            <Card.Header>
                <h4>個人資料</h4>
            </Card.Header>
            <Card.Body>
                <div className="dashboard-user-data align-items-start">
                    <Details label="中文姓名" value={chineseName} />
                    <Details label="英文姓名" value={englishName} />
                    <Details label="身分證字號" value={idNum} />
                    <Details label="現職服務單位" value={company} />
                    <Details label="Email" value={email} />
                    <Details label="聯絡電話" value={phone} />
                    <Details label="M3申請" value={applicationStatus} />
                </div>
            </Card.Body>
        </Card>
    );
};

export default UserDetails;
