import { useEffect, useState } from "react";
import twzipcode from "twzipcode-data";
import { Option } from "../formik/GenericSelect";

const data = twzipcode();
const zipcodes = data.zipcodes;
type SelectOption = Option<string, string>;

const useRegions = (countyName: string) => {
    const [regions, setRegions] = useState<Array<SelectOption>>([]);
    useEffect(() => {
        const regionList: { id: any; label: any }[] = [{ id: "", label: "請選擇區域" }];
        if (countyName && countyName !== "") {
            zipcodes.forEach((element: { id: string; county: string; zipcode: any; city: any }) => {
                if (countyName === element.county) {
                    regionList.push({
                        id: element.id,
                        label: element.city,
                    });
                }
            });
        }
        setRegions([...regionList]);
    }, [countyName]);

    return regions;
};

export default useRegions;
