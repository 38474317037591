import React, { useEffect, useMemo, useState } from "react";
import AgreementForm from "../components/certificate/AgreementForm";
import { useSelector } from "react-redux";
import RfaApi from "../RfaApi";
import { apiError } from "../components/form/Alerts";
import { useHistory } from "react-router-dom";
import { FormData } from "../components/certificate/AgreementForm";
import { UserState } from "../components/types";
import { UserProfile } from "../components/certificate/AgreementCorrectInformation";

const CertificateAgreementsMoral = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const user = useSelector((state: UserState) => state.user);
    const history = useHistory();
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const postAgreementForm = (formData: FormData) => {
        rfaApi
            .SaveAgreementMoral(formData)
            .then(() => {
                return history.push("/profile/certificate-download");
            })
            .catch(apiError);
    };

    useEffect(() => {
        if (user && user.rfa) {
            rfaApi.GetProfile().then(setUserProfile).catch(apiError);
        }
    }, [user, rfaApi]);

    return userProfile ? <AgreementForm onSubmit={postAgreementForm} userProfile={userProfile} /> : <></>;
};

export default CertificateAgreementsMoral;
