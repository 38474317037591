import React from "react";
import MaterialIcon from "../material/MaterialIcon";
import { ButtonGroup, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Stack from "react-bootstrap/Stack";

export interface ConfirmPaymentModalProps {
    show: boolean;
    chName: string;
    idNumber: string;
    handleConfirm: () => void;
    handleClose: () => void;
}

const ConfirmPaymentModal = ({ show, chName, idNumber, handleClose, handleConfirm }: ConfirmPaymentModalProps) => {
    return (
        <Modal className="form-alert confirm-payment-modal" show={show} onHide={handleClose}>
            <Modal.Body>
                <div className="modal-inner-content">
                    <Stack gap={3}>
                        <div className="p-3">
                            <MaterialIcon icon="error" size={110} color="#ff9d00" />
                        </div>
                        <h4>執行付款作業</h4>
                        <div>
                            <p>
                                網頁將跳轉至綠界線上金流， <br />
                                請確認您所登入的
                            </p>
                            <p>
                                中文姓名 <span className="text-muted">{chName}</span>與
                            </p>
                            <p>
                                身分證字號 <span className="text-muted">{idNumber}</span>{" "}
                            </p>
                            <p className="mt-3">是否與您的個人資料符合？</p>
                        </div>
                        <div>
                            <p>請注意，若報名的資料欲修改，請記得至【個人資料修改】調整再至此進行報名。</p>
                        </div>
                        <ButtonGroup className="d-flex justify-content-between btn-group-spacing mt-5">
                            <Button type="submit" variant="primary" onClick={handleConfirm}>
                                確定
                            </Button>
                            <Button type="button" variant="outline-primary" onClick={handleClose}>
                                取消
                            </Button>
                        </ButtonGroup>
                    </Stack>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ConfirmPaymentModal;
