import React from "react";
import { Card, Nav } from "react-bootstrap";
import Stack from "react-bootstrap/Stack";
import MaterialIcon from "../material/MaterialIcon";

export interface RfaCardProps {
    title: string;
    icon: string;
    href: string;
}

const RfaCard = ({ title, icon, href }: RfaCardProps) => {
    return (
        <Card className="rfa-card shadow-sm">
            <Card.Body>
                <Stack gap={3} className="d-flex justify-content-between align-items-center">
                    <MaterialIcon icon={icon} size={42} color={"#2660A1"} />
                    <Nav.Link href={href}>
                        <h4 className="rfa-card-title">{title}</h4>
                    </Nav.Link>
                </Stack>
            </Card.Body>
        </Card>
    );
};

export default RfaCard;
