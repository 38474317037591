import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import HomeCard, { HomeCardProps } from "../components/home/HomeCard";
import imgBlockA from "../static/home/rfa.png";
import imgBlockB from "../static/home/computer-free.png";
import imgBlockC from "../static/home/computer-subscribe.png";
import { useSelector } from "react-redux";
import { UserState } from "../components/types";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

export interface AdvertsProps {
    hasAdverts: boolean;
}

const cards: Array<HomeCardProps> = [
    {
        title: "【RFA專區】退休規劃理財顧問",
        description: "",
        link: "/rfa",
        linkTitle: "立即前往",
        image: imgBlockA,
    },
    {
        title: "【民眾版】好命退休計算機",
        description: "",
        link: "/RetireComputer-Person/",
        linkTitle: "開始計算",
        image: imgBlockB,
    },
    {
        title: "【RFA版】好命退休計算機(免費30天)",
        description: "",
        link: "/RetireComputer-Pro/",
        linkTitle: "開始計算",
        image: imgBlockC,
    },
];

const RenderCard = (props: HomeCardProps) => {
    const { title } = props;

    return (
        <>
            <Col key={title} xs={12} md={6} lg={4}>
                <HomeCard {...props} />
            </Col>
        </>
    );
};

const Home = () => {
    const userInfo = useSelector((state: UserState) => state.user);

    const [show, setShow] = useState(false);
    // const [noAdvertsOne, setNoAdvertsOne] = useState(false);
    // const [noAdvertsTwo, setNoAdvertsTwo] = useState(false);
    // const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleClose = () => setShow(false);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        let showAd = true;
        const keys = ["noAdvertsOne", "noAdvertsTwo", "noAdvertsThree"];

        keys.forEach((key) => {
            const storedValue = localStorage.getItem(key);
            if (storedValue) {
                const { date, noAdverts } = JSON.parse(storedValue);
                if (date === today && noAdverts) {
                    showAd = false; // 只要任一設定為不顯示，則不顯示廣告
                }
            } else {
                localStorage.setItem(key, JSON.stringify({ date: today, noAdverts: false })); // 初始化
            }
        });

        setShow(showAd);
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            // 只更新 noAdvertsOne 的值
            localStorage.setItem("noAdvertsOne", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <>
            <Row className="justify-content-center g-4">
                {cards.map((card) => (
                    <RenderCard key={card.title} {...card} />
                ))}
            </Row>
            {/* setIsChecked={setIsChecked} */}
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 0 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </>
    );
};

export default Home;
