import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import RfaApi from "../RfaApi";
import { UserState } from "../components/types";
import { Col, Row, Stack } from "react-bootstrap";
import Meta from "../components/Meta";
import FormContainer from "../components/FormContainer";
import ErrorModal from "../components/form/ErrorModal";
import EventCard from "../components/events/EventCard";
import { ActivityData, DataList } from "../type";
import AdvModal from "../components/modals/AdvertisementModal";
import dayjs from "dayjs";

const pageTitle = "活動列表";

const Events = () => {
    const rfaApi = useMemo(() => new RfaApi(), []);
    const history = useHistory();
    const userInfo = useSelector((state: UserState) => state.user);
    const [isErrorVisible, setErrorVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorContent, setErrorContent] = useState<string>();
    const [events, setEvents] = useState<ActivityData[]>([]);
    const [noAdvertsThree, setNoAdvertsThree] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    useEffect(() => {
        rfaApi
            .GetActivities()
            .then((respData: any) => {
                if (respData) {
                    const mData = respData as DataList<ActivityData>;
                    setIsLoading(false);
                    setEvents(mData.rows);
                }
            })
            .catch((err: any) => {
                console.error(err);
                setErrorVisible(true);
                setErrorContent("載入資料失敗");
            });
    }, []);

    useEffect(() => {
        const today = dayjs().format("YYYY/MM/DD");
        const advert = localStorage.getItem("noAdvertsThree");
        if (advert) {
            const { date, noAdverts } = JSON.parse(advert);
            if (date === today) {
                setShow(!noAdverts);
                setNoAdvertsThree(noAdverts);
            } else {
                // 如果不是當天，預設顯示廣告
                setShow(true);
            }
        } else {
            // 如果 localStorage 沒有對應的值，預設顯示廣告
            setShow(true);
        }
    }, []);

    const saveAdvertisent = (isChecked: boolean) => {
        if (isChecked) {
            const today = dayjs().format("YYYY/MM/DD");
            localStorage.setItem("noAdvertsThree", JSON.stringify({ date: today, noAdverts: isChecked }));
            setShow(!isChecked); // 根據用戶選擇更新廣告顯示狀態
        } else {
            setShow(false);
        }
    };

    return (
        <Row className="justify-content-center">
            <Col>
                <Meta title={pageTitle} />
                <FormContainer title={pageTitle} className="dashboard-card events">
                    <Stack gap={5}>
                        {events.map((eventDetails, index) => (
                            <EventCard key={index} eventDetails={eventDetails} />
                        ))}
                        {isLoading && <div className="text-center">載入中...</div>}
                    </Stack>
                </FormContainer>
                <ErrorModal
                    content={errorContent}
                    show={isErrorVisible}
                    handleClose={() => {
                        setErrorVisible(false);
                        window.location.href = "/profile/dashboard";
                    }}
                />
            </Col>
            {userInfo &&
                userInfo?.pages?.advertisement?.map(
                    (item, index) =>
                        index === 2 && (
                            <AdvModal
                                show={show}
                                handleClose={handleClose}
                                item={item}
                                key={item.id}
                                isChecked={isChecked}
                                setIsChecked={setIsChecked}
                                saveAdvertisent={saveAdvertisent}
                            />
                        )
                )}
        </Row>
    );
};

export default Events;
