import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const NotFound = () => {
    return (
        <Container fluid="md" className="mt-5">
            <Row>
                <Col>
                    <br></br>
                    <div className="card">
                        <div className="card-header">404 Not Found</div>
                        <div className="card-body">
                            <h5 className="card-title">查無此頁</h5>
                            {/* <p className='card-text'>
                Edit Custom 404 error page <code>src/pages/NotFound.js</code>
              </p> */}
                            <LinkContainer to="/">
                                <button className="btn btn-primary">返回</button>
                            </LinkContainer>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

export default NotFound;
