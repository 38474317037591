import React from "react";
import Meta from "../components/Meta";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import RfaApi from "../RfaApi";
import Swal from "sweetalert2";

const LoginPassword = () => {
    const pageTitle = "更新密碼";
    const rfaApi = new RfaApi();
    const history = useHistory();

    var [newPasswordValue, setNewPasswordValue] = useState("");
    var [passwordValue, setPasswordValue] = useState("");

    const { code } = useParams();

    const doAction = () => {
        if (passwordValue !== newPasswordValue) {
            Swal.fire({ icon: "error", title: "新密碼與確認密碼不一致" });
            return;
        }
        if (passwordValue.length < 8) {
            Swal.fire({ icon: "error", title: "密碼長度至少要8個字元" });
            return;
        }
        rfaApi.resetPasswordConfirm(newPasswordValue, code).then((result) => {
            if (result.confirm === true) {
                Swal.fire({
                    icon: "success",
                    title: "密碼已更新，請重新登入。",
                }).then(() => {
                    history.push("/login");
                });
            } else {
                Swal.fire({
                    icon: "error",
                    title: result.message,
                }).then(() => {
                    history.push("/");
                });
            }
        });
    };

    useEffect(() => {
        if (code) {
            console.log("code:", code);
            rfaApi.resetUrlCheck(code).then((result) => {
                //console.log("resetUrlCheck", result);
                if (!result.confirm) {
                    Swal.fire({
                        icon: "error",
                        title: result.message,
                    }).then(() => {
                        history.push("/");
                    });
                }
            });
        }
    }, [code, history, rfaApi]);

    return (
        <>
            <Meta title={pageTitle} />
            <Container>
                <Row className="justify-content-center">
                    <Col sm={4} md={4}>
                        <Container className="canvas">
                            <Row>
                                <Col></Col>
                            </Row>
                            <Row>
                                <Col>
                                    <form>
                                        <div className="form-group">
                                            <label>新密碼(密碼長度至少需要8碼)</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="pass"
                                                value={passwordValue}
                                                onChange={(e) => setPasswordValue(e.target.value)}
                                                placeholder="新密碼"
                                                required
                                            />
                                            <br></br>
                                        </div>
                                        <div className="form-group">
                                            <label>確認密碼</label>
                                            <input
                                                type="password"
                                                className="form-control"
                                                name="newpass"
                                                value={newPasswordValue}
                                                onChange={(e) => setNewPasswordValue(e.target.value)}
                                                placeholder="確認密碼"
                                                required
                                            />
                                            <br></br>
                                        </div>
                                        <div className="form-group d-grid gap-2">
                                            <button
                                                type="button"
                                                className="btn btn-primary"
                                                size="lg"
                                                onClick={doAction}
                                            >
                                                更新
                                            </button>
                                        </div>
                                    </form>
                                </Col>
                            </Row>
                            <Row>
                                <Col></Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default LoginPassword;
