import React from "react";
import dayjs from "dayjs";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import SeatLimit from "./SeatLimit";
import { getRocDateDots } from "../date/RocDate";
import EventDateTime from "./EventDateTime";
import { ActivityData } from "../../type";
import { minsToHours } from "./helper";

export interface EventCardProps {
    eventDetails: ActivityData;
}

interface EventHeaderProps {
    name: string;
}

const EventHeader = ({ name }: EventHeaderProps) => {
    return (
        <>
            <h4 className="h4">{name}</h4>
        </>
    );
};

interface EventBodyProps {
    data: ActivityData;
}

interface EntryProps {
    label: string;
    value: React.ReactNode;
    customClass?: string;
}

const Entry = ({ label, value, customClass }: EntryProps) => {
    const entryClass = "entry" + (customClass ? " " + customClass : "");

    return (
        <div className={entryClass}>
            <div className="entry-label">{label}</div>
            <div className="entry-value">{value}</div>
        </div>
    );
};

interface RegistrationRangeProps {
    start: Date;
    end: Date;
}

const RegistrationRange = ({ start, end }: RegistrationRangeProps) => {
    const dateFormatStart = getRocDateDots(start);
    const dateFormatEnd = getRocDateDots(end);

    return (
        <span>
            {dateFormatStart} - {dateFormatEnd}
        </span>
    );
};

const EventBody = ({ data }: EventBodyProps) => {
    const {
        id,
        //shortDescription,
        activityDate,
        startTime,
        endTime,
        place,
        orgName,
        headCountLimit,
        mins,
        amount,
        startDate,
        endDate,
    } = data;
    const hours = minsToHours(mins);
    const mActivityDate = dayjs(activityDate).toDate();
    const mStartDate = dayjs(startDate).toDate();
    const mEndDate = dayjs(endDate).toDate();
    return (
        <div className="event-body w-100">
            <div className="col-12 col-md-7 pe-md-4">
                <div className="row">
                    <div className="col">
                        {/* <div className="row pb-3">
                            <div className="event-description">{shortDescription}</div>
                        </div> */}
                        <div className="row py-3">
                            <Entry
                                label={"活動時間"}
                                value={<EventDateTime date={mActivityDate} startTime={startTime} endTime={endTime} />}
                            />
                        </div>
                        <div className="row py-3">
                            <Entry label={"活動地點"} value={place} />
                        </div>
                        <div className="row py-3">
                            <Entry label={"開課單位"} value={orgName} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 col-md-3">
                <div className="row py-3 py-md-0">
                    <div className="col-auto col-md-12 pb-md-3">
                        <Entry label={"費用"} value={amount === 0 ? "免費" : amount} />
                    </div>
                    <div className="col-auto col-md-12 py-md-3">
                        <Entry label={"人數"} value={<SeatLimit seatLimit={headCountLimit} />} />
                    </div>
                    <div className="col-auto col-md-12 py-md-3">
                        <Entry label={"認證時數"} customClass="education-hours" value={`${hours}小時`} />
                    </div>
                </div>
                <div className="row py-3 pb-4">
                    <div className="col-auto col-md-12">
                        <Entry label={"報名時間"} value={<RegistrationRange start={mStartDate} end={mEndDate} />} />
                    </div>
                </div>
            </div>
            <div className="col-12 pt-4 col-md-auto pt-md-0">
                <div className="row">
                    <EventButtons id={id} />
                </div>
            </div>
        </div>
    );
};

interface EventButtonsProps {
    id: string;
}

const EventButtons = ({ id }: EventButtonsProps) => {
    // TODO: update event link
    return (
        <div className="event-buttons">
            <Link className="btn btn-primary" role="button" to={`/profile/event-registration/${id}`}>
                立即報名
            </Link>
            <Link className="btn btn-outline-primary" role="button" to={`/profile/event/${id}`}>
                詳細資訊
            </Link>
        </div>
    );
};

const EventCard = ({ eventDetails }: EventCardProps) => {
    const { name } = eventDetails;

    return (
        <Card className="event-card">
            <Card.Header>
                <EventHeader name={name} />
            </Card.Header>
            <Card.Body>
                <EventBody data={eventDetails} />
            </Card.Body>
        </Card>
    );
};

export default EventCard;
